/*globals views, login, globalNavigate */
import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import * as d3 from "d3";

import colors from "common/graphs/colors";
import colorsDark from "common/graphs/colorsDark";
import Request from "common/Request";
import RangeSliderInput from "common/RangeSliderInput";

import Charts from "./Charts";
import {
  getInRangeItems,
  getMetricDir,
  parseStatsResponse,
  getSampleSize,
  retrieveRange,
  hoursByTimePeriodMap,
  PERIOD_TO_LINES,
  getTargets,
  heatmapFormatMap,
  metricMap
} from "./utils";

const timePeriodMap = {
  oneDay: 10,
  oneWeek: 10,
  oneMonth: 60,
  threeMonths: 60,
};

const tooltipUnitMap = {
  averageSpeed: "Mbps",
  maxSpeed: "Mbps",
  activeFlows: "flows",
  flowsCreated: "flows/minute",
  latency: "ms",
  retransmissions: "%",
  congestion: "%",
  traffic: "%",
};

const yAxisUnitMap = {
  averageSpeed: "Mbps",
  maxSpeed: "Mbps",
  activeFlows: "Flows",
  flowsCreated: "Flows-per-minute",
  latency: "ms",
  retransmissions: "% retransimissions",
  congestion: "% congestion",
  traffic: "% traffic at max speed",
};

const CenteredMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 100%;
  min-height: 155px;
`
const NoSubscribersSelected = () =>
  <CenteredMessage>No Subscribers selected.</CenteredMessage>

const returnTo = "viewStatusSubscribers";

const openSubscriberDetails = (entity, key, timePeriod) => {
  const target =
    entity === "ip-address"
      ? { addr: key, subsId: null }
      : { addr: null, subsId: key };
  views.doKeep(returnTo);
  globalNavigate("viewStatusSubscribers", { ...target, returnView: returnTo, timePeriod });
};

const TimeEvolutionMetricsResult = ({
  direction,
  timePeriod,
  metric,
  entity,
  refreshSignal,
  subscribersToPlot = null,
}) => {
  const windowWidth = window.innerWidth;
  const [request, setRequest] = useState(null);
  const [range, setRange] = useState();
  const [initialRange, setInitialRange] = useState();
  const context = useRef({ entity, metric, timePeriod, direction });
  const yAxisUnits = yAxisUnitMap[metric];
  const POINTS_PER_TILE_CHART = windowWidth / 5;
  const colorsSet = login.isTheme("light") ? colors : colorsDark;

  const doLoad = () => {
    setRequest(loadStatistics());
  };

  useEffect(() => {
    removeTooltips();
  }, [request]);

  useEffect(() => {
    return setRange(initialRange);
  }, [initialRange]);

  useEffect(() => {
    context.current = {
      ...context.current,
      entity,
      metric,
      timePeriod,
      direction,
      subscribersToPlot,
    };
    doLoad();
  }, [metric, timePeriod, refreshSignal, entity, subscribersToPlot]);

  const loadStatistics = () => {
    const { entity, metric, timePeriod, direction, subscribersToPlot } =
      context.current;
    const hours = hoursByTimePeriodMap[timePeriod];
    if (subscribersToPlot === null || subscribersToPlot.length === 0) {
      return Promise.resolve({ noSubscribers: true, fields: [], items: [] });
    }

    const metricDir = getMetricDir(metric, direction);

    return retrieveRange(hours)
    .then(({ from, to }) => {
      const targets =
      getTargets(entity, subscribersToPlot);
      setInitialRange({ from, to });
      return ifCl
        .run(
          `show statistics subscribers ${getMetricDir(metric, direction)} ${
            entity === "ip-address" ? "" : "id "
          }'${targets.join(",")}' hours ${hours} interval ${
            timePeriodMap[timePeriod]
          }`
        )
        .then((response) => {
          return parseStatsResponse(response, metric, from, to, colorsSet)
        })
    })
    .catch((error)=>showModalError("Error:", error))
  };

  function removeTooltips() {
    for (const element of document.getElementsByClassName(
      "nvtooltip xy-tooltip"
    )) {
      element.remove();
    }
  }

  const formatUnit = (metric) => (value) =>
    value === null || value === undefined
      ? "n/a"
      : `${
          ["averageSpeed", "maxSpeed"].includes(metric)
            ? value.toFixed(3)
            : value
        } ${tooltipUnitMap[metric]}`;

  return (
    <>
      <small>
        <code>right-click</code> in the lines inside the chart or in the labels for each subscriber to be inspected in the dashboard, or <code>left-click</code> on the heat-map labels.
      </small>
      {request === null ? null : (
        <Request during={request}>
          {({ noSubscribers= false, fields, items, limits: initLimits }) => {
            if ( noSubscribers === true ) {
              return <NoSubscribersSelected />
            }
            const selectedRange = range || initialRange;
            const sampleSize = getSampleSize(
              selectedRange,
              POINTS_PER_TILE_CHART,
              timePeriod
            );
            const { items: inRangeItems, limits, sampledValues } = getInRangeItems(
              items,
              selectedRange,
              fields,
              sampleSize,
              initLimits
            );

            return (
              <Charts
                inRangeItems={inRangeItems}
                yAxisUnits={yAxisUnits}
                tooltipUnits={tooltipUnitMap[metric]}
                xAxisFormat={d3.timeFormat("%m/%d %H:%M")}
                yTooltipFormat={formatUnit(metric)}
                fields={fields}
                onSeriesInspect={(key) => openSubscriberDetails(entity, key, timePeriod)}
                rangeY={
                  limits?.isZeroOrNull ? [0, 1] : [0, limits ? limits.max : 1]
                }
                sampledValues={sampledValues}
                heatmapFormat={heatmapFormatMap[metric]}
                entity={entity}
                metric={metricMap[metric]}
              />
            );
          }}
        </Request>
      )}
      <div className="full-width can-compress margin-t-20 margin-b-30" style={{paddingLeft: 190, marginRight: -15}}>
        {initialRange && (
          <RangeSliderInput
            {...initialRange}
            onChange={(value) => setRange(value)}
          />
        )}
      </div>
    </>
  );
};

export default TimeEvolutionMetricsResult;

