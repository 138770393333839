import { safeStr, capitalize } from "common/api";

export const MARGIN = { left: 15, top: 12, bottom: 20, right: 125 };

export const CONST_TREE_RULE = {
  WIDTH: 800,
  TITLE_NAME: "Title",
  TREE_NAME: "Tree",
  TREE_HDR_NAME: "TreeHdr",
  TREE_RULE_NAME: "treeRule",
  TREE_ID_TOOLTIP_NAME: "treeIdTooltip",
  TREE_NAME_TOOLTIP_NAME: "treeNameTooltip",
  RELOAD_BTN_NAME: "ReloadBtn",
  HELP_BTN_NAME: "HelpBtn",
  HELP_NAME: "Help",
  ANY: "any",
  ROOT: "root",
  ANYOTHER: "any-other",
  COL_BLUE: "#2196F3",
  COL_TEAL: "#009688",
  COL_GREY: "#9E9E9E",
  EDIT_ICON:
    "M3,17.46 L3,20.5 C3,20.78 3.22,21 3.5,21 L6.54,21 C6.67,21 6.8,20.95 6.89,20.85 L17.81,9.94 L14.06,6.19 L3.15,17.1 C3.05,17.2 3,17.32 3,17.46 Z M20.71,7.04 C21.1,6.65 21.1,6.02 20.71,5.63 L18.37,3.29 C17.98,2.9 17.35,2.9 16.96,3.29 L15.13,5.12 L18.88,8.87 L20.71,7.04 Z",
  DELETE_ICON:
    "M6,19 C6,20.1 6.9,21 8,21 L16,21 C17.1,21 18,20.1 18,19 L18,9 C18,7.9 17.1,7 16,7 L8,7 C6.9,7 6,7.9 6,9 L6,19 Z M18,4 L15.5,4 L14.79,3.29 C14.61,3.11 14.35,3 14.09,3 L9.91,3 C9.65,3 9.39,3.11 9.21,3.29 L8.5,4 L6,4 C5.45,4 5,4.45 5,5 C5,5.55 5.45,6 6,6 L18,6 C18.55,6 19,5.55 19,5 C19,4.45 18.55,4 18,4 Z",
  PX_PER_RULE: 70,
  NAMES_MAX_LEN: 19,
};

export const POLI = {
  attr: "poli",
  type: "policy",
  cmdRespColIdx: 12,
  titleEdit: "Policy",
  header: "POLICY",
};

export const PROFS = [
  {
    attr: "if",
    cmdRespColIdx: 2,
    type: "interface",
    titleEdit: "Interface Profile",
    header: "INTERFACE",
  },
  {
    attr: "vlan",
    cmdRespColIdx: 3,
    type: "vlan",
    titleEdit: "VLAN Profile",
    header: "VLAN",
  },
  {
    attr: "policiesRate",
    cmdRespColIdx: 4,
    type: "policy-rate",
    titleEdit: "Policy-Rate Profile",
    header: "POLICY-RATE",
  },
  {
    attr: "int",
    cmdRespColIdx: 5,
    type: "internet",
    titleEdit: "Internet Profile",
    header: "INTERNET",
  },
  {
    attr: "subscriberGroup",
    cmdRespColIdx: 6,
    type: "subscriber-group",
    titleEdit: "Subscriber Group Profile",
    header: "SUB-GROUP",
  },
  {
    attr: "subscriberId",
    cmdRespColIdx: 7,
    type: "subscriber-id",
    titleEdit: "Subscriber ID Profile",
    header: "SUB-ID",
  },
  {
    attr: "acc",
    cmdRespColIdx: 8,
    type: "access",
    titleEdit: "Access Profile",
    header: "ACCESS",
  },
  {
    attr: "time",
    cmdRespColIdx: 9,
    type: "time",
    titleEdit: "Time Profile",
    header: "TIME",
  },
  {
    attr: "throu",
    cmdRespColIdx: 10,
    type: "throughput",
    titleEdit: "Throughput Profile",
    header: "THROUGHPUT",
  },
  {
    attr: "dpi",
    cmdRespColIdx: 11,
    type: "dpi",
    titleEdit: "DPI Profile",
    header: "DPI",
  },
];

export const TYPE_PROFILE_HEADER_MAP = {
  interface: "INTERFACE",
  vlan: "VLAN",
  "policy-rate": "POLICY-RATE",
  internet: "INTERNET",
  "subscriber-group": " SUB-GROUP",
  "subscriber-id": "SUB-ID",
  access: "ACCESS",
  throughput: "THROUGHPUT",
  dpi: "DPI",
  policy: "POLICY",
  time: "TIME",
};

export const TYPE_PROFILE_SHORTCUTF_MAP = {
  interface: function (profName, viewName) {
    if (
      profName === CONST_TREE_RULE.ANY ||
      profName === CONST_TREE_RULE.ANYOTHER
    )
      return;
    profIf.setReturnView(viewName);
    globalNavigate("viewProfIf");
  },
  vlan: function (profName, viewName) {
    if (
      profName === CONST_TREE_RULE.ANY ||
      profName === CONST_TREE_RULE.ANYOTHER
    ) {
      return;
    }
    window.globalNavigate("viewVLANProfileConfig", {
      edit: profName,
      returnView: "viewRulesFlow",
    });
  },
  "policy-rate": function (profName, viewName) {
    if (
      profName === CONST_TREE_RULE.ANY ||
      profName === CONST_TREE_RULE.ANYOTHER
    )
      return;
    ifCl.execute("show profile policy-rate", function (resp) {
      if (resp.CmdResponse.response.indexOf("%ERR") > -1) {
        showModalError("Error:", "Unknow profile " + profName);
      } else {
        var entries = profPoliciesRate.parseProfile(
          profName,
          resp.CmdResponse.response
        );
        profPoliciesRate.setData(entries);
        // ViewRules is the common view for all (flow, monitor, rate)
        profPoliciesRate.editProfile(profName, viewName);
      }
    });
  },
  internet: function (profName, viewName) {
    if (
      profName === CONST_TREE_RULE.ANY ||
      profName === CONST_TREE_RULE.ANYOTHER
    )
      return;
    ifCl.execute(`show profile ${safeStr(profName)}`, function (resp) {
      if (resp.CmdResponse.response.indexOf("%ERR") > -1) {
        showModalError("Error:", "Unknow profile " + profName);
      } else {
        var entries = profInt.parseProfile(profName, resp.CmdResponse.response);
        profInt.setData(entries);
        // ViewRules is the common view for all (flow, monitor, rate)
        profInt.editProfile(profName, viewName);
      }
    });
  },
  "subscriber-group": function (profName, viewName) {
    if (
      profName === CONST_TREE_RULE.ANY ||
      profName === CONST_TREE_RULE.ANYOTHER
    )
      return;
    window.globalNavigate("viewSubscriberGroupProfileConfig", {
      edit: profName,
      returnView: "viewRulesFlow",
    });
  },
  "subscriber-id": function (profName, viewName) {
    if (
      profName === CONST_TREE_RULE.ANY ||
      profName === CONST_TREE_RULE.ANYOTHER
    )
      return;
    globalNavigate("viewSubscriberIDProfileConfig", {
      edit: profName,
      returnView: viewName,
    });
  },
  access: function (profName, viewName) {
    if (
      profName === CONST_TREE_RULE.ANY ||
      profName === CONST_TREE_RULE.ANYOTHER
    )
      return;
    ifCl.execute(`show profile ${safeStr(profName)}`, function (resp) {
      if (resp.CmdResponse.response.indexOf("%ERR") > -1) {
        showModalError("Error:", "Unknow profile " + profName);
      } else {
        var entries = profAcc.parseProfile(profName, resp.CmdResponse.response);
        profAcc.setData(entries);
        // ViewRules is the common view for all (flow, monitor, rate)
        profAcc.editProfile(profName, viewName);
      }
    });
  },
  time: function (profName, viewName) {
    if (
      profName === CONST_TREE_RULE.ANY ||
      profName === CONST_TREE_RULE.ANYOTHER
    )
      return;
    globalNavigate("viewTimeProfileConfig", {
      edit: profName,
      returnView: viewName,
    });
  },
  throughput: function (profName, viewName) {
    if (
      profName === CONST_TREE_RULE.ANY ||
      profName === CONST_TREE_RULE.ANYOTHER
    )
      return;
    profThrou.setReturnView(viewName);
    globalNavigate("viewProfThrou");
  },
  dpi: function (profName, viewName) {
    if (
      profName === CONST_TREE_RULE.ANY ||
      profName == CONST_TREE_RULE.ANYOTHER
    )
      return;
    ifCl.execute("show profile " + profName, function (resp) {
      if (resp.CmdResponse.response.indexOf("%ERR") > -1) {
        showModalError("Error:", "Unknow profile " + profName);
      } else {
        var entries = profDpi.parseProfile(profName, resp.CmdResponse.response);
        profDpi.setData(entries);
        // ViewRules is the common view for all (flow, monitor, rate)
        profDpi.editProfile(profName, viewName);
      }
    });
  },
  policy: function (poliName, viewName, tab) {
    const flowPolicies = rulesFlow.data.policies;
    ifCl.execute("show policy " + safeStr(poliName), function (resp) {
      if (resp.CmdResponse.response.indexOf("%ERR") > -1) {
        showModalError("Error:", "Unknow policy " + poliName);
      } else {
        const policy = flowPolicies.parsePolicy(resp.CmdResponse.response);
        flowPolicies.editPolicy(policy, viewName, tab);
      }
    });
  },
};
