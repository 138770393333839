import {
  parseSystemSecurity, block, cliCommand, invalid } from "common/api";

import {
  ensure,
  wrapErrors,
  oneOf,
  notEmpty,
  noLongerThan,
  asAString,
  asAInteger,
  equalTo,
  beBetween,
} from "views/Config/Profiles/Common/composition";

const notNAAndHigherOrEqualTo = (threshold) => (value) =>
  value !== null && value >= threshold;
const notNAAndLowerOrEqualTo = (threshold) => (value) =>
  value !== null && value <= threshold;
const validCredit = notNAAndLowerOrEqualTo(-1);

export const checkSystemSecurityActive = ({
  maxFailures,
  unlockTime,
  passwordMinLength,
  passwordLCredit,
  passwordUCredit,
  passwordDCredit,
  passwordOCredit,
  enforceUsername,
  enforceRoot,
}) =>
  notNAAndHigherOrEqualTo(4)(maxFailures) &&
  notNAAndHigherOrEqualTo(300)(unlockTime) &&
  notNAAndHigherOrEqualTo(9)(passwordMinLength) &&
  validCredit(passwordLCredit) &&
  validCredit(passwordDCredit) &&
  validCredit(passwordOCredit) &&
  validCredit(passwordUCredit) &&
  enforceRoot === true &&
  enforceUsername === true;

export const parseStrictSystemSecurityIsActive = (response) => ({
  strictSystemSecurityActive: checkSystemSecurityActive(
    parseSystemSecurity(response)
  ),
});

const yesOrNo = (input) =>
  input === "yes" ? true : input === "no" ? false : null;

const pktEngFieldTranslations = {
  "DPI-per-user for operator": "enableDPIForOperators",
  "Steering flow": "steeringFlow",
  "Hide policy rate details for operator": "hidePolicyRatesToOperators",
};

const composePktEngFieldAndValue = (previous, line) => {
  const [srcField, srcValue] = line.split(/:\s+/);
  const dstField = pktEngFieldTranslations[srcField];
  return dstField === undefined
    ? previous
    : { ...previous, [dstField]: yesOrNo(srcValue) };
};

const macOrIpAddress = (input) =>
  input === "MAC" ? "mac" : input === "IP" ? "ip-address" : input;

const composeSubscriberIDSourceFieldAndValue = (settings, line) => {
  const [srcField, srcValue] = line.split(/:\s+/);
  return srcField !== "Subscriber-ID source"
    ? settings
    : { ...settings, subscriberIDSource: macOrIpAddress(srcValue) };
};
const invertDPIEnabledForOperator = ({
  enableDPIForOperators,
  ...settings
}) => ({
  ...settings,
  hideDPIToOperators:
    enableDPIForOperators !== undefined
      ? enableDPIForOperators === false
      : undefined,
});

export const parsePktEngParameters = (input) =>
  invertDPIEnabledForOperator(
    input.trim("\n").split("\n").reduce(composePktEngFieldAndValue, {})
  );

export const parseSubscriberIDSource = (input) =>
  input
    .trim("\n")
    .split("\n")
    .reduce(composeSubscriberIDSourceFieldAndValue, {});

export const parseHostname = (input) => ({
  hostname: input.trim("\n"),
});

export const parseIPV6Prefix = (input) => ({
  ipV6Prefix: parseInt(input.trim("\n"), 10),
});

export const parseUDRRate = (input) => ({
  udrRate: parseInt(input.trim("\n"), 10),
});

const numberOrNA = (input) => (input === "n/a" ? "" : parseInt(input, 10));

const composeInactivityTimeoutFieldAndValue = (settings, line) => {
  const [srcField, srcValue] = line.split(/:\s+/);
  return srcField !== "Session inactivity timeout"
    ? settings
    : { ...settings, guiInactivityTimeout: numberOrNA(srcValue) };
};

export const parseInactivityTimeout = (input) =>
  input.trim("\n").split("\n").reduce(composeInactivityTimeoutFieldAndValue, {
    guiInactivityTimeout: "",
  });

const onlyLettersDigitsAndHyphenRE = /^[a-zA-Z0-9-_]+$/;

const onlyLettersDigitsAndHyphen = (input) =>
  input.match(/^[-_]/) !== null
    ? invalid(`can't start with hyphen or lower hyphen`)
    : input.match(onlyLettersDigitsAndHyphenRE) === null
    ? invalid(`can use only letters, digits and hyphens`)
    : input;

const validHostname = wrapErrors(
  (error) => `Hostname ${error}`,
  ensure(asAString(notEmpty, noLongerThan(253), onlyLettersDigitsAndHyphen))
);
const updateHostname = (previous, { hostname }) =>
  previous.hostname === hostname
    ? []
    : [`system hostname ${validHostname(hostname)}`];

const expressValueCommand = (expression, defaultValue, current, previous) =>
  defaultValue === undefined || current !== defaultValue
    ? `${expression} ${current}`
    : `no ${expression} ${previous}`;

const expressesValueAs =
  (expression, { validates, defaultValue }) =>
  (previous, current) =>
    previous === current
      ? []
      : [
          expressValueCommand(
            expression,
            defaultValue,
            validates(current),
            previous
          ),
        ];

const expressesBoolAs =
  (expression, { inverted = false }) =>
  (previous, current) =>
    previous === current
      ? []
      : [
          expressBoolCommand(
            expression,
            inverted === true ? current === false : current,
            previous
          ),
        ];

const expressBoolCommand = (expression, value) =>
  typeof expression === "function"
    ? expression(value)
    : `${value === true ? "" : "no "}${expression}`;

const theField = (fieldName, expression) => (previous, current) =>
  expression(previous[fieldName], current[fieldName]);

const validUDRRate = wrapErrors(
  (error) => `The UDR production rate ${error}`,
  ensure(asAInteger(beBetween(0, 9999)))
);

const updateUDRRate = theField(
  "udrRate",
  expressesValueAs("udr rate", { validates: validUDRRate, defaultValue: 300 })
);

const validIPV6Prefix = wrapErrors(
  (error) => `IPv6 prefix for subscribers ${error}`,
  ensure(asAInteger(beBetween(1, 128)))
);

const updateIPV6Prefix = theField(
  "ipV6Prefix",
  expressesValueAs("subscriber ipv6-prefix", {
    validates: validIPV6Prefix,
    defaultValue: 64,
  })
);

const validSubscriberIDSource = wrapErrors(
  (error) => `The subscriber ID source ${error}`,
  ensure(oneOf(equalTo("mac"), equalTo("ip-address")))
);

const updateSubscriberIDSource = theField(
  "subscriberIDSource",
  expressesValueAs("subscriber-id", { validates: validSubscriberIDSource })
);

const updateHideDPIToOperators = theField(
  "hideDPIToOperators",
  expressesBoolAs("dpi-per-user operator", { inverted: true })
);

const updateHidePolicyRatesToOperators = theField(
  "hidePolicyRatesToOperators",
  expressesBoolAs("policy-rate operator", { inverted: true })
);

const updateSteeringEnabled = theField(
  "steeringFlow",
  expressesBoolAs("steering flow", {})
);

const optionalPktengSettingsBlock = block({
  optional: true,
  start: "pkteng",
  end: "root",
});

const updateStrictSystemSecurityActiveCommand = (active) =>
  active === false
    ? "no security"
    : `
      security
      access lock 4 300
      password minlen 9
      password dcredit -1
      password lcredit -1
      password ucredit -1
      password ocredit -1
      password enforce-for-root
      password reject-username
      exit
      `;

const updateStrictSystemSecurityActive = theField(
  "strictSystemSecurityActive",
  expressesBoolAs(updateStrictSystemSecurityActiveCommand, {})
);

const updatePktengSettings = (previous, expected) =>
  optionalPktengSettingsBlock([
    ...updateIPV6Prefix(previous, expected),
    ...updateUDRRate(previous, expected),
    ...updateSubscriberIDSource(previous, expected),
    ...updateHideDPIToOperators(previous, expected),
    ...updateHidePolicyRatesToOperators(previous, expected),
    ...updateSteeringEnabled(previous, expected),
  ]);

const optionalWebServiceSettingsBlock = block({
  optional: true,
  start: "service web",
  end: "root",
});

const blankTimeout = wrapErrors(
  () => "blank if no timeout is required",
  equalTo("")
);

const validInactivityTimeout = wrapErrors(
  (error) => `The inactivity timeout ${error}`,
  ensure(oneOf(asAInteger(beBetween(1, 9999)), blankTimeout))
);

const updateInactivityTimeout = theField(
  "guiInactivityTimeout",
  expressesValueAs("session timeout inactivity", {
    validates: validInactivityTimeout,
    defaultValue: "",
  })
);

const updateWebServiceSettings = (previous, expected) =>
  optionalWebServiceSettingsBlock([
    ...updateInactivityTimeout(previous, expected),
  ]);

const optionalSystemSettingsBlock = block({
  optional: true,
  start: "system",
  end: "root",
});

const updateSystemSettings = (previous, expected) =>
  optionalSystemSettingsBlock([
    ...updateStrictSystemSecurityActive(previous, expected),
  ]);

const configureBlock = block({
  optional: true,
  start: "configure",
  end: "commit",
  enter: false,
});

export const commandsBlock = block({ enter: true });

export const composeApplyCommand = (previous, expected) =>
  cliCommand`
    ${commandsBlock([
      ...updateHostname(previous, expected),
      ...configureBlock([
        ...updatePktengSettings(previous, expected),
        ...updateWebServiceSettings(previous, expected),
        ...updateSystemSettings(previous, expected),
      ]),
    ]).join("\n")}
  `(/*enter*/ false);
