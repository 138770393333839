import React, { useContext, useState, useEffect } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import ActionsContext from "common/ActionsContext";
import { capitalize } from "common/api";
import RulesTreeHelpModal from "../RulesTree/Help";
import { PROFS } from "../RulesTree/constants";
import RuleModal from "../RulesTree/RuleModal";
import ConflictsModal from "../RulesTree/ConflictsModal";
import {
  parseConflicts,
  parseRules,
  resizeRulesTree,
} from "../RulesTree/utils";
import SubscriberFlowsTabs from "./Tabs";

const ViewContainer = styled.div`
  min-height: 50vh;
  &.card {
    height: 100%;
  }
`;

const doesNothing = () => {};

function getRulesAndConflicts() {
  return Promise.all([
    ifCl.run(`show rule flow web`).then(parseRules),
    ifCl.run(`show rule error`).then(parseConflicts),
  ]);
}

const ProfMenuItem = ({ view, attr, label, dpiEnabled }) => {
  const mapAttrInstance = {
    if: profIf,
    vlan: profVlan,
    policiesRate: profPoliciesRate,
    int: profInt,
    // subscriberGroup: profSubscriberGroup,
    subscriberId: profSubscriberId,
    acc: profAcc,
    throu: profThrou,
    dpi: profDpi,
  };

  if (view === "viewProfDpi" && !dpiEnabled) {
    return null;
  }

  const handleMenuClick = (view, attr) => {
    const returnView = "viewRulesFlow";
    if (view === "viewProfVlan") {
      globalNavigate("viewVLANProfileConfig", {
        returnView,
      });
    } else if (view === "viewProfTime") {
      globalNavigate("viewTimeProfileConfig", {
        returnView,
      });
    } else if (view === "viewProfSubscriberId") {
      globalNavigate("viewSubscriberIDProfileConfig", {
        returnView,
      });
    } else if (view === "viewProfSubscriberId") {
      globalNavigate("viewSubscriberIDProfileConfig", {
        returnView,
      });
    } else if (view === "viewProfSubscriberGroup") {
      globalNavigate("viewSubscriberGroupProfileConfig", {
        returnView,
      });
    } else {
      mapAttrInstance[attr].setReturnView(returnView);
      globalNavigate(view);
    }
  };

  return (
    <li key={`menu-${view}`}>
      <a onClick={() => handleMenuClick(view, attr)}>{label}</a>
    </li>
  );
};

const ContextMenu = ({ dpiEnabled, tab }) => {
  const actions = useContext(ActionsContext);

  return (
    <ul className="context-menu header-dropdown m-r--5">
      <li key="menu-open-help">
        <a
          data-loading-effect="pulse"
          data-toggle="cardloading"
          title="Help"
          onClick={() => actions.send("open-help")}
        >
          <i className="material-icons">help_outline</i>
        </a>
      </li>
      <li key="menu-refresh">
        <a
          onClick={() => actions.send("do-load")}
          data-toggle="cardloading"
          data-loading-effect="pulse"
          title="Refresh"
        >
          <i className="material-icons">refresh</i>
        </a>
      </li>
      <li className="dropdown hidden-to-operators" key="menu-dropdown">
        <a
          className="dropdown-toggle"
          data-toggle="dropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
          title="Options"
        >
          <i className="material-icons">more_vert</i>
        </a>
        <ul className="dropdown-menu pull-right">
          {tab !== 2 && (
            <li key="menu-add-rule">
              <a
                id="rulesFlowAddRuleBtn"
                onClick={() => actions.send("open-rule-modal", { type: "Add" })}
              >
                Add Rule...
              </a>
            </li>
          )}
          <li key="menu-add-policy">
            <a
              id="rulesFlowAddPoliBtn"
              onClick={() =>
                rulesFlow.data.policies.addPolicy("viewRulesFlow", { tab })
              }
            >
              Add Policy...
            </a>
          </li>
          {tab !== 2 &&
            PROFS.map((prof, index) => {
              const { attr, titleEdit } = prof;
              return (
                <ProfMenuItem
                  view={`viewProf${capitalize(attr)}`}
                  attr={attr}
                  label={`Manage ${titleEdit}s...`}
                  dpiEnabled={dpiEnabled}
                  key={index}
                />
              );
            })}
          {tab !== 2 && (
            <li key="menu-show-conflicts">
              <a
                id="rulesFlowShowConflictsBtn"
                onClick={() => actions.send("open-conflict-modal")}
              >
                Show Rule Conflicts...
              </a>
            </li>
          )}
        </ul>
      </li>
    </ul>
  );
};

const SubscriberFlows = ({ ...params }) => {
  const { dpiEnabled, tab: paramTab } = params;
  const initTab = paramTab || 0;
  const actions = useContext(ActionsContext);
  const [tab, setTab] = useState(Number(initTab));
  const navigate = useNavigate();
  const [rules, setRules] = useState({
    rules: [],
    rulesResp: "",
    conflicts: undefined,
  });

  const doLoad = () => {
    setRules({
      rules: [],
      rulesResp: "",
      conflicts: undefined,
    });
    getRulesAndConflicts()
      .then(([rules, conflicts]) => {
        setRules({
          rules: rules.parsedResponse,
          rulesResp: rules.response,
          conflicts,
        });
      })
      .catch((error) => {
        showModalError("Error: ", error);
      });
  };

  useEffect(() => doLoad() || actions.recv("do-load", doLoad), []);

  const { rules: rulesTree, conflicts, rulesResp } = rules;

  const handleTabChange = (tab) => {
    const queryParams = { tab, dpiEnabled };
    navigate({
      pathname: "/viewRulesFlow",
      search: createSearchParams(queryParams).toString(),
    });
  };

  useEffect(() => {
    setTab(Number(params.tab) || 0);
  }, [params.tab]);

  return (
    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div className="card">
        <div className="header block-header">
          <h2>Subscriber Flows Configuration</h2>
          <ContextMenu dpiEnabled={dpiEnabled} tab={tab} />
        </div>
        <div>
          {rules.rules.length > 0 ? (
            <SubscriberFlowsTabs
              rules={rulesTree}
              conflicts={conflicts}
              response={rulesResp}
              tab={tab}
              setTab={handleTabChange}
            />
          ) : null}
        </div>
      </div>
      <RuleModal rules={rulesTree} />
      <RulesTreeHelpModal />
      <ConflictsModal />
    </div>
  );
};

export default SubscriberFlows;
