import React, { useState, useContext, useEffect } from "react";
import ActionsContext from "common/ActionsContext";
import Modal from "common/Modal.jsx";

const BillingStatusHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);
  useEffect(
    () =>
      actions.recv("open-help-billing-status", function () {
        setOpen(true);
      }),
    []
  );
  const doClose = () => setOpen(false);
  if (open !== true) {
    return null;
  }

  return (
    <Modal
      title="BILLING CONNECTOR STATUS"
      onClose={doClose}
      content={() => (
        <>
          <p>
            This page shows information about the connection with the billing
            system:
          </p>
          <ul>
            <li>
              <b>Type:</b> type of billing system (e.g. Azotel).
            </li>
            <li>
              <b>Server:</b> IP address and port of the billing system. If
              0.0.0.0 address shown, the address is not correct or DNS is now
              working (either not configured right in
              Configuration->Interfaces->Management) or firewall is blocking DNS
              traffic.
            </li>
            <li>
              <b>Synch in progress:</b> on-going synchronization. Click on
              reload to see when it is over.
            </li>
            <li>
              <b>Last sync:</b> date of last synchronization.
            </li>
            <li>
              <b>Next synch in (mins:secs):</b> minutes and seconds to the next
              synchronization.
            </li>
            <li>
              <b>Failures since last sync:</b> number of failures since last
              successful synchronization (0 if OK). System will keep retrying
              periodically.
            </li>
            <li>
              <b>Subscribers retrieved in last sync:</b> number of subscribers
              retrieved from the billing system in the last synchronization.
            </li>
            <li>
              <b>Subscribers updated in last sync:</b> number of valid valid
              subscribers retrieved and provisioned in the last synchronization.
            </li>
            <li>
              <b>Date and time of status:</b> exact date and time the status
              shown corresponds to.
            </li>
          </ul>
          <p>
            Click on the <i className="material-icons valign-icon">refresh</i>{" "}
            icon to reload.
          </p>
          <p className="hidden-to-operators">
            Click on the <i className="material-icons valign-icon">swap_vert</i>{" "}
            button to force a reconnection.
          </p>
        </>
      )}
    />
  );
};

export default BillingStatusHelpModal;
