import React, { useRef, useEffect, useState, forwardRef, useMemo } from "react";
import * as d3 from "d3";
import styled from "styled-components";
import { getDateWithOffset } from "common/api";

import {
  formatDate,
  getItemsUpdate,
  getTooltipTitle,
  MARGIN_FACET_CHART,
  MARGIN_DPI_CHART,
  CursorLine,
} from "./livemetrics.utils";
import NVStackedAreaChartLive from "./NVStackedAreaChartLive";
import { formats } from "common/graphs/NVStackedAreaChart";
import DPIAnalyticsLiveLegend from "./DPIAnalyticsLiveLegend";
import SelectInputWithIcon from "common/SelectInputWithIcon";
import useProfile from "common/hooks/useProfile";

const ChartContainer = styled.div`
  width: 100%;
  position: relative;
`;

const EmptyChartContainer = styled.div`
  min-height: 190px;
`;

const BigGraph = styled.div`
  position: relative;
  & svg {
    min-height: 190px;
  }

  .border {
    position: absolute;
    background-color: transparent;

    &.top {
      width: 100%;
      height: 70px;
      min-height: 70px;
      top: -50px;
      left: 0;
    }
    &.right {
      width: 24px;
      height: 100%;
      top: 0;
      right: 20px;
    }

    &.bottom {
      min-height: 70px;
      width: 100%;
      height: 30px;
      bottom: -40px;
      left: 0;
    }

    &.left {
      width: 30px;
      height: 100%;
      top: 0;
      left: 20px;
    }
  }
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const Title = styled.h4`
  text-align: center;
  margin: 10px 0 10px 0;
  width: 40%;

  @media (max-width: 1024px) {
    width: 100%;
    margin: 0px 0 15px 0;
  }
`;

const ControlsContainer = styled.div`
  @media (max-width: 1024px) {
    width: 100%;
  }

  > div {
    max-width: 120px;
    margin: 0 0 10px 10px;

    select {
      min-width: 84px;
    }
  }
`;

const LegendsContainer = styled.div`
  padding: 0 50px;
  display: relative;
  > div {
    display: block;
    text-align: center;
  }
`;

const StyledTooltipContainer = styled.div`
  position: absolute;
  z-index: 10000;
  text-align: left;
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  padding: 0 5px;
  width: 560px;
  @media (max-width: 1024px) {
    width: 280px;
  }

  .title {
    color: black;
    margin-bottom: 0px;
  }

  .series-tooltip-stacked-area-wrapper {
    display: grid;
    grid-template-columns: repeat(1, 100%);
  }

  .series-tooltip-stacked-area-wrapper.large {
    grid-template-columns: repeat(2, 50%);

    @media (max-width: 1024px) {
      grid-template-columns: repeat(1, 100%);
    }
  }

  .series-tooltip-stacked-area {
    display: flex;
    align-items: center;
    margin-right: 5px;
    justify-content: space-between;
    padding: 2px 0;
  }

  .series-tooltip-stacked-area-name-color {
    display: flex;
    align-items: center;
  }

  .total .series-tooltip-stacked-area-color {
    border: none;
  }

  .total .series-tooltip-stacked-area-name {
    color: black;
  }

  .series-tooltip-stacked-area-wrapper.total.large
    .series-tooltip-stacked-area {
    grid-column: 2;

    @media (max-width: 1024px) {
      grid-column: 1;
    }
  }

  .series-tooltip-stacked-area-color {
    width: 12px;
    min-width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 5px;
    border: 1px solid transparent;
  }

  .series-tooltip-stacked-area-name {
    margin-right: 5px;
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .series-tooltip-stacked-area-value {
    margin-right: 5px;
    text-align: right;
    font-size: 13px;
    color: black;
  }
`;

const _directionChoices = [
  { value: "all", label: "Both." },
  { value: "uplink", label: "Uplink" },
  { value: "downlink", label: "Downlink" },
];

const Tooltip = ({ dataPoint, mouseX, title, mouseY, chartSizes, filterFields, range }) => {
  const { time, fields } = dataPoint;
  if (!time) {
    return null;
  }

  const format = formats.threeDecimals;
  const filteredFields = fields.filter(({ name }) => filterFields.has(name));

  const seriesTotalRest = filteredFields?.reduce(
    (acc, value) => {
      const { name, value: valuePoint } = value;
      if (name.toLowerCase() === "rest") {
        acc.rest = value;
        acc.total += valuePoint;
      } else {
        const { color, value: valuePoint, name } = value;
        if (!isNaN(valuePoint) && valuePoint !== null) {
          if (valuePoint !== 0) {
            acc.total += valuePoint;
            acc.series.push(value);
          }
        }
      }
      return acc;
    },
    {
      rest: {},
      total: 0,
      series: [],
    }
  ) || { rest: {}, total: 0, series: [] };

  const { rest, total, series } = seriesTotalRest;
  const { value: restValue, color: restColor } = seriesTotalRest.rest;
  const restRender =
    !isNaN(restValue) && restValue !== null ? (
      <TooltipSerie
        color={restColor}
        name={"REST"}
        value={`${d3.format(".2f")(
          total <= 0 ? 0 : (restValue * 100) / total
        )}% ${format(restValue)} Mbps`}
      />
    ) : null;

  const chartWidth = chartSizes.wDPI;;
  const isSmallScreen = chartWidth < 800;
  const isLeftSide = mouseX < (chartWidth - 100) / 2;
  const offsetSmallScreen = isLeftSide ? 55 : -190;
  const offsetBigScreen = isLeftSide ? -100 : -450;
  const offset = isSmallScreen ? offsetSmallScreen : offsetBigScreen;

  return (
    <StyledTooltipContainer
      style={{
        top: mouseY -50 + "px",
        left: mouseX + offset + "px",
      }}
      className="tooltip-live"
    >
      <label className="title">{getTooltipTitle(title)}</label>
      <div class="tooltip-stacked-area-wrapper large">
        <div class="series-tooltip-stacked-area-wrapper large">
          {total > 0
            ? series.map((serie) => {
                const { color, value, name } = serie;
                const displayValue = `${d3.format(".1f")(
                  total <= 0 ? 0 : (value * 100) / total
                )} % ${format(value)} Mbps`;

                return (
                  <TooltipSerie
                    color={color}
                    name={name}
                    value={displayValue}
                  />
                );
              })
            : null}
          {restRender}
        </div>
        {(series.length > 0 || Object.keys(rest).length>0) ? (
          <div class="series-tooltip-stacked-area-wrapper large total">
            <TooltipSerie
              color={"transparent"}
              name={"TOTAL"}
              value={`${format(total)} Mbps`}
            />
          </div>
        ) : null}
      </div>
    </StyledTooltipContainer>
  );
};

const TooltipSerie = ({ color, name, value }) => {
  return (
    <div className="series-tooltip-stacked-area">
      <div className="series-tooltip-stacked-area-name-color">
        <div
          className="series-tooltip-stacked-area-color"
          style={{ backgroundColor: color }}
        ></div>
        <div className="series-tooltip-stacked-area-name">{name}</div>
      </div>
      <div class="series-tooltip-stacked-area-value">{value}</div>
    </div>
  );
};

const getHoveredTime = (mouseEvent, items) => {
  if (!mouseEvent || items.length === 0) {
    return null;
  }
  const {mouseXDPI, rangeDPI, mouseXFacet, rangeFacet} = mouseEvent;
  if(mouseXDPI!==null && rangeDPI){
    const xScale =  d3.scaleTime()
    .domain([items[0].time, items[items.length-1].time])
    .range([0, rangeDPI]);
    return xScale.invert(mouseXDPI);
  }
  if(mouseXFacet!==null && rangeFacet){
    const xScale =  d3.scaleTime()
    .domain([items[0].time, items[items.length-1].time])
    .range([0, rangeFacet]);
    return xScale.invert(mouseXFacet);
  }
  return null;
};

const getMouseX = (mouseEvent, range)=>{
  if (!mouseEvent) {
    return null;
  }
  const {mouseXDPI, mouseXFacet, rangeFacet} = mouseEvent;
  if(mouseXDPI){
    return mouseXDPI;
  }
  if(mouseXFacet){
    return (range/rangeFacet) * mouseXFacet;
  }
  return null;
}

const DPIAnalyticsLive = forwardRef(
  (
    {
      title,
      units = "",
      yAxisFormat = null,
      value,
      resetSignal,
      setResetSignal,
      onMouseEvent,
      mouseEvent,
      yTooltipFormat = (value) => value,
      timeOffset,
      direction,
      setDirection,
      fields,
      fieldsMap,
      timeWindow,
      margin,
      chartSizes,
      ...rest
    },
    ref
  ) => {
    const [items, setItems] = useState([]);
    const [window, setWindow] = useState(0);
    const [filterFields, setFilterFields] = useState(new Set());
    const [filteredFields, setFilteredFields] = useState([]);
    const firstValue = useRef(false);
    const fieldsSet = useRef({});
    const dataMap = useRef({});
    const [range, setRange] = useState(0);

    useEffect(() => {
      if (resetSignal) {
        fieldsSet.current = {};
        firstValue.current = false;
        setItems([]);
        setResetSignal(false);
        dataMap.current = {};
        setWindow(0);
      }
    }, [resetSignal]);

    useEffect(() => {
      if (!value) {
        return;
      }

      const now = getDateWithOffset(timeOffset);
      const newValue = { ...value };

      if (!firstValue.current) {
        firstValue.current = newValue;
      }

      const fieldNames = Object.keys(fieldsMap);
      const addedFields = fieldNames.filter(
        (field) => !fieldsSet.current[field]
      );
      const filterFieldsWithoutRemoved = [...filterFields].filter(
        (fieldName) => fieldsSet.current[fieldName]
      );
      setFilterFields(new Set([...filterFieldsWithoutRemoved, ...addedFields]));
      fieldsSet.current = fieldsMap;

      const timeFrame =
        newValue.time.getTime() - firstValue.current.time.getTime();

      let { itemsUpdate, window: windowNew } = getItemsUpdate(
        items,
        now,
        fields,
        timeOffset,
        timeFrame,
        timeWindow,
        newValue,
        window
      );
      setWindow(windowNew);
      const lastValue = itemsUpdate[itemsUpdate.length - 1];
      if (
        lastValue?.time &&
        newValue?.time &&
        formatDate(lastValue.time) !== formatDate(newValue.time)
      ) {
        delete dataMap.current[formatDate(itemsUpdate[0].time)];
        dataMap.current[formatDate(newValue.time)] = {
          time: newValue.time,
          fields,
        };
        setItems([...itemsUpdate.slice(1), newValue]);
      }
    }, [value]);

    useEffect(() => {
      if (filterFields) {
        setFilteredFields(
          fields.filter((field) => filterFields.has(field.name))
        );
      }
    }, [filterFields]);

    const handleDirectionChange = ({ target }) => {
      const { value } = target;
      setDirection(value);
    };

    const top30Fields = useMemo(
      () =>
        Object.entries(fieldsMap)
          .map(([key, { total, color }]) => {
            return {
              label: key,
              name: key,
              color,
              total,
            };
          })
          .sort((a, b) => b.total - a.total)
          .slice(0, 30),
      [fieldsMap]
    );

    const hasRestValue = fieldsMap["rest"]
      ? fieldsMap["rest"].total >= top30Fields[top30Fields.length - 1].total
      : false;

    function removeTooltip() {
      onMouseEvent(null);
    }

    const hoveredTime = getHoveredTime(mouseEvent, items);
    const mouseX = getMouseX(mouseEvent, range);

    return (
      <ChartContainer className="padding-t-10 padding-l-10 padding-r-10 padding-b-20 chart-container dpi-chart-container">
        <TitleContainer className="dpi-live-chart-header">
          <Title className="chart-title">{title}</Title>
          <ControlsContainer>
            <SelectInputWithIcon
              title="Direction"
              name="Direction"
              icon="import_export"
              selected={direction}
              onChange={handleDirectionChange}
              options={_directionChoices}
            />
          </ControlsContainer>
        </TitleContainer>

        {items.length > 0 ? (
          <LegendsContainer
            onMouseEnter={removeTooltip}
            onMouseLeave={removeTooltip}
            className="dpi-live-legend-wrapper"
          >
            <DPIAnalyticsLiveLegend
              fields={top30Fields}
              onChange={(filter) => setFilterFields(filter)}
              value={filterFields}
              rest={hasRestValue ? fieldsMap["rest"] : false}
            />
          </LegendsContainer>
        ) : null}

        <BigGraph ref={ref}>
          {mouseX && hoveredTime && (
            <Tooltip
              dataPoint={dataMap.current[formatDate(hoveredTime)] || {}}
              mouseX={mouseX}
              mouseY={mouseEvent.mouseY}
              title={hoveredTime}
              chartSizes={chartSizes}
              range={range}
              filterFields={filterFields}
            />
          )}
          {mouseEvent && (
            <>
              <div
                className="border top"
                onMouseEnter={removeTooltip}
                onMouseLeave={removeTooltip}
              ></div>
              <div
                className="border right"
                onMouseEnter={removeTooltip}
                onMouseLeave={removeTooltip}
              ></div>
              <div
                className="border bottom"
                onMouseEnter={removeTooltip}
                onMouseLeave={removeTooltip}
              ></div>
              <div
                className="border left"
                onMouseEnter={removeTooltip}
                onMouseLeave={removeTooltip}
              ></div>
            </>
          )}
          <NVStackedAreaChartLive
            items={items}
            xAxisFormat={d3.timeFormat("%H:%M:%S")}
            fields={filteredFields.sort((a, b) => {
              return a.name.localeCompare(b.name);
            })}
            yAxisTopGap={0.05}
            margin={margin}
            onHighlight={(e) => {
              if (e) {
                const newEvent = {...e, rangeDPI: range, mouseXDPI: e.mouseX, mouseXFacet: null}
                onMouseEvent(newEvent);
              }
            }}
            onRange={(rangeNew) => {
              setRange(rangeNew);
            }}
            chartWidth={chartSizes.wDPI - MARGIN_DPI_CHART.left-MARGIN_DPI_CHART.right}
          />
          { mouseEvent && hoveredTime ? (
            <CursorLine
              style={{
                left: mouseX + MARGIN_DPI_CHART.left,
                height: `calc(100% - ${MARGIN_DPI_CHART.top + MARGIN_DPI_CHART.bottom}px)`,
                top: MARGIN_DPI_CHART.top,
              }}
            />
          ): null}
        </BigGraph>
      </ChartContainer>
    );
  }
);

DPIAnalyticsLive.displayName = "DPIAnalyticsLive";

const EmptyContainer = styled.div`
  width: 100%;
  height: 20px;
`;

const CenteredMessage = styled.div`
  min-height: 2cms;
  display: flex;
  align-items: center;
  justify-content: center;
`

const TooMuchFlowsForDPI = ({title}) =>
  <ChartContainer className="padding-t-10 padding-l-10 padding-r-10 padding-b-20 chart-container dpi-chart-container">
    <TitleContainer className="dpi-live-chart-header">
      <Title className="chart-title">{title}</Title>
    </TitleContainer>
    <CenteredMessage>Too many concurrent flows for live DPI</CenteredMessage>
  </ChartContainer>

const OnlyAllowedDPIAnalyticsLive = ({preventFlood=false, title, ...settings}) => {
  const profile = useProfile() || {};
  return (
    profile.dpiEnabled !== true ? <EmptyContainer /> :
    preventFlood === true ? <TooMuchFlowsForDPI title={title} /> :
    <DPIAnalyticsLive title={title} {...settings} />
  )
}

export default OnlyAllowedDPIAnalyticsLive;
