/* globals showModalInfo */
import React, { useState, useContext, useEffect, useRef } from "react";
import Modal from "common/Modal.jsx";
import ActionsContext from "common/ActionsContext";
import Fields from "./Fields";
import { mergeStateOfDevices } from "../api";

const doesNothing = () => {};

const CreateWire = ({
  others = [],
  doSave = doesNothing,
  interfaces = [],
}) => {
  const actions = useContext(ActionsContext);
  const [wireCreation, setWireCreation] = useState(false);

  const showWireCreation = () => setWireCreation(true);
  const hideWireCreation = () => setWireCreation(false);

  useEffect(() => actions.recv("new-wire", () => showWireCreation()), []);

  return wireCreation === true ? (
    <CreateWireModal
      active={wireCreation}
      others={others}
      doSave={doSave}
      doClose={hideWireCreation}
      interfaces={interfaces}
    />
  ) : null
};

const getFormData = (form) => {
  const data = new FormData(form);
  const result = Object.fromEntries(data.entries());
  return {
    acc: { iface: result.accIface, pcap: result.accPcap === "on" },
    inet: { iface: result.inetIface, pcap: result.inetPcap === "on" },
  };
};

const CreateWireModal = ({
  doSave = doesNothing,
  doClose = doesNothing,
  interfaces = [],
}) => {
  const form = useRef(null);
  const [error, setError] = useState(null);
  if (interfaces.length < 2) {
    showModalInfo("Not enough free interfaces available", null);
    return null;
  }

  const clearError = () => setError(null);

  const doApply = () => {
    try {
      const currently = getFormData(form.current);
      const devicesByName = Object.fromEntries(
        interfaces.map((device) => [device.name, device])
      );
      doSave(mergeStateOfDevices(devicesByName)(currently));
      doClose();
    } catch (error) {
      setError(error);
    }
  };
  
  return (
    <Modal
      title="Create Wire"
      superIcon="add_circle"
      large={false}
      content={() => (
        <form ref={form}>
          <Fields interfaces={interfaces} onChange={clearError} />
        </form>
      )}
      applyLabel="OK"
      onApply={doApply}
      applyDisabled={error !== null}
      closeLabel="CANCEL"
      onClose={doClose}
      footerMessage={
        (error !== null && (
          <span className="modal-err-msg color-red align-left">{error}</span>
        )) ||
        null
      }
    />
  );
};
export default CreateWire;
