/* globals showModalConfirm showModalError showModalInfo */
import * as d3 from "d3";
import { safeStr, capitalize } from "common/api";
import {
  MARGIN,
  CONST_TREE_RULE,
  POLI,
  PROFS,
  TYPE_PROFILE_HEADER_MAP,
  TYPE_PROFILE_SHORTCUTF_MAP,
} from "./constants";

function fromProfName(name) {
  return name == "n/a" ? "any" : name;
}

export function parseConflicts(response) {
  if (!response || response.length === 0) return;

  const lines = response.trim(/\s/).split("\n");
  return lines.reduce((acc, value) => {
    const conflictsFound = value.match(
      /Conflict between .+ rules \d+ and \d+/g
    );
    if (conflictsFound) {
      const conflictingRules = conflictsFound[0].match(/\d+/g);
      if (conflictingRules) {
        const key = conflictingRules[0];
        if (acc[key]) {
          if (!acc[key].includes(conflictingRules[1])) {
            acc[key] = `${acc[key]}, ${conflictingRules[1]}`;
          }
        } else {
          acc[key] = `Conflict with rule ${conflictingRules[1]}`;
        }
      }
    }
    return acc;
  }, {});
}

export function parseRules(response) {
  const [header, ...rows] = response.trim(/\s/).split("\n");
  const parsedResponse = rows.reduce((acc, value) => {
    const attrs = value.split(/\s+/);
    if (attrs[0].length == 0) attrs.shift();
    let rule = {};
    rule.id = attrs[0];
    PROFS.forEach((prof) => {
      rule[prof.attr] = fromProfName(attrs[prof.cmdRespColIdx]);
    });
    rule.poli = fromProfName(attrs[POLI.cmdRespColIdx]);

    acc.push(rule);
    return acc;
  }, []);

  return { response, parsedResponse };
}

export function parseRulesTree({
  response,
  conflicts,
  rules: parsedRulesResponse,
}) {
  const [header, ...rows] = response.trim(/\s/).split("\n");
  const rules = rows.reduce((acc, value) => {
    const attrs = value.split(/\s+/);
    if (attrs[0].length == 0) attrs.shift();
    PROFS.forEach((prof) => {
      const profsAttr = fromProfName(attrs[prof.cmdRespColIdx]);
      if (!acc[prof.type]) {
        const newEntry = {
          type: prof.type,
          nodes: [],
          profs: [profsAttr],
          rulesId: [],
          conflicts: [],
        };
        acc[prof.type] = newEntry;
      } else {
        acc[prof.type].profs.push(profsAttr);
      }
    });
    const profsAttrPolicy = fromProfName(attrs[POLI.cmdRespColIdx]);
    const conflict = conflicts && conflicts[attrs[0]];
    if (!acc.policy) {
      const newEntry = {
        type: POLI.type,
        nodes: [],
        profs: [profsAttrPolicy],
        rulesId: [attrs[0]],
        conflicts: [conflict],
      };
      acc.policy = newEntry;
    } else {
      acc.policy.profs.push(profsAttrPolicy);
      acc.policy.rulesId.push(attrs[0]);
      acc.policy.conflicts.push(conflict);
    }
    return acc;
  }, {});

  const rulesFiltered = Object.values(rules).filter((rule) => {
    const { profs } = rule;
    return profs.some((prof) => prof !== "any");
  });

  return rulesFiltered;
}

function getPredProfileName(typeIdx, ruleId, rules) {
  if (typeIdx === 0) {
    return null;
  } else {
    return rules[typeIdx - 1].profs[ruleId];
  }
}

function findPredsInCategories(preds, cats) {
  return cats.findIndex((cat) => {
    for (let i = 0; i < preds.length; i++) {
      if (cat.preds[i] != preds[i]) return false;
    }
    return true;
  });
}

function getCategories(rules, typeIdx) {
  const profs = rules[typeIdx].profs;
  const categories = [];
  for (let i = 0; i < profs.length; i++) {
    let preds = [];
    let profName = profs[i];
    preds.push(profName);
    for (let idx = typeIdx; idx; idx--) {
      preds.push(getPredProfileName(idx, i, rules));
    }
    const catIdx = findPredsInCategories(preds, categories);
    if (catIdx === -1) {
      // Create category
      categories.push({ name: profName, idx: i, preds: preds, rulesIdx: [i] });
    } else {
      // Add ruleId to category
      categories[catIdx].rulesIdx.push(i);
    }
  }
  return categories;
}

function bubleDownSiblingAnyNodes(first, last, flatTree) {
  let node, next, tmp;

  if (first == last) {
    node = flatTree[first];
    if (node.profile === "any") {
      return flatTree;
    }
  }

  for (let i = first; i < last; i++) {
    node = flatTree[i];
    next = flatTree[i + 1];
    if (
      (node.profile == "any" || node.profile == "any-other") &&
      next &&
      next.parent == node.parent
    ) {
      //console.log('BUBBLING-ANY-SIBLINGS: %d, %d', i, i+1);
      // Change profile to ANYOTHER while bubbling down
      node.profile = "any-other";
      // Swap nodes
      tmp = node;
      flatTree[i] = next;
      flatTree[i + 1] = node;
    }
  }
  return flatTree;
}

export function buildFlatTree(rules) {
  let rulesTree = [...rules];
  let flatTree = [];
  let first;
  let nodeId = 0;

  const parentNode = {
    name: 0,
    profile: "root",
    type: "root",
    ruleId: null,
    conflicts: null,
    parent: null,
  };
  flatTree.push(parentNode);
  nodeId = nodeId + 1;

  for (let i = 0; i < rulesTree.length; i++) {
    const ruleTree = rulesTree[i];
    const categories = getCategories(rulesTree, i);
    first = flatTree.length;
    categories.forEach((cat, index) => {
      parent = i === 0 ? 0 : rulesTree[i - 1].nodes[cat.rulesIdx[0]];
      const ruleId =
        ruleTree.type === "policy" ? ruleTree.rulesId[index] : null;
      const conflicts =
        ruleTree.type === "policy" ? ruleTree.conflicts[index] : null;
      const newNode = {
        name: nodeId,
        profile: cat.name,
        type: ruleTree.type,
        ruleId,
        conflicts,
        parent,
      };
      flatTree.push(newNode);

      for (let j = 0; j < cat.rulesIdx.length; j++) {
        ruleTree.nodes[cat.rulesIdx[j]] = nodeId;
      }
      nodeId = nodeId + 1;
    });
    flatTree = bubleDownSiblingAnyNodes(first, flatTree.length - 1, [
      ...flatTree,
    ]);
  }
  return flatTree;
}

export function fromFlatToTree(flatTreeData) {
  let treeData = [];
  const dataMap = flatTreeData.reduce((acc, value) => {
    acc[value.name] = value;
    return acc;
  }, {});

  [...flatTreeData].forEach((node) => {
    const parent = dataMap[node.parent];
    if (parent) {
      if (parent.children) {
        parent.children.push(node);
      } else {
        parent.children = [node];
      }
    } else {
      // parent is null or missing
      treeData.push(node);
    }
  });

  return treeData[0];
}

export function getFontSizeTree(widthSvg, width) {
  if (widthSvg > 890) {
    return Math.round((width / widthSvg) * 14);
  } else if (widthSvg < 500) {
    return Math.round((width / widthSvg) * 6);
  } else if (widthSvg < 550) {
    return Math.round((width / widthSvg) * 7);
  } else if (widthSvg < 600) {
    return Math.round((width / widthSvg) * 8);
  } else if (widthSvg < 620) {
    return Math.round((width / widthSvg) * 10);
  } else if (widthSvg < 800) {
    return Math.round((width / widthSvg) * 12);
  } else if (widthSvg < 890) {
    return Math.round((width / widthSvg) * 13);
  }

  return 13;
}

export function getScaleIcons(widthSvg, width) {
  if (widthSvg > 890) {
    return width / widthSvg;
  } else if (widthSvg < 600) {
    return (width / widthSvg) * 0.5;
  } else if (widthSvg < 620) {
    return (width / widthSvg) * 0.6;
  } else if (widthSvg < 800) {
    return (width / widthSvg) * 0.7;
  } else if (widthSvg < 890) {
    return (width / widthSvg) * 0.8;
  }

  return 13;
}

export function getRadiusBig(widthSvg, width) {
  if (widthSvg > 890) {
    return Math.round((width / widthSvg) * 9);
  } else if (widthSvg < 600) {
    return Math.round((width / widthSvg) * 5);
  } else if (widthSvg < 620) {
    return Math.round((width / widthSvg) * 6);
  } else if (widthSvg < 800) {
    return Math.round((width / widthSvg) * 7);
  } else if (widthSvg < 890) {
    return Math.round((width / widthSvg) * 8);
  }

  return 9;
}

export function getRadiusSmall(widthSvg, width) {
  if (widthSvg > 890) {
    return Math.round((width / widthSvg) * 5);
  } else if (widthSvg < 600) {
    return Math.round((width / widthSvg) * 2);
  } else if (widthSvg < 620) {
    return Math.round((width / widthSvg) * 3);
  } else if (widthSvg < 800) {
    return Math.round((width / widthSvg) * 3);
  } else if (widthSvg < 890) {
    return Math.round((width / widthSvg) * 4);
  }

  return 9;
}

export function toMaxNameLen(name) {
  if (name.length > CONST_TREE_RULE.NAMES_MAX_LEN) {
    return name.substring(0, CONST_TREE_RULE.NAMES_MAX_LEN - 3) + "...";
  } else {
    return name;
  }
}

export function isVoid(value) {
  return value === undefined || value === null;
}

const defaultProfile = "flow-default";

export const isDefaultRule = (data, parent, level = 0) => {
  let { type, profile } = data;
  if (type === "root") {
    return true;
  }
  if (type === "policy" && profile === defaultProfile) {
    const parentData = parent.data;
    return parentData
      ? isDefaultRule(
          { type: parentData.type, profile: parentData.profile },
          parent.parent,
          level + 1
        )
      : false;
  }
  if (profile === "any" || profile === "any-other") {
    const parentData = parent.data;
    return parentData
      ? isDefaultRule(
          { type: parentData.type, profile: parentData.profile },
          parent.parent,
          level + 1
        )
      : false;
  }
  return undefined;
};

function xSpacingAdd(xValue, xSpacing) {
  const len = xSpacing.length;
  const off = 11;
  const val = Math.ceil(xValue) + off;
  let item;
  if (len === 0) {
    xSpacing.push(val);
  } else if (len === 1) {
    if (val === xSpacing[0]) {
      return xSpacing;
    }
    if (val > xSpacing[0]) xSpacing.push(val);
    else xSpacing.unshift(val);
  } else {
    for (var i = 0; i < len; i++) {
      item = xSpacing[i];
      if (item === val) {
        return xSpacing;
      }
      if (item > val) {
        xSpacing.splice(i, 0, val);
        return xSpacing;
      }
    }
    xSpacing.push(val);
  }
  return xSpacing;
}

export function renderSvgTree(
  chartHeightInit,
  root,
  linksRef,
  svgTreeWidth,
  circlesRef,
  rules,
  actions
) {
  let xSpacing = [];
  const tree = d3
    .tree()
    .size([
      chartHeightInit - MARGIN.top - MARGIN.bottom,
      CONST_TREE_RULE.WIDTH - MARGIN.left - MARGIN.right,
    ]);
  let nodes = d3.hierarchy(root, (d) => d.children);
  nodes = tree(nodes);
  const links = d3.select(linksRef.current);
  const newFontSizeLegend = getFontSizeTree(
    svgTreeWidth,
    CONST_TREE_RULE.WIDTH
  );
  const bigNodeRadius = getRadiusBig(svgTreeWidth, CONST_TREE_RULE.WIDTH);
  const smallNodeRadius = getRadiusSmall(svgTreeWidth, CONST_TREE_RULE.WIDTH);
  const scaleIcons = getScaleIcons(svgTreeWidth, CONST_TREE_RULE.WIDTH);

  const link = links
    .selectAll(".link")
    .data(nodes.descendants().slice(1))
    .join(
      (enter) =>
        enter
          .append("path")
          .attr("class", "link")
          .style("stroke", "#ccc")
          .style("fill", "none")
          .attr("d", (d) => {
            return (
              "M" +
              d.y +
              "," +
              d.x +
              "C" +
              (d.y + d.parent.y) / 2 +
              "," +
              d.x +
              " " +
              (d.y + d.parent.y) / 2 +
              "," +
              d.parent.x +
              " " +
              d.parent.y +
              "," +
              d.parent.x
            );
          }),
      (exit) => exit.remove()
    );

  const circles = d3.select(circlesRef.current);

  const circleGroups = circles
    .selectAll("g")
    .data(nodes.descendants())
    .join(
      (enter) =>
        enter.append("g").attr("transform", function (d) {
          return "translate(" + d.y + "," + d.x + ")";
        }),
      (exit) => exit.remove()
    );

  // Add node circle
  circleGroups
    .append("circle")
    .attr("stroke-width", 1)
    .attr("class", function (d) {
      return d.profile == CONST_TREE_RULE.ANY ||
        d.profile == CONST_TREE_RULE.ROOT
        ? "tree-circle-small"
        : "tree-circle-big";
    })
    .attr("stroke", function (d) {
      xSpacing = xSpacingAdd(d.y, xSpacing);
      if (d.profile == CONST_TREE_RULE.ANY || d.profile == CONST_TREE_RULE.ROOT)
        return CONST_TREE_RULE.COL_GREY;
      if (d.children == null) return CONST_TREE_RULE.COL_TEAL;
      return CONST_TREE_RULE.COL_BLUE;
    })
    .attr("fill", function () {
      return login.isTheme("light") ? "white" : "#1c1c1e";
    })
    .attr("cursor", function (d) {
      const profile = d.data.profile;
      return profile == CONST_TREE_RULE.ANY ||
        profile == CONST_TREE_RULE.ANYOTHER ||
        profile == CONST_TREE_RULE.ROOT
        ? "auto"
        : "pointer";
    })
    .on("click", function (_, d) {
      const { profile, type } = d.data;
      if (
        profile !== CONST_TREE_RULE.ANY &&
        profile !== CONST_TREE_RULE.ANYOTHER
      ) {
        const viewName = "viewRulesFlow";
        const shortcutF = TYPE_PROFILE_SHORTCUTF_MAP[type];
        if (type === "policy") {
          shortcutF(profile, viewName, {tab:0});
        } else {
          shortcutF(profile, viewName);
        }
      }
    })
    .on("mouseover", function (e, d) {
      const { profile } = d.data;
      const { offsetX, offsetY } = e;
      if (
        profile !== CONST_TREE_RULE.ANY &&
        profile !== CONST_TREE_RULE.ANYOTHER &&
        profile.length > CONST_TREE_RULE.NAMES_MAX_LEN
      ) {
        actions.send("mouse-over-rule", {
          x: offsetX,
          y: offsetY,
          content: profile,
        });
      }
    })
    .on("mouseout", function () {
      actions.send("mouse-over-rule", null);
    })
    .attr("r", function (d) {
      return d.data.profile === CONST_TREE_RULE.ANY ||
        d.data.profile === CONST_TREE_RULE.ROOT
        ? smallNodeRadius
        : bigNodeRadius;
    });

  // Add node legend
  circleGroups
    .append("text")
    .text(function (d) {
      const { data } = d;
      return data.profile == "any" ? "" : toMaxNameLen(data.profile);
    })
    .attr("dy", ".5em")
    .attr("text-anchor", "middle")
    .attr("y", function (d) {
      return -bigNodeRadius * 2.8;
    })
    .attr("class", "tree-legend")
    .style("font-size", `${newFontSizeLegend}px`);

  // Add terminal rule legend and conflict info
  circleGroups
    .filter(function (d) {
      const { data } = d;
      return data.children === undefined;
    })
    .append("text")
    .text(function (d) {
      const { data } = d;
      return data.ruleId;
    })
    .attr("x", 45)
    .attr("y", -3)
    .style("font-size", `${newFontSizeLegend}px`)
    .attr("class", "tree-legend-signal")
    .attr("fill", function (d) {
      const { data } = d;
      return data.conflicts ? "orange" : "green";
    })
    .attr("dy", ".5em")
    .attr("text-anchor", "middle");

  // Add terminal edit icon
  circleGroups
    .filter(
      (d) =>
        isVoid(d.data.children) &&
        (d.defaultRule = isDefaultRule(d.data, d.parent)) !== true
    )
    .filter(function (d) {
      return d.data.children == null;
    })
    .append("g")
    .attr("class", "treeIconEdit")
    .append("path")
    .attr("transform", `translate(65, -13) scale(${scaleIcons}, ${scaleIcons})`)
    .attr("fill", "#747474")
    .attr("d", CONST_TREE_RULE.EDIT_ICON)
    .style("cursor", "pointer")
    .attr("class", "hidden-to-operators");

  // Add terminal clickable square area (24x24)
  circleGroups
    .filter((d) => {
      const { data } = d;
      return isVoid(data.children) && d.defaultRule !== true;
    })
    .attr("class", "treeIconEditClickArea")
    .append("rect")
    .attr("transform", `translate(65, -13) scale(${scaleIcons}, ${scaleIcons})`)
    .attr("width", 24)
    .attr("height", 24)
    .attr("fill", "transparent")
    .style("cursor", "pointer")
    .on("click", function (_, d) {
      if (d.defaultRule === true) {
        return;
      }
      const ruleId = d.data.ruleId;
      const rule = rules.find((rule) => rule.id === ruleId);
      if (rule) {
        actions.send("open-rule-modal", { type: "Edit", rule });
      }
    });

  // Add delete icon
  circleGroups
    .filter((d) => {
      const { data } = d;
      return isVoid(data.children) && d.defaultRule !== true;
    })
    .append("g")
    .attr("class", "treeIconDelete flow")
    .append("path")
    .attr("transform", `translate(90, -13) scale(${scaleIcons}, ${scaleIcons})`)
    .attr("fill", "#747474")
    .attr("d", CONST_TREE_RULE.DELETE_ICON)
    .style("cursor", "pointer")
    .attr("class", "hidden-to-operators")
    .on("click", function (_, d) {
      if (d.defaultRule === true) {
        return;
      }

      const ruleId = d.data.ruleId;
      const rule = rules.find((rule) => rule.id === ruleId);
      if (rule) {
        showModalConfirm(
          "Remove rule ?",
          "WARNING: Rule data will be lost",
          "delete_forever",
          () => {
            const cmd = buildCommand("clear", rule);

            ifCl
              .run(cmd)
              .then((response) => {
                if (response.length === 0) {
                  actions.send("do-load");
                } else if (response.substring(0, 5) === "%WARN") {
                  showModalInfo("Warning:", response);
                } else {
                  showModalError("Error:", response);
                }
              })
              .catch((error) => showModalError(error));
          }
        );
      }
    });

  return xSpacing;
}

export function getHeaderData(xSpacing, rulesTree) {
  const hdrYoff = 20;
  let headerData = [{ type: "", x: xSpacing[0], y: hdrYoff }];

  rulesTree.forEach((dataItem, i) => {
    headerData.push({
      type: TYPE_PROFILE_HEADER_MAP[dataItem.type],
      x: xSpacing[i + 1],
      y: hdrYoff,
    });
  });

  headerData.push({
    type: "RULE",
    x: xSpacing[xSpacing.length - 1] + 46,
    y: hdrYoff,
  });
  headerData.push({
    type: "ACTIONS",
    x: xSpacing[xSpacing.length - 1] + 96,
    y: hdrYoff,
  });
  return headerData;
}

export function renderSvgHeader(rulesFlowTreeHdr, headerData, chartWidth) {
  const hdrs = rulesFlowTreeHdr
    .selectAll("text")
    .data(headerData)
    .enter()
    .append("text");

  const newFontSizeTreeHdr = getFontSizeTree(chartWidth, CONST_TREE_RULE.WIDTH);

  hdrs
    .text(function (d) {
      return d.type;
    })
    .attr("class", "treeHdrText")
    .attr("text-anchor", "middle")
    .style("font-size", `${newFontSizeTreeHdr}px`)
    .attr("y", function (d) {
      return d.y;
    })
    .attr("x", function (d) {
      return d.x;
    });
}

export function resizeRulesTree(chartRef) {
  const chartWidth = chartRef.current.clientWidth;
  const svg = d3.select(chartRef.current);
  const nFontSizeTree = getFontSizeTree(chartWidth, CONST_TREE_RULE.WIDTH);
  const nSmallInnerRadius = getRadiusSmall(chartWidth, CONST_TREE_RULE.WIDTH);
  const nBigInnerRadius = getRadiusBig(chartWidth, CONST_TREE_RULE.WIDTH);
  const nScaleIcon = getScaleIcons(chartWidth, CONST_TREE_RULE.WIDTH);
  d3.selectAll(".treeHdrText").style("font-size", `${nFontSizeTree}px`);
  d3.selectAll(".tree-legend")
    .style("font-size", `${nFontSizeTree}px`)
    .attr("y", -nBigInnerRadius * 3);
  d3.selectAll(".tree-legend-signal").style("font-size", `${nFontSizeTree}px`);
  const newScaleIc = CONST_TREE_RULE.WIDTH / chartWidth;
  const iconDelete = svg.selectAll(".treeIconDelete path");
  const iconEdit = svg.selectAll(".treeIconEdit path", "rect.treeIconEdit");
  iconDelete.attr(
    "transform",
    `translate(90, ${
      -nBigInnerRadius * 1.5
    }) scale(${nScaleIcon}, ${nScaleIcon})`
  );
  iconEdit.attr(
    "transform",
    `translate(65, ${
      -nBigInnerRadius * 1.5
    }) scale(${nScaleIcon}, ${nScaleIcon})`
  );
  svg.selectAll(".tree-circle-small").attr("r", nSmallInnerRadius);
  svg.selectAll(".tree-circle-big").attr("r", nBigInnerRadius);
}

export function buildCommand(type, rule) {
  const safeProfs = Object.entries(rule).reduce(
    (result, [field, value]) =>
      value !== CONST_TREE_RULE.ANY && field !== "poli" && field !== "id"
        ? result + " " + safeStr(value)
        : result,
    ""
  );
  const cmd = `${type} rule flow ${
    safeProfs.length > 0 ? safeProfs : "any"
  } policy ${safeStr(rule.poli)}`;
  return cmd;
}
