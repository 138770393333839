import * as d3 from "d3";

export function getKeysSeries(header) {
  const [, ...keys] = header.split(/\s+/);
  return keys.map((key) => {
    const chunks = key.split("-").map((item) => {
      return item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
    });
    return chunks.join("-");
  });
}

export const parseDate = d3.timeParse("%Y-%m-%dT%H:%M:%S");

export function getInRangeItems(items, range, fields, sampleSize) {
  if (items.length === 0 || fields.length === 0) {
    return [];
  }

  const { from, to } = range;
  let inrangeItems = [];
  let maxItems;
  for (let i = 0; i < items.length; i += 1) {
    const { time } = items[i];
    if (from <= time && time <= to) {
      if (!maxItems) {
        maxItems = { ...items[i] };
      }
      if (i % sampleSize === 0) {
        inrangeItems.push(maxItems);
        maxItems = { ...items[i] };
      } else {
        const newMax = fields.reduce((acc, value) => {
          if (items[i][value] > acc[value]) {
            acc[value] = items[i][value];
          }
          return acc;
        }, maxItems);
        maxItems = newMax;
      }
    }
  }

  if (inrangeItems.length === 0) {
    return [];
  }

  if (
    inrangeItems[inrangeItems.length - 1]?.time &&
    items[items.length - 1]?.time &&
    inrangeItems[inrangeItems.length - 1].time !== items[items.length - 1]?.time
  ) {
    const { time } = items[items.length - 1];
    if (from <= time && time <= to) {
      inrangeItems.push(items[items.length - 1]);
    }
  }

  let extendedItems = [...inrangeItems];
  if (extendedItems.length !== 0) {
    while (extendedItems[0].time >= from) {
      const emptyElement = fields.reduce((acc, value) => {
        acc[value] = null;
        return acc;
      }, {});
      const time = new Date(
        Date.parse(extendedItems[0].time) - 5 * 60000 * sampleSize
      );
      extendedItems.unshift({ ...emptyElement, time });
    }
  }
  return extendedItems;
}

export const inRange = (beginRange=null, endRange=null) =>
  beginRange === null && endRange === null
  ? () => true
  : time => beginRange < time && time < endRange


