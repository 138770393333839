import React, {
  useState,
  useContext
} from "react";
import Modal from "common/Modal.jsx";
import ActionsContext from "common/ActionsContext";

const FlowPoliciesHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);
  actions.recv("open-help", function () {
    setOpen(true);
  });
  const doClose = () => setOpen(false);
  if (open !== true) {
    return null;
  }

  return (
    <Modal
      title="FLOW POLICIES"
      onClose={doClose}
      content={() => (
        <>
          <p>Subscriber flow policies. Define the functions to perform on all the subscriber flows that are assigned to each policy.</p>
          <ul>
            <li><b>NAME</b>: Policy name.</li>
            <li><b>FLOWS</b>: Number of active flows.</li>
            <li><b>MBYTES-DN</b>: Traffic in the downlink direction.</li>
            <li><b>MBYTES-UP</b>: Traffic in the upSpeed direction.</li>
            <li className="f-dpi-per-user"><b>BLOCK</b>: Block flow (traffic is dropped).</li>
            <li><b>TCPO</b>: Percentage of TCP traffic to be optimized. The rest will not be optimized, but will be measured, to obtain acceleration statistics.</li>
            <li><b>COMPRESS</b>: When accelerating, compress flow.</li>
            <li><b>SHAPE-SUBS-DN</b>: Shaping speed limit in the downlink direction (Mbps or % of rate policy for the subscriber). Limit shared by all flows matching this policy with same client IP address.</li>
            <li><b>SHAPE-SUBS-UP</b>: Shaping speed limit in the upSpeed direction (Mbps or % of rate policy for the subscriber). Limit shared by all flows matching this policy with same client IP address.</li>
            <li><b>SHAPE-FLOW-DN</b>: Shaping speed limit in the downlink direction (Mbps or % of rate policy for the subscriber). Limit per flow for all flows matching this policy.</li>
            <li><b>SHAPE-FLOW-UP</b>: Shaping speed limit in the upSpeed direction (Mbps or % of rate policy for the subscriber). Limit per flow for all flows matching this policy.</li>
            <li><b>PRIO</b>: Shaping priority for this policy. An integer from -5 to 5. 0 means no special priority assigned. Negative numbers reduce the priority and positive numbers increase the priority.</li>
            <li><b>DROP</b>: This policy is configured to drop Internet-initiated flows.</li>
            <li><b>QUOTA</b>: The traffic falling into this policy counts for quota limitations.</li>
            <li><b>SKIP-RATE</b>: This policy is configured to skip rate limitations imposed by rate policy.</li>
            <li><b>SKIP-SUBGR</b>: This policy is configured to skip rate limitations imposed by subscriber-groups.</li>
            <li><b>ACTIONS</b>: Allowed operations on this policy.</li>
          </ul>
          <p>Click on the <i className="material-icons valign-icon">delete</i> icon to delete an already configured policy.</p>
          <p>Click on the <i className="material-icons valign-icon">refresh</i> icon to refresh policies information.</p>
        </>
      )}
    />
  );
};

export default FlowPoliciesHelpModal;
