import React, { useEffect, useState, useContext, useRef } from "react";
import TextInput from "common/TextInput";
import { getFormData } from "common/utils";
import ActionsContext from "common/ActionsContext";
import { classForType } from "./ListItems";
import Modal from "common/Modal.jsx";
import ColumnsGrid from "common/layouts/ColumnsGrid";

const doesNothing = () => {};

const combineLabel = (label = null, clarify = null) =>
  label === null || label.length === 0
    ? ""
    : clarify
    ? `${label} (${clarify})`
    : label;

const Field = ({
  name,
  value,
  className = "as-column",
  type,
  InputComponent = TextInput,
  label = "",
  labelSingle = "",
  clarify = null,
  ...settings
}) => (
  <InputComponent
    className={`input-control ${className} ${classForType(type)}`}
    readOnly={false}
    defaultValue={value}
    {...settings}
    name={name}
    label={combineLabel(labelSingle || label, clarify)}
  />
);

const preventDefaultSubmitAndDo = (fn) => (event) => {
  if (event.key === "Enter") {
    if (event.target.tagName !== "TEXTAREA") {
      event.preventDefault();
      setTimeout(() => fn(event), 1);
    }
  }
};

const ModificationsModal = ({
  cliName = "",
  labelSingle = "",
  fields = [],
  onCreate = doesNothing,
  onUpdate = doesNothing,
}) => {
  const form = useRef(null);
  const [open, setOpen] = useState(false);
  const [editing, setEditing] = useState(null);
  const [error, setError] = useState(null);
  const actions = useContext(ActionsContext);
  const doOpen = () => {
    setError(null);
    setOpen(true);
  };
  const doClose = () => {
    setOpen(false);
  };
  useEffect(
    () =>
      actions.recv(`show-edit-${cliName}-modal`, function (item) {
        setEditing(item);
        doOpen();
      }),
    [cliName]
  );
  useEffect(
    () =>
      actions.recv(`show-create-${cliName}-modal`, function () {
        setEditing(null);
        doOpen();
      }),
    [cliName]
  );
  if (open !== true) {
    return null;
  }
  const handleChange = (event) => {
    if (event.key !== "Enter") {
      setError(null);
    }
  };
  const doApplyAndClose = () => {
    try {
      const update = getFormData(form.current);
      if (editing === null) {
        onCreate({ ...update });
      } else {
        onUpdate({ ...editing, ...update });
      }
      doClose();
    } catch (error) {
      setError(String(error));
      throw error;
    }
    return false;
  };

  return (
    <Modal
      title={`${editing ? "Edit" : "Add"} ${labelSingle}`}
      superIcon={editing ? "edit" : "add_circle"}
      onApply={doApplyAndClose}
      onClose={doClose}
      closeLabel="CANCEL"
      wrapIntoForm={false}
      content={() => (
        <form
          ref={form}
          onSubmit={doApplyAndClose}
          onKeyDown={preventDefaultSubmitAndDo(doApplyAndClose)}
        >
          <ColumnsGrid columns={2} minWidth="25ch" rowGap="0">
            {fields.map(
              ({ name, editionClassName, defaultValue, ...settings }) => (
                <Field
                  edit={true}
                  className={editionClassName || "full-width"}
                  key={name}
                  name={name}
                  onChange={handleChange}
                  {...settings}
                  value={editing ? editing[name] : defaultValue}
                  defaultValue={editing ? editing[name] : defaultValue}
                />
              )
            )}
          </ColumnsGrid>
        </form>
      )}
      footerMessage={
        (error !== null && (
          <span className="modal-err-msg color-red align-left">{error}</span>
        )) ||
        null
      }
    />
  );
};

export default ModificationsModal;
