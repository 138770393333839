import React, { useState, useContext } from "react";
import ActionsContext from "common/ActionsContext";
import Modal from "common/Modal.jsx";

const SubscriberGroupProfileSettingsHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);
  actions.recv("open-settings-help", function () {
    setOpen(true);
  });
  const doClose = () => setOpen(false);
  if (open !== true) {
    return null;
  }

  return (
    <Modal
      title="Subscriber Group Profiles"
      onClose={doClose}
      content={() => (
        <React.Fragment>
          <p>This page shows the subscriber Groups assigned to this profile.</p>
          <ul>
            <li>
              <b>PROFILE NAME</b>: Profile name.
            </li>
            <li>
              <b>SUBSCRIBER GROUPS</b>: Pattern of the Subscriber id. Wildchar * is
              supported.
            </li>
            <li>
              <b>PRIORITY</b>: Evaluation priority. Lower numbers evaluated
              first. (Number from -9999 to 9999).
            </li>
          </ul>
          <p>
            Click on the <i className="material-icons valign-icon">refresh</i>{" "}
            icon to refresh profile information.
          </p>
          <p>
            Click on the <i className="material-icons valign-icon">more_vert</i>{" "}
            icon to do the following actions:
          </p>
          <ul>
            <li>
              <b>Add Subscriber Group</b>: Add a new subscriber Group to the profile.
            </li>
            <li>
              <b>Merge Subscriber-Groups from file</b>: Add new subscriber Groups from
              a file.
            </li>
            <li>
              <b>Replace Subscriber-Groups from file</b>: Remove existing
              subscriber Groups and replace them with those in the file.
            </li>
          </ul>
          <p>
            Format of the file: <strong>.txt</strong>. It must contain
            subscriber Groups in new lines with no headers. Subscriber-Groups must be
            shorter than 64 characters. All spaces in the Subscriber-Group field
            will be substituted with the '_' character.{" "}
          </p>
          <p>
            <strong>Example:</strong>
            <p></p> Joe_Smith <p> subsGroupForCustomerX </p> e4:8d:8c:a6:55:e8
          </p>
        </React.Fragment>
      )}
    />
  );
};

export default SubscriberGroupProfileSettingsHelpModal;
