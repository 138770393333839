import React, { useState, useContext } from "react";
import ActionsContext from "common/ActionsContext";
import Modal from "common/Modal.jsx";

const RulesTreeHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);
  actions.recv("open-help", function () {
    setOpen(true);
  });
  const doClose = () => setOpen(false);
  if (open !== true) {
    return null;
  }

  return (
    <Modal
      title="Subscriber Flow Rules"
      onClose={doClose}
      content={() => (
        <>
           <p>Subscriber flow rules. Rules that determine which subscriber flow policy is assigned to each flow, as a function of how the flow matches the profiles in the rules. Every flow can be assigned one, and only one, suscriber flow policy. The subscriber flow policy determines the functions to perform on all the flows of each subscriber that match the policy. Rule matching is performed by analyzing profiles in the following order:</p>
        <ul>
          <li><b>INTERFACE</b>: Interface profile.</li>
          <li><b>VLAN</b>: VLAN profile.</li>
          <li><b>POLICY-RATE</b>: Rate policy profile.</li>
          <li><b>INTERNET</b>: Internet addresses profile.</li>
          <li><b>SUB-GROUP</b>: Subscriber group profile.</li>
          <li><b>SUB-ID</b>: Subscriber ID profile.</li>
          <li><b>ACCESS</b>: Access addresses profile.</li>
          <li><b>TIME</b>: Time profile.</li>
          <li><b>THROUGHPUT</b>: Throughput profile.</li>
          <li><b>DPI</b>: DPI profile.</li>
        </ul>
        <p>If more than one rule matches, the one matching the profile higher up in this order is selected. If two rules match, and the highest order matching profile is the same, then the rule with the more restrictive profile is selected. If, however, the two profiles contain the same matching element, then there is a conflict, and the rule number will point to the rules that have conflicts, so that it is resolved.</p>
        <p>Click on the <i className="material-icons valign-icon">edit</i> icon to edit an already configured rule.</p>
        <p>Click on the <i className="material-icons valign-icon">delete</i> icon to delete an already configured rule.</p>
        <p>Click on the <span className="hyperlink-text">profile name</span> to edit profile content.</p>
        <p>Click on the <span className="hyperlink-text">policy name</span> to edit the policy parameters.</p>
        <p>To add a new rule, click on the "Add Rule.." button, or press the <i className="material-icons valign-icon">more_vert</i> icon and then select the <b>Add Rule...</b> option.</p>
        <p>Click on the "Add Policy.." button to add a new policy.</p>
        <p>Click on the <i className="material-icons valign-icon">refresh</i> icon to refresh rules information.</p>
        </>
      )}
      onClose={doClose}
    />
  );
};
export default RulesTreeHelpModal;
