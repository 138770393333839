import React, { useState, useContext } from "react";
import ActionsContext from "common/ActionsContext";
import Modal from "common/Modal.jsx";

const ConfigSubscriberIdProfilesHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);
  actions.recv("open-help", function () {
    setOpen(true);
  });
  const doClose = () => setOpen(false);
  if (open !== true) {
    return null;
  }

  return (
    <Modal
      title="Subscriber ID Profiles"
      onClose={doClose}
      content={() => (
        <React.Fragment>
          <p>
            This page shows the subscriber id profiles defined in the system.
          </p>
          <ul>
            <li>
              <b>PROFILE NAME</b>: Profile name.
            </li>
            <li>
              <b>PRIORITY</b>: Evaluation priority. Lower numbers evaluated
              first. (Number from -9999 to 9999).
            </li>
            <li>
              <b>SUBSCRIBER IDS</b>: Pattern of the Subscriber ID name. Wildchar
              * is supported.
            </li>
          </ul>
          <p>
            Press the <b>Add Profile...</b> option to add a new subscriber ID
            profile.
          </p>
          <p>
            Click on the <i className="material-icons valign-icon">edit</i> icon
            to edit an already configured profile.
          </p>
          <p>
            Click on the <i className="material-icons valign-icon">delete</i>{" "}
            icon to delete all entries of the corresponding profile.
          </p>
          <p>
            Press the <i className="material-icons valign-icon">more_vert</i>{" "}
            icon and then select the <b>Add Profile...</b> option to add a new
            profile.
          </p>
          <p>
            Click on the <i className="material-icons valign-icon">refresh</i>{" "}
            icon to refresh profiles information.
          </p>
        </React.Fragment>
      )}
    />
  );
};

export default ConfigSubscriberIdProfilesHelpModal;
