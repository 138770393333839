import React, {useState, useEffect} from "react";
import { InputLabel, MenuItem, FormControl, Select } from "@mui/material";
import styled from "styled-components";

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    &.input-group {
      height: initial; /*Height 100% with margin bottom invades elements below*/
    }

    .MuiSelect-select {
      font-family: AllianceNo2-SemiBold;
      font-weight: 100;
      color: var(--Text-Primary);
    }
`;

const IconContainer = styled.div`
  font-family: AllianceNo2-SemiBold;
  font-weight: 100;
  padding-right: 10px;

  i {
    font-size: 18px;
    color: var(--Text-Primary);
  }
`;

const LabelContainer = styled.div`
  font-family: AllianceNo2-SemiBold;
  font-weight: 100;
  white-space: nowrap;
`;

const DownIconContainer = styled.div`
  i {
    font-size: 16px;
    font-weight: 600;
    color: var(--text-primary);
  }
  i.MuiSelect-iconOpen {
    transform: rotate(180deg);
  }
`;

const DownIcon = ({ className }) => {
  return (
    <DownIconContainer>
      <i className={`${className} material-icons`}>expand_more</i>
    </DownIconContainer>
  );
};

const doesNothing = () => {};

const SelectInput = ({
  className = "",
  disabled = false,
  icon = null,
  name = "",
  title = "",
  defaultValue = null,
  options = [],
  onChange = doesNothing,
  disableUnderLine = true,
  label = "",
}) => {
  const [selected, setSelected] = useState(defaultValue);

  useEffect(() => {
    setSelected(defaultValue);
  }, [defaultValue]);

  const handleChange = (event) => {
    const { value } = event.target;
    setSelected(value);
    onChange(event);
  };

  return (
    <Wrapper className={`${className}`}>
      {icon ? (
        <IconContainer title={title || name}>
          <i className="material-icons">{icon}</i>
        </IconContainer>
      ) : null}
      {label ? <LabelContainer>{label}:</LabelContainer> : null}
      <Select
        name={name}
        value={selected}
        onChange={handleChange}
        title={title}
        IconComponent={DownIcon}
        disableUnderLine={disableUnderLine}
        disabled={disabled}
        sx={{
          fontSize: "12px",
          fontFamily: "AllianceNo2-SemiBold",
          width: "100%",
          boxShadow: "none",
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              border: 0,
            },
          ".MuiInputBase-input": {
            padding: "4px 14px",
            borderBottom: "#ddd 1px solid",
            borderRadius: "0px",
          },
        }}
      >
        {options.map(({ value, label }) => (
          <MenuItem
            value={value}
            key={value}
            sx={{
              fontSize: "12px",
              fontFamily: "AllianceNo2-SemiBold !important",
            }}
          >
            {label}
          </MenuItem>
        ))}
      </Select>
    </Wrapper>
  );
};

export default SelectInput;
