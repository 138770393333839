export { Profiles } from "./Profiles";
export { ProfileSettings } from "./Settings";
import React from "react";
import styled from "styled-components";
import { Profiles } from "./Profiles";
import { ProfileSettings } from "./Settings";

const ShowsOnlyFirst = styled.div`
  & > * + * {
    display: none !important;
  }
`;

const ProfilesAndSettings = ({ ...settings }) => (
  <ShowsOnlyFirst>
    {<ProfileSettings {...settings} />}
    {/*or will show*/}
    <Profiles {...settings} />
  </ShowsOnlyFirst>
);

export default ProfilesAndSettings;
