import React, { useState, useContext } from "react";
import ActionsContext from "common/ActionsContext";
import Modal from "common/Modal";

const ConfigVLANProfilesHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);
  actions.recv("open-help", function () {
    setOpen(true);
  });
  const doClose = () => setOpen(false);
  if (open !== true) {
    return null;
  }

  return (
    <Modal
      title="VLAN Profiles"
      onClose={doClose}
      content={() => (
        <React.Fragment>
          <p>
            VLAN Profiles. This profile matches all traffic from the listed VLAN
            tags.
          </p>
          <ul>
            <li>
              <b>NAME</b>: Profile name.
            </li>
            <li>
              <b>VLAN TAGS</b>: VLAN tag, <i>none</i> for no tag.
            </li>
          </ul>
          <p>
            Click on the <i className="material-icons valign-icon">edit</i> icon
            to edit an already configured profile.
          </p>
          <p>
            Click on the <i className="material-icons valign-icon">delete</i>{" "}
            icon to delete all entries of the corresponding profile.
          </p>
          <p>
            Press the <i className="material-icons valign-icon">more_vert</i>{" "}
            icon and then select the <b>Add Profile...</b> option to /add a new
            profile.
          </p>
          <p>
            Click on the <i className="material-icons valign-icon">refresh</i>{" "}
            icon to refresh profiles information.
          </p>
        </React.Fragment>
      )}
    />
  );
};

export default ConfigVLANProfilesHelpModal;
