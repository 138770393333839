import { styled } from "@mui/material/styles";
import SubscriberGroupDashboard from "views/Statistics/SubscriberGroupDashboard/component";
import { SubscribersAndDetails } from "../Subscribers/SubscribersAndDetails";
import SubscriberGroupEdit from "views/Status/SubscriberGroupsEdit/component";
import VerifiedSubcriberDetail from "views/Statistics/SubscriberDetail/component";
  
import SubscriberThroughputLive from "./component";

const DetailsLayer = styled("div")(() => ({
  position: "absolute",
}));

function ThroughputAndDashboard({
  returnView,
  ipAddress,
  addr,
  subsId,
  dpiEnabled,
  direction,
  timePeriod,
  metric,
  subscriberGroup,
  edit,
  view,
  use,
  liveMode,
  timeWindow,
  output
}) {
  const params = {
    returnView,
    ipAddress,
    addr,
    subsId,
    dpiEnabled,
    direction,
    timePeriod,
    metric,
    subscriberGroup,
    use,
    liveMode,
    timeWindow,
    output
  };
  const showSubscriberDetail = (!!addr || !!subsId);

  return (
    <>
      <div
        style={{
          opacity:
          showSubscriberDetail ? 0 : 1,
        }}
      >
        <SubscriberThroughputLive {...params} />
      </div>
      {showSubscriberDetail && (
        <DetailsLayer className="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-l-0 p-r-0">
          <SubscribersAndDetails {...params} />
        </DetailsLayer>
      )}
    </>
  );
}

export default ThroughputAndDashboard;
