import React, { useRef } from "react";

const doesNothing = () => {};

const SpinnerInput = ({
  defaultValue,
  size = 2,
  max = 0,
  min = 100,
  onChange = doesNothing,
}) => {
  const [spinner, input] = [useRef(), useRef()];
  const increase = () => {
    const value = parseInt(input.current.value, 10);
    if (value < max) {
      input.current.value = String(value + 1).padStart(size, '0');
      onChange(input.current.value);
    }
  };
  const decrease = () => {
    const value = parseInt(input.current.value, 10);
    if (value > min) {
      input.current.value = String(value - 1).padStart(size, '0');
      onChange(input.current.value);
    }
  };
  return (
    <div
      className="input-group spinner"
      data-trigger="spinner"
      ref={spinner}
      style={{ width: `${size + 5}ch` }}
    >
      <input
        type="number"
        className="form-control text-center"
        ref={input}
        defaultValue={defaultValue}
        onChange={onChange}
        min={min}
        max={max}
        size={size}
        style={{ width: `${size}ch` }}
        pattern={`[0-9]{${size}}`}
        title="Values should contain 2 digits, add a padding 0 when below 10"
      />
      <span className="input-group-addon">
        <a onClick={increase} className="spin-up" data-spin="up">
          <i className="glyphicon glyphicon-chevron-up"></i>
        </a>
        <a onClick={decrease} className="spin-down" data-spin="down">
          <i className="glyphicon glyphicon-chevron-down"></i>
        </a>
      </span>
    </div>
  );
};

export default SpinnerInput;
