import React, { useState, useEffect, useContext } from "react";
import isEqual from "lodash.isequal";
import Modal from "common/Modal";
import ActionsContext from "common/ActionsContext";
import SelectInputWithIcon from "common/SelectInputWithIcon";
import { safeStr } from "common/api";
import { buildCommand } from "../RulesTree/utils";
import { PROFS, POLI, CONST_TREE_RULE } from "./constants";

function getDuplicatedRule(rules, rule) {
  return rules.find((ruleX) => {
    const newRule = { ...ruleX };
    delete newRule.id;
    return isEqual(newRule, rule);
  });
}

function getProfilesInfoFns(ifCl) {
  return [
    ...PROFS.map((prof) => {
      return ifCl.run(`show profile ${prof.type}`);
    }),
    ifCl.run("show policy flow"),
  ];
}

const doLoad = (ifCl) => {
  return Promise.all(getProfilesInfoFns(ifCl)).then((response) => {
    return response.map((respProfile, index) => {
      const [header, ...rows] = respProfile.trim(/\s/).split("\n");
      const names = rows.reduce((accNames, row) => {
        const [name] = row.split(/\s+/);
        accNames.push(name);
        return accNames;
      }, []);
      return names;
    });
  });
};

const OPTIONS_PROFILES = [...PROFS, POLI].reduce((acc, value) => {
  const { attr } = value;
  if (attr !== "poli") {
    acc[attr] = [{ label: CONST_TREE_RULE.ANY, value: "any" }];
  } else {
    acc[attr] = [
      { label: CONST_TREE_RULE.ANY, value: "any" },
      { label: "flow-default", value: "flow-default" },
    ];
  }
  return acc;
}, []);

const OPTIONS_SELECTED_INIT = [...PROFS, POLI].reduce((acc, value) => {
  const { attr } = value;
  acc[attr] = "any";
  return acc;
}, {});

const ModalContent = ({ rule, options, selection, setSelection }) => {
  const handleChange = ({ target }) => {
    const { value, name } = target;
    setSelection({ ...selection, [name]: value });
  };
  return (
    <div>
      {[...PROFS, POLI].map(({ titleEdit, attr }) => {
        const optionsProfile = options[attr] || [];
        const { optionsUn } = optionsProfile.reduce(
          (acc, option) => {
            const { value } = option;
            if (!acc.set.has(value)) {
              acc.set.add(value);
              acc.optionsUn.push(option);
            }
            return acc;
          },
          { optionsUn: [], set: new Set() }
        );
  
        return (
          <div
            className="form-group margin-b-15 margin-t-15"
            key={`profile-${attr}`}
          >
            <label>{titleEdit}:</label>
            <SelectInputWithIcon
              title={titleEdit}
              name={attr}
              icon="place"
              selected={selection[attr]}
              onChange={handleChange}
              options={optionsUn}
            />
          </div>
        );
      })}
    </div>
  );
};

const RuleModal = ({ rules }) => {
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("Add");
  const [options, setOptions] = useState(OPTIONS_PROFILES);
  const [selectedOptions, setSelectedOptions] = useState({...OPTIONS_SELECTED_INIT});
  const [rule, setRule] = useState();
  const [errorFooter, setErrorFooter] = useState("");
  const actions = useContext(ActionsContext);
  const doClose = () => setOpen(false);

  useEffect(() => {
    return actions.recv("open-rule-modal", function ({ rule, type }) {
      setType(type);

      if (rule) {
        const byRuleSlectedOptions = Object.entries(rule).reduce(
          (acc, [name, value]) => {
            if (acc[name]) {
              acc[name] = value;
            }
            return acc;
          },
          selectedOptions
        );
        setSelectedOptions({...byRuleSlectedOptions});
        setRule(rule);
      }
      setErrorFooter('');
      setOpen(true);
      doLoad(ifCl).then((parsedResponse) => {
        const newOptions = parsedResponse.reduce(
          (acc, value, index) => {
            let attr;
            if (index === PROFS.length) {
              attr = "poli";
            } else {
              attr = PROFS[index].attr;
            }
            const optionsNames = value.reduce((accOptions, option) => {
              if (attr === "poli" && option === "flow-default") {
                return accOptions;
              } else {
                accOptions.push({ label: option, value: option });
                return accOptions;
              }
            }, []);

            acc[attr] = [...optionsNames, ...acc[attr]];
            return acc;
          },
          { ...OPTIONS_PROFILES }
        );
        setOptions(newOptions);
      });
    });
  }, []);

  if (open === false) {
    return null;
  }

  const duplicate = getDuplicatedRule(rules, selectedOptions);

  const handleApplyRule = (response) => {
    if (response.length === 0) {
      setSelectedOptions({...OPTIONS_SELECTED_INIT});
      actions.send("do-load");
    } else if (response.substring(0, 5) == "%WARN") {
      showModalInfo("Warning:", response);
      actions.send("do-load");
    } else {
      showModalError("Error:", response);
    }
    setOpen(false);
  };

  const setNewRule = (rule) => {
    ifCl
      .run(buildCommand("set", selectedOptions))
      .then(handleApplyRule)
      .catch((error) => {
        showModalError("Error:", error);
        setOpen(false);
      });
  };

  const applyRule = () => {
    if (type === "Edit") {
      if (selectedOptions["poli"] === "any") {
        setErrorFooter("No policy specified");
      } else {
        ifCl
        .run(buildCommand("clear", rule))
        .then(() => ifCl.run(buildCommand("set", selectedOptions)))
        .then(handleApplyRule)
        .catch((error) => {
          showModalError("Error:", response);
          setOpen(false);
          setNewRule(rule);
        });
      }
    } else {
      if (selectedOptions["poli"] === "any") {
        setErrorFooter("No policy specified");
      } else if (duplicate !== undefined) {
        setErrorFooter("Duplicated rule");
      } else {
        setNewRule(selectedOptions);
      }
    }
  };

  const handleSelectionChange = (options) => {
    setErrorFooter("");
    setSelectedOptions({...options});
    const duplicate = getDuplicatedRule(rules, options);
    if(duplicate !== undefined){
      setErrorFooter("Duplicated rule");
    }
  };

  return (
    <Modal
      title={`${type} flow rule`}
      superIcon={type === "Add" ? "add_circle" : "edit"}
      large={false}
      content={() => (
        <ModalContent
          rule={rule}
          options={options}
          selection={{...selectedOptions}}
          setSelection={handleSelectionChange}
        />
      )}
      applyLabel="OK"
      onApply={applyRule}
      applyDisabled={duplicate !== undefined}
      closeLabel="CANCEL"
      onClose={() => {
        setSelectedOptions({...OPTIONS_SELECTED_INIT});
        setOpen(false);
      }}
      footerMessage={
        errorFooter !== "" ? (
          <span className="modal-err-msg color-red">
            <h5>{errorFooter}</h5>
          </span>
        ) : null
      }
    />
  );
};

export default RuleModal;