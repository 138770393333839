import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useLayoutEffect,
} from "react";
import styled from "styled-components";
import * as d3 from "d3";

import ActionsContext from "common/ActionsContext";

import { MARGIN, CONST_TREE_RULE } from "./constants";
import {
  parseRulesTree,
  buildFlatTree,
  fromFlatToTree,
  getRadiusSmall,
  getRadiusBig,
  getFontSizeTree,
  toMaxNameLen,
  isVoid,
  isDefaultRule,
  getScaleIcons,
  renderSvgTree,
  getHeaderData,
  renderSvgHeader,
  resizeRulesTree,
} from "./utils";

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

const WrapperNoContent = styled.div`
  width: 100%;
  position: relative;
  padding: 15px 15px 30px;
  color: var(--text-primary);
`;

const RulesTreeWrapper = styled.div`
  width: 100%;
  position: relative;

  svg text:not(.tree-legend-signal) {
    fill: var(--text-primary);
  }
`;

const TooltipWrapper = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  font-size: 12px;
  padding: 6px;
  transform: translate(-50%, -50%);
  font-family: "AllianceNo2-Regular" !important;
`;

const Tooltip = () => {
  const actions = useContext(ActionsContext);
  const [data, setData] = useState(null);
  useEffect(() => {
    return actions.recv("mouse-over-rule", (data) => {
      setData(data);
    });
  }, []);

  if (data === null) {
    return null;
  }

  const { x, y, content } = data;

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        pointerEvents: "none",
      }}
    >
      <TooltipWrapper
        style={{
          position: "absolute",
          width: "auto",
          left: x,
          top: y + 20,
        }}
      >
        {content}
      </TooltipWrapper>
    </div>
  );
};

const RulesTreeReport = ({rules, response, conflicts}) => {
  const [chartRef, linksRef, circlesRef, chartHdrRef] = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const actions = useContext(ActionsContext);
  const [flatTree, setFlatTree] = useState([]);
  const [rulesTree, setRulesTree] = useState([]);
  const chartHeightInit = rulesTree[0]?.profs?.length
    ? rulesTree[0]?.profs?.length * CONST_TREE_RULE.PX_PER_RULE
    : 250;

  useEffect(() => {
    if (rules.length === 0) {
      return;
    }    
    setFlatTree([]);
    setRulesTree([]);
    const rulesTree = parseRulesTree({rules, conflicts, response});
    setRulesTree(rulesTree);
    const flatTree = buildFlatTree(rulesTree);
    setFlatTree(flatTree);
  }, [rules, conflicts, response]);

  useEffect(() => {
    if (
      chartRef.current === null ||
      linksRef.current === null ||
      circlesRef.current === null ||
      chartHdrRef.current === null ||
      flatTree.length === 0
    ) {
      return;
    }
    const rulesFlowTree = d3.select(chartRef.current);
    const rulesFlowTreeHdr = d3.select(chartHdrRef.current);
    const chartWidth = chartRef.current.clientWidth;
    const mainGroup = rulesFlowTree.select(".mainGroup");
    const root = fromFlatToTree(flatTree);
    let xSpacing = renderSvgTree(
      chartHeightInit,
      root,
      linksRef,
      chartWidth,
      circlesRef,
      rules,
      actions,
    );
    let headerData = getHeaderData(xSpacing, rulesTree);
    renderSvgHeader(rulesFlowTreeHdr, headerData, chartWidth);

    return () => {
      linksRef.current = undefined;
      circlesRef.current = undefined;
    };
  }, [
    flatTree,
    rulesTree[0]?.profs?.length,
    linksRef.current,
    chartRef.current,
    circlesRef.current,
    chartHdrRef.current,
    rules
  ]);

  const handleResize = () => {
    if (chartRef.current) {
      resizeRulesTree(chartRef);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useLayoutEffect(() => {
    handleResize();
  }, []);

  if (rules.length === 0) {
    return (
      <Wrapper>
        <WrapperNoContent>No Data</WrapperNoContent>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <RulesTreeWrapper>
        <svg
          preserveAspectRatio="xMinYMin meet"
          viewBox={`0 0 ${CONST_TREE_RULE.WIDTH} 40`}
          ref={chartHdrRef}
        ></svg>
        <svg
          preserveAspectRatio="xMinYMin meet"
          viewBox={`0 0 ${CONST_TREE_RULE.WIDTH} ${chartHeightInit}`}
          ref={chartRef}
        >
          <g
            className="mainGroup"
            transform={`translate(${[MARGIN.left, MARGIN.top].join(",")})`}
          >
            <g className="links" ref={linksRef} />
            <g className="circles" ref={circlesRef} />
          </g>
        </svg>
        <Tooltip />
      </RulesTreeWrapper>
    </Wrapper>
  );
};

export default RulesTreeReport;


