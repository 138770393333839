import { safeStr, block, cliCommand } from "common/api";
import {
  ensure,
  wrapErrors,
  asAString,
  notEmpty,
  noLongerThan,
} from "./composition";
import { enumerate } from "common/utils";
export { NothingToDo } from "common/api";

const configureBlock = block({
  optional: true,
  start: "configure",
  end: "commit",
  enter: false,
});

export const commandsBlock = block({ enter: true });

const asSuppresionCommand = ({ cliType }, { name }) =>
  `no profile ${cliType} ${safeStr(name)}`;

const asClearCommand = ({ cliType }, { name }) =>
  `clear profile ${cliType} ${safeStr(name)}`;

const PROFILE_LENGTH_MAX = 31;

const validateProfileName = wrapErrors(
  (error) => `Profile name ${error}`,
  ensure(asAString(notEmpty, noLongerThan(PROFILE_LENGTH_MAX)))
);

export const profileSettingsBlockOf = ({ name, type }) =>
  block({
    start: `profile ${type} ${safeStr(name)}`,
    end: "root",
    enter: false,
    optional: true,
  });

const fieldsCommandsMayFail = (profileName) => (fields) => {
  try {
    return fields();
  } catch (fieldsError) {
    throw `Profile "${profileName}" ${fieldsError}`;
  }
};

const asDefinitionCommand = (
  { cliType, type, fields = [] },
  { name, ...current }
) =>
  profileSettingsBlockOf({ name, type: cliType || type })(
    fieldsCommandsMayFail(validateProfileName(name))(() => [
      ...fields
        .filter(notItsName)
        .map((field) => expressPropertyChange(field, current, {})),
    ])
  );

const expressPropertyItemsChange = ({ name, ...terms }, current, previous) =>
  previous !== null &&
  JSON.stringify(current[name]) === JSON.stringify(previous[name])
    ? []
    : composePropertyItemsChange(
        { name, ...terms },
        current[name],
        previous[name]
      );

const mayWrap = (wrap, value) => (wrap === true ? safeStr(value) : value);

const valuesOfItem = (fields = [], item) =>
  fields
    .map(({ name, wrapSafe = false, validate = alwaysOk }) =>
      mayWrap(wrapSafe, validate(item[name]))
    )
    .join(" ");

const asClearItemWith =
  ({ name, cliName, fields = [] }) =>
  (item) =>
    `no ${cliName || name} ${valuesOfItem(fields, item)}`;

const asAssertItemWith =
  ({ name, cliName, fields = [] }) =>
  (item) =>
    `${cliName || name} ${valuesOfItem(fields, item)}`;

const updateExistingItem = (terms, current, previous) => [
  ...(previous === null ? [] : [asClearItemWith(terms)(previous)]),
  asAssertItemWith(terms)(current),
];

const getOriginalItem = ({ __id = undefined }, previous = []) =>
  previous.find(
    (current) => current.__id !== undefined && current.__id === __id
  ) || null;

const validateItems =
  ({ validate = null, canBeEmpty = true, label }) =>
  ({ added, updated, deleted, keeped }) => {
    if (canBeEmpty !== true || validate !== null) {
      const define = [...added, ...updated];
      if (define.length === 0 && keeped.length === 0) {
        if (canBeEmpty !== true) {
          throw `has no ${label}`;
        }
      } else {
        const others = [...keeped, ...define];
        define.forEach((item) => validate(item, others));
      }
    }
    return { added, updated, deleted };
  };

const splitByActions = (items = []) =>
  items.reduce(
    ({ added = [], deleted = [], updated = [], keeped = [] }, current) =>
      markedAsDeletedButStored(current)
        ? { added, updated, keeped, deleted: [...deleted, current] }
        : markedAsNotStoredNotDeleted(current)
        ? { deleted, updated, keeped, added: [...added, current] }
        : markedAsModifiedAndStoredNotDeleted(current)
        ? { added, deleted, keeped, updated: [...updated, current] }
        : { added, deleted, updated, keeped: [...keeped, current] },
    { added: [], deleted: [], updated: [], keeped: [] }
  );

const composePropertyItemsChange = (terms, items, previous = []) => {
  const { added, deleted, updated } = validateItems(terms)(
    splitByActions(items)
  );
  return [
    ...deleted.map(asClearItemWith(terms)),
    ...added.map(asAssertItemWith(terms)),
    ...updated.map((updated) =>
      updateExistingItem(terms, updated, getOriginalItem(updated, previous))
    ),
  ];
};
const getOriginalProfile = ({ name, __id = undefined }, previous = []) =>
  previous.find(
    (current) =>
      (current.__id !== undefined && current.__id === __id) ||
      (current.name !== undefined && current.name === name)
  ) || null;

const declarePropertyValue = (name, value, safeWrap = false) =>
  `${name} ${safeWrap === true ? safeStr(value) : value}`;

const alwaysOk = (value) => value;

const expressPropertyValueChange = (
  { validate = alwaysOk, name, safeWrap },
  current,
  previous
) =>
  current[name] === previous[name]
    ? []
    : declarePropertyValue(name, validate(current[name]), safeWrap);

const expressPropertyChange = ({ type, ...terms }, current, previous) =>
  type === "many"
    ? expressPropertyItemsChange(terms, current, previous)
    : expressPropertyValueChange(terms, current, previous);

const notItsName = ({ name }) => name !== "name";

const asUpdateCommands = (
  { cliType, type, fields = [] },
  { name, ...current },
  previous = {}
) =>
  profileSettingsBlockOf({ name, type: cliType || type })([
    ...fields
      .filter(notItsName)
      .map((field) => expressPropertyChange(field, current, previous)),
  ]);

const expressedAsCommands = (
  fields,
  previous,
  { added = [], deleted = [], updated = [] }
) =>
  configureBlock([
    ...deleted.map((remove) => asSuppresionCommand(fields, remove)),
    ...updated.map((update) =>
      asUpdateCommands(fields, update, getOriginalProfile(update, previous))
    ),
    ...added.map((added) => asDefinitionCommand(fields, added)),
  ]);

const expressedAsCommandsWithClear = (
  composition,
  previous,
  { added = [], deleted = [], updated = [] }
) => [
  ...deleted.map((remove) => asClearCommand(composition, remove)),
  ...configureBlock([
    ...added.map((added) => asDefinitionCommand(composition, added)),
    ...updated.map((update) =>
      asUpdateCommands(
        composition,
        update,
        getOriginalProfile(update, previous)
      )
    ),
  ]),
];

const markedAsDeletedButStored = ({ deleted, stored }) =>
  deleted === true && stored === true;

const markedAsModifiedAndStoredNotDeleted = ({ modified, deleted, stored }) =>
  deleted !== true && stored === true && modified === true;

const markedAsNotStoredNotDeleted = ({ stored, deleted }) =>
  deleted !== true && stored !== true;

const nothingIsStored = (target) =>
  Object.fromEntries(
    Object.entries(target).map(([key, value]) => [
      key,
      key === "stored"
        ? false
        : Array.isArray(value)
        ? value.map(nothingIsStored)
        : typeof value === "object"
        ? nothingIsStored(value)
        : value,
    ])
  );

const mayBeARenameSoDeleteAndCreate = (current, previous) =>
  markedAsModifiedAndStoredNotDeleted(current) &&
  previous !== null &&
  current.name !== previous.name
    ? [
        { ...previous, deleted: true },
        { ...nothingIsStored(current), modified: false, created: true },
      ]
    : null;

const treatRenameAsDeleteAndCreate = (expected = [], previous = []) =>
  expected.flatMap(
    (current) =>
      mayBeARenameSoDeleteAndCreate(
        current,
        getOriginalProfile(current, previous)
      ) || [current]
  );

export const expressApplyCommand = (
  { expectClear = false, ...composition },
  previous,
  expected
) =>
  cliCommand`
    ${commandsBlock(
      expectClear === true
        ? expressedAsCommandsWithClear(
            composition,
            previous,
            splitByActions(treatRenameAsDeleteAndCreate(expected, previous))
          )
        : expressedAsCommands(
            composition,
            previous,
            splitByActions(treatRenameAsDeleteAndCreate(expected, previous))
          )
    ).join("\n")}`(false);

const enumeratedAndStored = (values = []) =>
  enumerate(0, { stored: true })(values);

const savedNestedItems = (source, fields = []) =>
  Object.fromEntries(
    fields
      .filter(({ type }) => type === "many")
      .map(({ name }) => [name, enumeratedAndStored(source[name])])
  );

const asSavedProfile = (fields) => (profile) => ({
  ...profile,
  ...savedNestedItems(profile, fields),
  stored: true,
  modified: false,
});

const squashValuesInto = (record, values, fields) =>
  Object.fromEntries(
    fields.map(({ name, type, fields }, index) => [
      name,
      type !== "many"
        ? values[index]
        : [...(record[name] || []), nestedRecord(fields, values[index])],
    ])
  );

const nestedRecord = (fields, values) =>
  Object.fromEntries(fields.map(({ name }, index) => [name, values[index]]));

const asIts = (input) => input;

const groupValues = (fields, values) =>
  fields.map(({ type, parse = asIts, fields }, index) =>
    type !== "many"
      ? parse(values[index])
      : groupValues(fields, values.slice(index))
  );

const intoARecordNamed = (name, fields) => (record, line) => {
  const values = line.split(/\s+/);
  return name !== values[0]
    ? record /* skip */
    : squashValuesInto(record, groupValues(fields, values), fields);
};

export const loadProfile =
  (name, fields = []) =>
  (response) => {
    const [_head, ...lines] = response.trim("\n").split("\n");
    return asSavedProfile(fields)(
      lines.reduce(intoARecordNamed(name, fields), {})
    );
  };
