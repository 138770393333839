/*globals showModalConfirm, globalNavigate*/
import React, { useContext, useEffect, useState } from "react";
import ActionsContext from "common/ActionsContext";
import DataTable, { Column } from "common/DataTable";
import Request from "common/Request";
import { NothingToDo } from "./api";
import View from "views/Config/Profiles/Common/View";

const doesNothing = () => {};

const useProfiles = (type = "unknown") => {
  const [request, setRequest] = useState(null);
  const doLoad = () => {
    setRequest(null);
    setRequest(
      ifCl.run(`show profile ${type}`).then((response) => ({ response }))
    );
  };
  return [request, doLoad];
};

const invalidColumnType = (type) => {
  throw new Error(`Tnvalid column type: ${type}`);
};

const fieldAsColumn = ({ type, ...settings }, ...args) =>
  type === "text"
    ? [asTextColumn(settings, ...args)]
    : type === "number"
    ? [asNumberColumn(settings, ...args)]
    : type === "many"
    ? asManyColumn(settings, ...args)
    : invalidColumnType;

const asTextColumn = ({ label, idx, name: field }, colIdx) =>
  Column.Text({
    idx: idx || colIdx,
    label: label.toUpperCase(),
    field,
  });

const asNumberColumn = (
  { label, idx, name: field, colClassName = "text-right" },
  colIdx
) =>
  Column.Number({
    idx: idx || colIdx,
    label,
    colClassName,
    field,
  });

const asManyFieldsColumnAt =
  (parentIdx) =>
  ({ name: field, colClassName, label, ...settings }, colIdx) =>
    Column.GroupedText({
      idx: parentIdx + colIdx,
      label: label.toUpperCase(),
      field,
      colClassName,
      orderable: false,
      along: colIdx > 0,
      ...settings,
    });

const asManyColumn = (
  {
    label,
    idx,
    name: field,
    colClassName = "text-right",
    fields = [],
    ...settings
  },
  colIdx
) =>
  fields.length > 1
    ? fields.map(asManyFieldsColumnAt(idx || colIdx))
    : [
        Column.GroupedText({
          idx: idx || colIdx,
          label: label.toUpperCase(),
          field,
          colClassName,
          orderable: false,
          ...settings,
        }),
      ];

const itemsFromValue = (values = [], { name = "row" }) =>
  values.map((value) => ({ [name]: value }));

const valueOfField = ({ type, ...field }, value) =>
  type === "many"
    ? itemsFromValue(value, field.fields[0]) //TODO allow multiple nested fields
    : value;

const fieldAndValueOf = (
  { name, idx = undefined, ...field },
  row,
  fieldIndex
) => [name, valueOfField(field, row[idx !== undefined ? idx : fieldIndex])];

const actionsColumnFor = ({ onEdit, onDelete, defaults = {} }, fields = []) => {
  const rowAsObject = (row) =>
    Object.fromEntries([
      ...Object.entries(defaults),
      ...fields.map((field, idx) => fieldAndValueOf(field, row, idx)),
    ]);

  return Column.Actions({
    label: "ACTIONS",
    idx: 0,
    colClassName: "text-center action align-top",
    cellClassName: "hides-content-to-operators",
    are: [
      {
        id: "edit",
        label: "Edit",
        icon: "edit",
        onClick: (_, row) => onEdit(rowAsObject(row)),
      },
      {
        id: "delete",
        label: "Delete",
        icon: "delete",
        onClick: (_, row) => onDelete(rowAsObject(row)),
      },
    ],
  });
};

const ShowProfiles = ({
  response,
  onEdit = doesNothing,
  onDelete = doesNothing,
  collapsible = null,
  columns = undefined,
  fields = [],
  itemDefaults = { stored: true, pristine: true }, // Received from CLI
}) => (
  <DataTable
    collapsible={collapsible}
    pageLength={50}
    exportAsCSV={true}
    content={response}
    columns={[
      ...(columns || fields.flatMap(fieldAsColumn)),
      actionsColumnFor({ onEdit, onDelete, defaults: itemDefaults }, fields),
    ]}
  />
);

const addClass = (className) => (element) => element.classList.add(className);

const removeClass = (className) => (element) =>
  element.classList.remove(className);

const ExpandCollapeButtons = () => {
  const collapse = (event) => {
    event.target
      .closest(".body")
      .querySelectorAll(".grouping")
      .forEach(addClass("collapsed"));
  };
  const expand = (event) => {
    event.target
      .closest(".body")
      .querySelectorAll(".grouping")
      .forEach(removeClass("collapsed"));
  };
  return (
    <>
      <button
        type="button"
        className="btn btn-default waves-effect shadow-none"
        onClick={expand}
      >
        <i className="material-icons">expand_more</i>
        <span>Expand All</span>
      </button>
      <button
        className="btn btn-default waves-effect shadow-none"
        type="button"
        onClick={collapse}
      >
        <i className="material-icons">expand_less</i>
        <span>Collapse All</span>
      </button>
    </>
  );
};

const CreateProfileButton = ({ onClick }) => (
  <button
    type="button"
    className="btn btn-default waves-effect shadow-none"
    onClick={onClick}
  >
    <i className="material-icons">add_circle</i>
    <span>Add Profile...</span>
  </button>
);

const CANT_PROCEED_ERROR =
  "Cannot proceed while there are pending config changes." +
  " Try again later.";

const clearConfigAttempt = () =>
  ifCl.run("configure\nclear config changes\n", /*is batch*/ true);

const checkNothingPending = () =>
  ifCl.run("show config diff").then((response) => {
    if (response.length > 0) {
      throw new Error(CANT_PROCEED_ERROR);
    }
  });

const notifyAndRevert = (error) => {
  error = error.message === undefined ? new Error(error) : error;
  clearConfigAttempt();
  throw error;
};

class UserDeclined extends Error {}

const nestedEntries = (fields = []) =>
  fields
    .filter(({ type }) => type === "many")
    .map(({ label }) => label)
    .join(", ") || "profile data";

const askForDeleteConfirmation = ({ profile, fields = [] }) => {
  return new Promise((resolve, reject) => {
    showModalConfirm(
      `Delete "${profile.name}" profile?`,
      `This will delete all the ${nestedEntries(fields)}.`,
      "info",
      () => resolve(profile),
      () => reject(new UserDeclined())
    );
  });
};

const ignoreNormalOutcomes = (error) => {
  if (error instanceof UserDeclined || error instanceof NothingToDo) {
    console.log("Process cancelled");
  } else {
    throw error;
  }
};

const submitCommand = (command) => ifCl.run(command, /*is batch*/ true);

export const Profiles = ({
  cliType = "unknown",
  typeLabel = "Unknown",
  viewURL = "Uknown",
  fields = [],
  composeApplyCommand = doesNothing,
  edit = null,
  create = null,
  returnView,
}) => {
  const [error, setError] = useState(null);
  const [request, doLoad] = useProfiles(cliType);

  const clearError = () => setError(null);
  const clearAndDoLoad = () => clearError() || doLoad();

  const apply = (settings, originalSettings = []) => {
    clearError();
    const buildCommand = () => composeApplyCommand(originalSettings, settings);

    return checkNothingPending()
      .then(buildCommand)
      .then(submitCommand)
      .catch(ignoreNormalOutcomes)
      .catch(notifyAndRevert)
      .catch(setError);
  };

  const requestDeletion = (profile) =>
    apply([{ ...profile, deleted: true, stored: true }]);

  const actions = useContext(ActionsContext);
  useEffect(() => {
    if (edit === null && create === null) {
      clearAndDoLoad();
    }
    return actions.recv("do-load", clearAndDoLoad);
  }, [edit, create]);

  const doEditProfile = ({ name }) => globalNavigate(viewURL, { edit: name });

  const doCreateProfile = () => globalNavigate(viewURL, { create: true });

  const doDeleteProfile = (profile) =>
    checkNothingPending()
      .then(() => askForDeleteConfirmation({ profile, fields }))
      .then(requestDeletion)
      .then(doLoad);

  return request === null ? null : (
    <View
      title={`${typeLabel} profiles`}
      applyActionClassName="hidden-to-operators"
      returnView={returnView}
      error={error}
      createAction={doCreateProfile}
    >
      <div className="margin-b-20">
        <CreateProfileButton onClick={doCreateProfile} />
        <ExpandCollapeButtons />
      </div>
      <Request during={request}>
        {({ response }) => (
          <ShowProfiles
            fields={fields}
            response={response}
            onDelete={doDeleteProfile}
            onEdit={doEditProfile}
          />
        )}
      </Request>
    </View>
  );
};
