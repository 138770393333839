import React, { useEffect, useReducer, useContext } from "react";
import { enumerate } from "common/utils";
import styled from "styled-components";
import ActionsContext from "common/ActionsContext";
import ListItems from "./ListItems";
import ModificationsModal from "./Modifications";
import ImportModal from "./Import";

const doesNothing = () => {};

const higherId = (current, { __id }) => (current > __id ? current : __id);
const nextId = (items) => items.reduce(higherId, 0) + 1;

function init({ defaults = {}, from = "unknown" }) {
  const source = defaults[from] || [];
  return {
    changed: false,
    list: enumerate(0, { stored: defaults.stored })([...source]),
  };
}
function contentsReducer({ list }, { type, ...action }) {
  switch (type) {
    case "added": {
      return {
        changed: true,
        list: [
          ...list,
          {
            ...action.item,
            stored: false,
            __id: nextId(list),
          },
        ],
      };
    }
    case "deleted": {
      return {
        changed: true,
        list: list.flatMap(({ __id, stored, ...item }) =>
          __id !== action.id
            ? [{ __id, stored, ...item }]
            : stored === true
            ? [{ __id, stored, ...item, deleted: true }]
            : []
        ),
      };
    }
    case "updated": {
      let found = false;
      return {
        changed: true,
        list: [
          // Update the list
          ...list.map(({ __id, ...item }) =>
            __id !== action.update.__id
              ? { __id, ...item }
              : { __id, ...item, ...action.update, modified: (found = true) }
          ),
          ...(found === true
            ? [
                /*nothing to add*/
              ]
            : [action.update]),
        ],
      };
    }
    case "reset":
      return init(action.payload);
    default: {
      throw Error("Unknown action: " + type);
    }
  }
}

const ActionsLayout = styled.div`
  margin-bottom: 30px;
`;
const Actions = ({ labelSingle = "", onShowCreate = doesNothing }) => (
  <ActionsLayout>
    <button
      type="button"
      onClick={onShowCreate}
      className="btn btn-default waves-effect shadow-none"
    >
      <i className="material-icons">add_circle</i>
      <span>{`Add ${labelSingle}...`}</span>
    </button>
  </ActionsLayout>
);

const HasMany = ({
  label = undefined,
  labelSingle = "",
  className = "",
  items = [],
  fields = [],
  cliName,
  onDelete = doesNothing,
  onCreate = doesNothing,
  onUpdate = doesNothing,
  importable = false,
}) => {
  const actions = useContext(ActionsContext);
  const sendCreate = () => actions.send(`show-create-${cliName}-modal`);
  const itemById = (id) => items.find(({ __id }) => __id === id);
  const sendEdit = (id) =>
    actions.send(`show-edit-${cliName}-modal`, itemById(id));

  return (
    <>
      <Actions
        label={label}
        labelSingle={labelSingle}
        onShowCreate={sendCreate}
      />
      <ListItems
        items={items}
        fields={fields}
        className={className}
        onEdit={sendEdit}
        onDelete={onDelete}
      />
      <ModificationsModal
        cliName={cliName}
        labelSingle={labelSingle}
        fields={fields}
        onUpdate={onUpdate}
        onCreate={onCreate}
      />
      {importable !== true ? null : (
        <ImportModal
          labe={label}
          labelSingle={labelSingle}
          cliName={cliName}
          fields={fields}
          onCreate={onCreate}
          onUpdate={onUpdate}
        />
      )}
    </>
  );
};

const ContentsLayout = styled.div``;

const itsOk = (item) => item;

const ProfileContents = ({
  name: from = "many",
  fields = [],
  defaults = { stored: true },
  onChange = doesNothing,
  validate = itsOk,
  ...props
}) => {
  const payload = { defaults, from };
  const [contents, dispatch] = useReducer(contentsReducer, payload, init);

  const handleDeleteId = (id) => {
    dispatch({
      type: "deleted",
      id,
    });
  };
  const createItem = (item) => {
    dispatch({
      type: "added",
      item: validate(item, contents.list),
    });
  };
  const updateItem = (update) => {
    dispatch({
      type: "updated",
      update: validate(update, contents.list),
    });
  };
  const reset = () => dispatch({ type: "reset", payload });

  useEffect(() => {
    if (contents.changed === true) {
      onChange(null, { name: from, value: contents.list });
    }
  }, [contents]);
  useEffect(() => {
    if (contents.changed === true) {
      reset();
    }
  }, [from, JSON.stringify(defaults)]);
  return (
    <ContentsLayout className="full-width margin-t-10 margin-b-10">
      <HasMany
        items={contents.list}
        fields={fields}
        onDelete={handleDeleteId}
        onCreate={createItem}
        onUpdate={updateItem}
        kind={name}
        {...props}
      />
    </ContentsLayout>
  );
};

export default ProfileContents;
