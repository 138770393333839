import React, { useState } from "react";
import Labeled from "common/Labeled";

const doesNothing = () => {};

export const WEEKDAYS = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const runAllDays = (mask) =>
  WEEKDAYS.filter(
    (_day, index, days) => (mask & Math.pow(2, days.length - index - 1)) > 0
  );

export const bitMaskAsDays = (mask) =>
  runAllDays(parseInt(mask, 16)).join(", ");

const isLabour = (index) => index < 5;

const renderDay = (day, enabled = true, index) =>
  `
    <button
      type="button"
      aria-label="${day}"
      disabled
      class="btn btn-${(enabled && "primary") || "default"} waves-effect ${
    isLabour(index) ? "labour" : "weekend"
  }"
    >
      ${day[0].toUpperCase()}
    </button>
  `;

export const renderRangeDays = (enabled) =>
  `
    <div
      class="btn-toolbar nowrap align-center0"
      role="toolbar"
      aria-label="Range active days"
    >
        <div
          class="btn-group disabled"
          role="group"
        >
          ${WEEKDAYS.map((day, index) =>
            renderDay(day, enabled.includes(day), index)
          ).join("")}
        </div>
    </div>
`;

export const RangeDays = ({
  value = "",
  onChange = doesNothing,
  disabled = true,
}) => {
  const doToggle = (event) => {
    const toggled = event.target.value;
    const selected = event.target.classList.contains("btn-primary") === false;
    const values = value.length === 0 ? [] : value.split(", ");
    const newValues = selected
      ? [...values, toggled]
      : values.filter((current) => current !== toggled);
    if (selected) {
      event.target.classList.replace("btn-default", "btn-primary");
    } else {
      event.target.classList.replace("btn-primary", "btn-default");
    }
    onChange(newValues.join(", "));
  };
  const choices = WEEKDAYS;
  const groups = [
    choices.slice(0, -2), // Labour days
    choices.slice(-2), // Weekend
  ];
  return (
    <div
      className="btn-toolbar nowrap align-center0"
      role="toolbar"
      aria-label="Range active days"
    >
      {groups.map((group, index) => (
        <div
          className={`btn-group nowrap ${disabled ? "disabled" : ""}`}
          role="group"
          key={index}
        >
          {group.map((day) => (
            <button
              value={day}
              key={day}
              alt={day}
              type="button"
              onClick={disabled ? doesNothing : doToggle}
              aria-label={day}
              disabled={disabled}
              className={`btn btn-${
                (value.includes(day) && "primary") || "default"
              } waves-effect`}
            >
              {day[0].toUpperCase()}
            </button>
          ))}
        </div>
      ))}
    </div>
  );
};

export const daysAsBitMask = (days = "") =>
  (typeof days === "string" ? days.split(/,\s*/) : days)
    .reduce(
      (mask, day) =>
        Math.pow(2, WEEKDAYS.length - WEEKDAYS.indexOf(day) - 1) | mask,
      0
    )
    .toString(16)
    .padStart(2, "0");

const InteractveHexRangeDays = ({
  defaultValue = "",
  name,
  readOnly = false,
  ...settings
}) => {
  const [selected, setSelected] = useState(defaultValue);
  return (
    <>
      <input name={name} type="hidden" value={selected} />
      <RangeDays
        value={selected}
        {...settings}
        disabled={readOnly}
        onChange={setSelected}
      />
    </>
  );
};
export const RangeDaysInput = ({ ...settings }) => (
  <Labeled {...settings}>
    <div className="not-dotted-form-line margin-l-10">
      <InteractveHexRangeDays {...settings} />
    </div>
  </Labeled>
);
