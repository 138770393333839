import React, { useRef } from "react";
import NumberInputWithSpinner from "common/NumberInputWithSpinner";
import NumberInput from "common/NumberInput";
import SelectInput from "common/SelectInput";
import ArrangeInColumns from "common/layouts/ArrangeInColumns";
import SwitchInput from "common/SwitchInputWithState";
import TextInput from "common/TextInput";
import PasswordInput from "common/PasswordInput";
import AVPSettings from "./AVPSettings";
import styled from "styled-components";

const getFormData = (form) => {
  const data = new FormData(form);
  return Object.fromEntries(data.entries());
};
const subscriberIDSourceTypes = [
  { label: "Leave unchanged", value: "leave" },
  { label: "Username", value: "user-name" },
  { label: "Calling Station ID", value: "calling-station-id" },
];

const SystemSettingsForm = styled.form`
  .input-group {
    margin-bottom: 0;
  }
  margin-bottom: 20px;
`;

const SystemSettings = ({
  onChange,
  defaults = {},
  state = {},
  local = {},
  setLocal,
}) => {
  const form = useRef(null);
  const onInputChange = ({target}) => {
    const {name, type, value} = target;
    const { autoCongestionManagement = "off", ...settings } = getFormData(
      form.current
    );
    if(name==="subscriberIDSource"){
      onChange({
        ...settings,
        autoCongestionManagement: autoCongestionManagement === "on",
        subscriberIDSource: value,
      });
    } else {
      onChange({
        ...settings,
        autoCongestionManagement: autoCongestionManagement === "on",
      });
    }
  };
  const onAVPSettingsChange = (enabledAVP) => {
    const settings = getFormData(form.current);
    onChange({ ...settings, enabledAVP });
  };
  return (
    <SystemSettingsForm ref={form}>
      <ArrangeInColumns alignToTop={true}>
        <AVPSettings
          state={state.enabledAVP}
          onChange={onAVPSettingsChange}
          radiusMode={state.radiusMode}
          local={local}
          setLocal={setLocal}
        />
        <NumberInputWithSpinner
          name="rateLimitAVPRatePercentage"
          label="Rate-Limit Scaling (%)"
          min={1}
          max={200}
          defaultValue={defaults.rateLimitAVPRatePercentage}
          onChange={onInputChange}
        />
        <SelectInput
          name="subscriberIDSource"
          label="Subscriber-ID source"
          defaultValue={defaults.subscriberIDSource}
          options={subscriberIDSourceTypes}
          onChange={onInputChange}
          className="padding-l-0"
        />
        <SwitchInput
          defaultChecked={defaults.autoCongestionManagement}
          className={"sw-input"}
          name="autoCongestionManagement"
          value={true}
          label="Automatic-Congestion-Management"
          onChange={onInputChange}
        />
        {state.radiusMode !== "proxy" ? null : (
          <>
            <TextInput
              name="radiusServerAddress"
              label="Server IP address"
              defaultValue={defaults.radiusServerAddress}
              onChange={onInputChange}
              className="input-control align-left"
            />
            <PasswordInput
              name="radiusServerSecret"
              label="Server secret"
              defaultValue={defaults.radiusServerSecret}
              onChange={onInputChange}
              className="input-control align-left"
            />
            <NumberInput
              name="radiusDisconnectUDPPort"
              label="Server disconnect port"
              defaultValue={defaults.radiusDisconnectUDPPort}
              onChange={onInputChange}
              className="input-control align-left"
              autocomplete="off"
              min={1}
              max={65535}
              hideArrows={true}
            />
          </>
        )}
      </ArrangeInColumns>
    </SystemSettingsForm>
  );
};

export default SystemSettings;
