import React from "react";
import styled from "styled-components";

const FileInputStyle = styled.div`
  input, label {
    display: inline-block;
    overflow: visible;
    margin: 0 .25em;
  }
  input::-webkit-file-upload-button {
    visibility: hidden;
  }
  input::before {
    content: "${props => props.text}";
    display: inline-block;
    background: linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
  }
  input:hover::before {
    border-color: black;
  }
  input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  }
`
export const loadFile = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = (error) => reject(error);
    reader.onload = () => {
      resolve(event.target.result);
    };
    reader.readAsText(file);
  });

const FileInput = ({ label, name, text="Browse", ...params }) => (
  <FileInputStyle text={text}>
    {label === null ? null : <label>{label}:</label>}
    <input name={name} type="file" {...params} />
  </FileInputStyle>
);
export default FileInput;
