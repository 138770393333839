import React from "react";
import ProfilesAndSettings from "views/Config/Profiles/Common";
import VLANProfilesHelpModal from "./Help";
import VLANProfileSettingsHelpModal from "./SettingsHelp";
import { composeProfileSettings, composeApplyCommand } from "./api";
import vlanComposition from "./composition";

const ConfigVLANProfiles = ({ ...settings }) => (
  <>
    <ProfilesAndSettings
      {...vlanComposition}
      composeApplyCommand={composeApplyCommand}
      composeProfileSettings={composeProfileSettings}
      {...settings}
    />
    <VLANProfilesHelpModal />
    <VLANProfileSettingsHelpModal />
  </>
);

export default ConfigVLANProfiles;
