import React, { useState, useEffect, useContext } from "react";
import Modal from "common/Modal";
import ActionsContext from "common/ActionsContext";

const getConflicts = () => {
  return ifCl.run("show rule error flow");
};

const ConflictsContent = ({ conflicts }) => {
  if (!conflicts || conflicts.length === 0) {
    return <p>No Conflicts Found</p>;
  }
  return (
    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-b-10">
      {conflicts.map((conflict) => {
        return <p>{conflict}</p>;
      })}
    </div>
  );
};

const ConflictsModal = () => {
  const [open, setOpen] = useState(false);
  const [conflicts, setConflicts] = useState();
  const actions = useContext(ActionsContext);

  useEffect(() => {
    return actions.recv("open-conflict-modal", function () {
      setOpen(true);
      getConflicts().then((response) => {
        if (response.length > 0) {
          const lines = response.trim(/\s/).split("\n");
          const conflictsFound = lines.reduce((acc, value) => {
            const conflict = value.split("EALREADY: ")[1];
            acc.push(conflict);
            return acc;
          }, []);
          setConflicts(conflictsFound);
        }
      });
    });
  }, []);

  if (open === false) {
    return null;
  }

  return (
    <Modal
      title="Rule Conflicts"
      large={true}
      content={() => <ConflictsContent conflicts={conflicts} />}
      closeLabel="CANCEL"
      onClose={() => setOpen(false)}
    />
  );
};

export default ConflictsModal;
