import React from "react";
import ProfileAndSettings from "views/Config/Profiles/Common";
import SubscriberGroupProfilesHelpModal from "./Help";
import SubscriberGroupProfileSettingsHelpModal from "./SettingsHelp";
import { composeProfileSettings, composeApplyCommand } from "./api";

import subscriberGroupComposition from "./composition";

const ConfigSubscriberGroupProfiles = ({ ...settings }) => (
  <>
    <ProfileAndSettings
      {...subscriberGroupComposition}
      composeApplyCommand={composeApplyCommand}
      composeProfileSettings={composeProfileSettings}
      {...settings}
    />
    <SubscriberGroupProfilesHelpModal />
    <SubscriberGroupProfileSettingsHelpModal />
  </>
);

export default ConfigSubscriberGroupProfiles;
