/* globals showModalInfo */
import React from "react";
import { composeApplyCommand } from "./api";
import {
  parsePktEngParameters,
  parseStrictSystemSecurityIsActive,
  parseSubscriberIDSource,
  parseHostname,
  parseIPV6Prefix,
  parseUDRRate,
  parseInactivityTimeout,
} from "./api";
import GeneralSettings from "./Settings";
import GeneralSettingsHelpModal from "./Help";
import SettingsView from "common/SettingsView";

const CANT_PROCEED_ERROR =
  "Cannot proceed while there are pending config changes." +
  " Try again later.";

const clear = () =>
  ifCl.run("configure\nclear config changes\n", /*is batch*/ true);

const joinSettings = (settings) =>
  settings.reduce((previous, current) => Object.assign(previous, current), {});

const requestAndParsePktEngParams = () =>
  ifCl.run("show pkteng parameters").then(parsePktEngParameters);

const requestAndParseStrictSystemSecurityActive = () =>
  ifCl.run("show system security").then(parseStrictSystemSecurityIsActive);

const requestAndParseSubcriberIDSource = () =>
  ifCl.run("show api subscriber parameters").then(parseSubscriberIDSource);

const requestAndParseHostname = () =>
  ifCl.run("show system hostname").then(parseHostname);

const requestAndParseUDRRate = () =>
  ifCl.run("show pkteng udr rate").then(parseUDRRate);

const requestAndParseIPV6Prefix = () =>
  ifCl.run("show subscriber ipv6-prefix").then(parseIPV6Prefix);

const requestAndParseInactivityTimeout = () =>
  ifCl.run("show service web parameters").then(parseInactivityTimeout);

const load = () =>
  Promise.all([
    requestAndParsePktEngParams(),
    requestAndParseSubcriberIDSource(),
    requestAndParseInactivityTimeout(),
    requestAndParseStrictSystemSecurityActive(),
    requestAndParseUDRRate(),
    requestAndParseIPV6Prefix(),
    requestAndParseHostname(),
  ]).then(joinSettings);

const mayWarnUserIfHostnameChanged = (previous, { hostname }) =>
  previous.hostname === hostname
    ? (response) => response
    : (response) => {
        if (response.length === 0 || response.includes("%WARN")) {
          showModalInfo(
            "The change in hostname will only be fully effective after a system reboot",
            null
          );
        }
      };

const ConfigGeneralSettings = () => {
  const checkNothingPending = () =>
    ifCl.run("show config diff").then((response) => {
      if (response.length > 0) {
        throw new Error(CANT_PROCEED_ERROR);
      }
    });

  const submitCommand = (command) => ifCl.run(command, /*is batch*/ true);

  const clearConfigAttempt = () =>
    ifCl.run("configure\nclear config changes\n", /*is batch*/ true);

  const notifyAndRevert = (error) => {
    error = error.message === undefined ? new Error(error) : error;
    clearConfigAttempt();
    throw error;
  };

  const apply = (_formSettings, originalSettings = {}, state) => {
    const buildCommand = () => composeApplyCommand(originalSettings, state);

    return checkNothingPending()
      .then(buildCommand)
      .then(submitCommand)
      .then(mayWarnUserIfHostnameChanged(originalSettings, state))
      .catch(notifyAndRevert);
  };

  return (
    <SettingsView
      load={load}
      apply={apply}
      clear={clear}
      title="General Settings"
      applyActionClassName="hidden-to-operators"
      subtitle={
        <small>
          Press <code>Apply</code> button at the end of the page to set the new
          configuration values.
        </small>
      }
    >
      <GeneralSettings />
      <GeneralSettingsHelpModal />
    </SettingsView>
  );
};

export default ConfigGeneralSettings;
