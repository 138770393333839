import React, { useContext } from "react";
import styled from "styled-components";
import Status from "./Status";
import ActionsContext from "common/ActionsContext";
import { requestFactsAndMoment, reconnect } from "./api";
import BillingStatusHelpModal from "./Help";
import BusyWarningModal from "./BusyWarning";
import ConfigBilling from "views/Config/BillingView/component";

const ContextMenu = () => {
  const actions = useContext(ActionsContext);
  return (
    <ul className="context-menu header-dropdown m-r--5">
      <a
        data-loading-effect="pulse"
        data-toggle="cardloading"
        title="Help"
        onClick={() => actions.send("open-help-billing-status")}
      >
        <i className="material-icons">help_outline</i>
      </a>
      <a
        onClick={() => actions.send("do-load-billing-status")}
        data-toggle="cardloading"
        data-loading-effect="pulse"
        title="Refresh"
      >
        <i className="material-icons">refresh</i>
      </a>
    </ul>
  );
};

const HeaderDiv = styled.div`
  & .title {
    text-transform: uppercase;
  }
`;

const BillingStatus = () => {
  const actions = useContext(ActionsContext);
  const mayWarnBusy = () =>
    actions.send("show-busy-warning");
  const doReconnect = () => reconnect(mayWarnBusy);
  return (
    <>
    <div id="viewStatusBilling" className="container-lg">
      <div className="card ">
        <HeaderDiv className="header">
          <h2 className="title">Billing Connector Status</h2>
          <ContextMenu />
        </HeaderDiv>
        <div className="body flex-panel-column padding-20">
          <Status provider={requestFactsAndMoment} reconnect={doReconnect} />
        </div>
      </div>
      <BillingStatusHelpModal />
      <BusyWarningModal />
    </div>
    <ConfigBilling />
    </>

  );
};

export default BillingStatus;
