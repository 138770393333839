import { expressApplyCommand, loadProfile } from "../Common/api";
import timeComposition from "./composition";

export const composeApplyCommand = (previous, expected) =>
  expressApplyCommand(timeComposition, previous, expected);

export const composeProfileSettings = (previous, { pristine, ...expected }) =>
  composeApplyCommand(
    [previous],
    [{ ...expected, modified: pristine !== true }]
  );

export const loadTimeProfile = (name) =>
  loadProfile(name, timeComposition.fields);
