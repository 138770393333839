import {
  ensure,
  wrapErrors,
  asArrayOfStrings,
  notEmpty,
  itsProperty,
} from "../Common/composition";

import {
  bitMaskAsDays,
  daysAsBitMask,
  renderRangeDays,
  RangeDays,
  RangeDaysInput,
} from "./RangeDays";
import TimeInput from "./Time";

const validateRangeDays = wrapErrors(
  (error) => `selected days ${error}`,
  ensure(asArrayOfStrings(notEmpty), daysAsBitMask)
);
const invalid = (error) => {
  throw error;
};

export const toMinutes = (input) =>
  input
    .split(":")
    .reduce(
      (total, part, idx) => total + parseInt(part, 10) * (idx === 0 ? 60 : 1),
      0
    );

const hourOf = (input) => parseInt(input.replace(":.*$", ""), 10);

const validateDifferentHours = ({ start, end, ...rest }) =>
  hourOf(start) === hourOf(end)
    ? invalid(`can't start and end in same hour`)
    : { start, end, ...rest };

const validateRangeDuration = wrapErrors(
  (error) => `duration ${error}`,
  ({ start, end, ...rest }) =>
    toMinutes(start) >= toMinutes(end)
      ? invalid(`can't start after ends`)
      : { start, end, ...rest }
);

const asIts = (result) => result;

const matchsOnAnyDay = (days, otherDays) =>
  days.find((day) => otherDays.includes(day)) !== undefined;

const overlapsTimeSpans = ([start, end], [otherStart, otherEnd]) =>
  Math.max(start, otherStart) - Math.min(end, otherEnd) <= 0;

const anotherRangeMatchingDaysOrSpanOf = (reference) => {
  const [start, end, days] = [
    toMinutes(reference.start),
    toMinutes(reference.end),
    asArrayOfStrings(asIts)(reference.days),
  ];
  return (other) =>
    other.deleted !== true &&
    other !== reference &&
    other.__id !== reference.__id &&
    matchsOnAnyDay(days, asArrayOfStrings(asIts)(other.days)) &&
    overlapsTimeSpans(
      [start, end],
      [toMinutes(other.start), toMinutes(other.end)]
    );
};

const addQuotesAndCommas = (day, index, all) =>
  all.length === 1 || index === 0
    ? `"${day}"`
    : index + 1 === all.length
    ? `and "${day}"`
    : `, "${day}"`;

const quotedAndCommaSeparated = (result) =>
  result.map(addQuotesAndCommas).join("");

const enumerateDays = (input) =>
  asArrayOfStrings(quotedAndCommaSeparated)(input);

const describeRange = ({ start, end, days }) =>
  `"${start}" - "${end}" time range on ${enumerateDays(days)}`;

const wontAllowOverlapping = (target, overlapping) =>
  overlapping === undefined
    ? target
    : invalid(
        `Can't add range ${describeRange(target)} as overlaps ${describeRange(
          overlapping
        )}`
      );

const validateNotOverlaping = (item, list) =>
  wontAllowOverlapping(item, list.find(anotherRangeMatchingDaysOrSpanOf(item)));

const verifyValiedRange = wrapErrors(
  (error) => `Time range ${error}`,
  ensure(
    itsProperty('days', validateRangeDays),
    validateDifferentHours,
    validateRangeDuration,
    validateNotOverlaping,
  )
);

const removesPadding = (input) => input.replace(/^0/, "");

const isAValid24HourFormat = (input) =>
  input.match(/(0?|[12])[0-9]:[012345][0-9]/) === null
    ? invalid("is not a valid 24h hours and minutes")
    : input;

const validateHoursAndMinutesOf = (term) =>
  wrapErrors(
    (error) => `${term} time ${error}`,
    ensure(isAValid24HourFormat, removesPadding)
  );

const timeComposition = {
  cliType: "time",
  typeLabel: "Time",
  viewURL: "viewTimeProfileConfig",
  expectClear: true,
  fields: [
    { name: "name", label: "Profile name", type: "text", canUpdate: true },
    {
      name: "ranges",
      cliName: "range",
      label: "Time ranges",
      labelSingle: "Time range",
      type: "many",
      validate: verifyValiedRange,
      canBeEmpty: false,
      fields: [
        {
          name: "start",
          label: "Start",
          type: "text",
          icon: "access_time",
          InputComponent: TimeInput,
          editionClassName: "one-column",
          defaultValue: "00:00",
          validate: validateHoursAndMinutesOf("Start"),
        },
        {
          name: "end",
          label: "End",
          type: "text",
          icon: "access_time",
          InputComponent: TimeInput,
          editionClassName: "one-column",
          defaultValue: "23:59",
          validate: validateHoursAndMinutesOf("End"),
        },
        {
          name: "days",
          label: "Days",
          type: "days",
          icon: "today",
          parse: bitMaskAsDays,
          renderValue: renderRangeDays,
          Component: RangeDays,
          InputComponent: RangeDaysInput,
          validate: validateRangeDays,
        },
      ],
    },
  ],
};

export default timeComposition;
