/*globals moment, login*/
import React from 'react';
import { isValidIPValue } from "common/api";
import NVPieChartWithLegends from "common/graphs/NVPieChart";
import ColumnsGrid from "common/layouts/ColumnsGrid";
import Modal from "common/Modal";
import Radio from "common/Radio";
import { useState } from "react";
import styled from "styled-components";


const doesNothing = () => {};

const VOLUME_LIMIT = 1000 * 1000 /*TB*/;

const momentArgsBySpan = {
  'oneHour': [1, 'hour'],
  'oneDay': [1, 'day'],
  'oneMonth': [1, 'month'],
  'threeMonths': [3, 'month'],
}
const ChartContainer = styled.div`
  position: absolute;
  top: -20px;

.legends{
  position: absolute;
  top: 55px;
  right: -50px;
  width: 170px;
}
`
const VolumeSelectionDiv = styled.div`
  label[for] {font-size: 12px !important;}
`

const VolumeLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  label {
    margin-bottom: 0;
  }

  .volume-label{
    width: 130px;
    font-family: "AllianceNo2-Regular";
  }

  .volume-value{
    width: 150px;
    text-align: right;
  }
`;

const VolumeOption = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  label[for] {
    font-size: 13px !important;
    white-space: nowrap;
    margin: 0px;
  }
  .only-when-selected {
    max-width: 0px;
    transition: max-width .5s;
    overflow: hidden;
  }
  input[checked] + label + .only-when-selected {
    max-width: 120px;
  }
}
`;

const AskVolumeLimit = ({setNewVolumeLimit=doesNothing, newVolumeLimit}) =>
  <div className={`only-when-selected input-group margin-b-0 `}>
    <div className="form-line">
      <input
        type="text"
        className="form-control align-left"
        autoFocus
        onChange={(e) => setNewVolumeLimit(e.target.value)}
        value={newVolumeLimit}
      />
    </div>
    <span className="input-group-addon">
      <label>GB</label>
    </span>
  </div>

const VolumeOptionItem = ({value, children,...props}) =>
  <VolumeOption {...props} >
    {children}
    { value === 'no'? null : <AskVolumeLimit {...props} />}
  </VolumeOption >

export const EditQuotasModal = ({
    onOk = doesNothing,
    onClose = doesNothing,
    params,
  }) => {
    const isVolumeDefined = params?!isNaN(params.currentVolumeLimit):false;
    const isTimeLimitDefined = !!params?.currentTimeLimit;
    const initTimeLimit = isTimeLimitDefined?new Date(params.currentTimeLimit):'';
    
    const [timeLimit, setTimeLimit] = useState(initTimeLimit);
    const [currentVolumeLimit] = useState(isVolumeDefined?params.currentVolumeLimit:'');
    const [currentVolumeUsed] = useState(isVolumeDefined?params.currentVolumeUsed:'');
    const [newVolumeLimit, setNewVolumeLimit] = useState(isVolumeDefined?0:'');
    const [volumeLimitUpdateChoice, setVolumeLimitUpdateChoice] = useState(isVolumeDefined?'increment':'no');
    const [error, setError] = useState(null);
    const [open, setOpen] = useState(true);
    const [ipAddress, setIpAddress] = useState('');
    const timeDateStr = timeLimit
    ? moment(timeLimit).format("YYYY-MM-DDTHH:mm")
    : "";
    const [date="", time="" ] = timeDateStr?.split('T');
  
    async function getSystemDate(){
      const result = await ifCl.run('show system date');
      return new Date(result);
    }
  
    const doClose = () => onClose();
    if (open === false) {
      return null;
    }
  
    async function doApply(){
      const ipAddressTarget = params?params.ipAddress:ipAddress;
      if(!isValidIPValue(ipAddressTarget)){
        return setError('Invalid IP address');
      }
  
      if(timeLimit && moment(timeLimit).isBefore(moment())){
        return setError('Dates in the past')
      }
  
      if(isNaN(newVolumeLimit)){
        return setError('Invalid volume')
      }
  
      if(volumeLimitUpdateChoice === 'set' && Number(newVolumeLimit) === 0){
        return setError('Volume limit of 0')
      }
  
      if((volumeLimitUpdateChoice === 'set' || volumeLimitUpdateChoice === 'increment') && Number(newVolumeLimit) < 0){
        return setError('Volume limit negative')
      }
  
      if((volumeLimitUpdateChoice === 'set' && Number(newVolumeLimit) > VOLUME_LIMIT)|| (volumeLimitUpdateChoice === 'increment') && Number(newVolumeLimit) + Number(currentVolumeLimit) > VOLUME_LIMIT){
        return setError('Volume limit bigger than 1000 TB')
      }
  
      try{
        if(timeLimit){
          const systemDate = await getSystemDate();
          const diffInSeconds = moment(timeLimit).diff(systemDate, 'seconds')
          if(diffInSeconds > 0){
            await ifCl.run(`api subscriber ${ipAddressTarget} quota time assign ${diffInSeconds}`)
          }
        }else{
          await ifCl.run(`api subscriber ${ipAddressTarget} quota time disabled`)
        }
  
        if (volumeLimitUpdateChoice === "no") {
          await ifCl.run(
            `api subscriber ${ipAddressTarget} quota volume disabled`
          );
        } else if (!isNaN(newVolumeLimit)) {
          const kbytes = Number(newVolumeLimit) * 1000000;
          if (volumeLimitUpdateChoice === "set") {
            await ifCl.run(
              `api subscriber ${ipAddressTarget} quota volume assign ${kbytes}`
            );
          } else if (volumeLimitUpdateChoice === "increment" && kbytes > 0) {
            await ifCl.run(
              `api subscriber ${ipAddressTarget} quota volume increment ${kbytes}`
            );
          }
        }
      }catch(e){
        setError(e)
        return;
      }
  
      onOk();
      doClose();
    };
    const getReference = () => timeLimit
      ? Promise.resolve(timeLimit)
      : getSystemDate();

    const increaseBy = value => date =>
      moment(date).add(...momentArgsBySpan[value]).toDate();

    function onTimeLimitExtensionChanged(value){
      getReference().then(increaseBy(value)).then(setTimeLimit);

    }
  
    const isDark = login.isTheme("dark");
  
    return <Modal
          title="Set New Quota Limits"
          superIcon="add_circle"
          large={true}
          content={() => {
            return (
              <div>
                <div className="row clearfix margin-b-40">
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <div className="form-group margin-b-0">
                      <label>IP Address:</label>
                      <div className="input-group margin-b-0">
                        <span className="input-group-addon">
                          <i className="material-icons">cloud_queue</i>
                        </span>
                        <div className="form-line">
                          <input
                            type="text"
                            className="form-control align-left"
                            required
                            autoFocus
                            onChange={(event) => {
                              setError(null);
                              setIpAddress(event.target.value);
                            }}
                            value={params ? params.ipAddress : ipAddress}
                            readOnly={params !== undefined}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <h4>Time Quota </h4>
  
                <div className="row clearfix margin-b-10">
                  <button
                    type="button"
                    className="btn btn-default waves-effect shadow-none margin-r-15"
                    onClick={() => onTimeLimitExtensionChanged('oneHour')}
                  >
                    <i className="material-icons">add</i>
                    <span>1 hour</span>
                  </button>

                  <button
                    type="button"
                    className="btn btn-default waves-effect shadow-none margin-r-15"
                    onClick={() => onTimeLimitExtensionChanged('oneDay')}
                  >
                    <i className="material-icons">add</i>
                    <span>1 day</span>
                  </button>

                  <button
                    type="button"
                    className="btn btn-default waves-effect shadow-none margin-r-15"
                    onClick={() => onTimeLimitExtensionChanged('oneMonth')}
                  >
                    <i className="material-icons">add</i>
                    <span>1 month</span>
                  </button>

                  <button
                    type="button"
                    className="btn btn-default waves-effect shadow-none margin-r-15"
                    onClick={() => onTimeLimitExtensionChanged('threeMonths')}
                  >
                    <i className="material-icons">add</i>
                    <span>3 months</span>
                  </button>
                  <button
                      className="btn btn-link waves-effect"
                      onClick={() => setTimeLimit("")}
                      type="button"
                    >
                      Remove Time Limit
                    </button>
                </div>
                <div className="row clearfix margin-b-40">
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <div className="form-group margin-b-0">
                      <label>Time Limit:</label>
                      <div className="input-group margin-b-0">
                        <span className="input-group-addon">
                          <i className="material-icons">date_range</i>
                        </span>
                        <div className="form-line">
                          <div className="form-group">
                            <input
                              className="form-control"
                              type="date"
                              autofocus
                              value={date}
                              onChange={(e) => {
                                if (!timeLimit) {
                                  setTimeLimit(
                                    new Date(`${e.target.value}T00:00`)
                                  );
                                } else {
                                  setTimeLimit(
                                    new Date(`${e.target.value}T${time}`)
                                  );
                                }
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <input
                              className="form-control"
                              type="time"
                              autofocus
                              value={time}
                              onChange={(e) => {
                                if (!timeLimit) {
                                  setTimeLimit(
                                    new Date(
                                      `${moment(Date.now()).format(
                                        "YYYY-MM-DD"
                                      )}T${e.target.value}`
                                    )
                                  );
                                } else {
                                  setTimeLimit(
                                    new Date(`${date}T${e.target.value}`)
                                  );
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <h4>Volume Quota </h4>
                <VolumeSelectionDiv className="row clearfix">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <Radio
                      options={[
                        { value: "no", label: "No volume limit", radioClassName: 'radio-col-blue' },
                        { value: "set", label: "Set this new limit", radioClassName: 'radio-col-blue' },
                        { value: "increment", label: "Increment limit by this amount", radioClassName: 'radio-col-blue' },
                      ]}
                      name="action"
                      defaultValue={volumeLimitUpdateChoice}
                      onChange={(e) => setVolumeLimitUpdateChoice(e.target.value)}
                      itemWrapper={VolumeOptionItem}
                      wrapperProps={{setNewVolumeLimit, newVolumeLimit}}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <VolumeLabel className="input-group margin-b-0">
                        <span className="input-group-addon">
                          <i className="material-icons">data_usage</i>
                        </span>
                        <label className="volume-label">Current Volume Limit:</label>
                        <label className="volume-value">{currentVolumeLimit || 'n/a'}</label>
                        <span className="input-group-addon">
                          <label>GB</label>
                        </span>
                      </VolumeLabel>
  
                      <VolumeLabel className="input-group margin-b-0">
                        <span className="input-group-addon">
                          <i className="material-icons">data_usage</i>
                        </span>
                        <label className="volume-label">Current Volume Used:</label>
                        <label className="volume-value">{currentVolumeUsed || 'n/a'}</label>
                        <span className="input-group-addon">
                          <label>GB</label>
                        </span>
                      </VolumeLabel>
                  </div>
                </VolumeSelectionDiv>
  
                {currentVolumeLimit && currentVolumeUsed && <div className="row clearfix">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12"></div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12" style={{height: '170px'}}>
                    <ChartContainer>
                      <NVPieChartWithLegends items={[{ 
                        "label": "Used",
                        "value" : currentVolumeUsed,
                        "color":"#006ab7",
                      } , 
                      { 
                        "label": "Unused",
                        "value" : currentVolumeLimit - currentVolumeUsed,
                        "color": isDark ? "#3A3A3E" : "#F5F5F5"
                      }]} 
                      yTooltipFormat={d => `${d} GB`}/>
                    </ChartContainer>
                </div>
                </div> }
                
              </div>
            );
          }}
          applyLabel="OK"
          onApply={doApply}
          closeLabel="CANCEL"
          onClose={onClose}
          footerMessage={
            (error !== null && (
              <span className="modal-err-msg color-red align-left">{error}</span>
            )) ||
            null
          }
        />
  };
