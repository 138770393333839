import React, { useReducer, useContext, useEffect } from "react";
import ActionsContext from "common/ActionsContext";
import List from "./List";
import Create from "./Create";
import { nextId } from "common/utils";

const doesNothing = () => {};

const reverseEnds = (source) => ({
  ...source,
  acc: source.inet,
  inet: source.acc,
});

function wiresReducer({ list }, { type, ...action }) {
  switch (type) {
    case "create": {
      return {
        changed: true,
        list: [
          ...list,
          {
            ...action.wire,
            stored: false,
            __id: nextId(list),
          },
        ],
      };
    }
    case "reverse": {
      return {
        changed: true,
        list: list.map(({ __id, ...wire }) =>
          __id !== action.id
            ? { __id, ...wire }
            : { __id, ...reverseEnds(wire), updated: true }
        ),
      };
    }
    case "delete": {
      return {
        changed: true,
        list: list.flatMap(({ __id, stored, ...wire }) =>
          __id !== action.id
            ? [{ __id, stored, ...wire }]
            : stored === true
            ? [{ __id, stored, ...wire, deleted: true }]
            : []
        ),
      };
    }
    default: {
      throw Error("Unknown action: " + type);
    }
  }
}
const fieldAction = (field) => (field === "name" ? "details" : field);

const notDeleted = (items) =>
  items.filter(({ deleted = false }) => deleted !== true);

const notInvoledIn =
  (wires) =>
  ({ name }) =>
    wires.find(({ acc, inet }) => acc.iface === name || inet.iface === name) ===
    undefined;

const Wires = ({
  wires: initialWires = [],
  interfaces = [],
  onChange = doesNothing,
}) => {
  const [wires, dispatch] = useReducer(wiresReducer, {
    changed: false,
    list: [...initialWires],
  });
  const actions = useContext(ActionsContext);
  useEffect(() => {
    if (wires.changed === true) {
      onChange(wires.list);
    }
  }, [wires]);

  const doInterfaceClick = (field, params) =>
    actions.send(`show-interface-${fieldAction(field)}`, params);

  const handleDeleteId = (id) => {
    dispatch({
      type: "delete",
      id,
    });
  };
  const handleReverseId = (id) => {
    dispatch({
      type: "reverse",
      id,
    });
  };

  const doVerification = (candidate) => {
    if (candidate.acc.iface === candidate.inet.iface) {
      throw "Invalid wire definition";
    }
    return candidate;
  };

  const createWire = (wire) => {
    doVerification(wire, wires.list);
    dispatch({
      type: "create",
      wire,
    });
  };

  return (
    <>
      <List
        items={wires.list}
        onInterfaceClick={doInterfaceClick}
        doDelete={handleDeleteId}
        doReverse={handleReverseId}
      />
      <Create
        interfaces={interfaces.filter(notInvoledIn(notDeleted(wires.list)))}
        doSave={createWire}
      />
    </>
  );
};

export default Wires;
