import React from "react";

const RadioOption = ({
  name,
  value,
  defaultValue,
  label,
  index,
  ItemWrapper = React.fragment,
  wrapperProps = {},
  radioClassName = 'radio-col-red', 
  onChange,
}) => (
  <ItemWrapper {...{name, value}} {...wrapperProps}>
    <input
      name={name}
      value={value}
      type="radio"
      id={`${name}-${index}`}
      className={`with-gap ${radioClassName}`}
      defaultChecked={value === defaultValue}
      onChange={onChange}
    />
    <label htmlFor={`${name}-${index}`}>{label}</label>
  </ItemWrapper>
);
const doesNothing = () => {};

const Radio = ({
  defaultValue = null,
  name="",
  options = [],
  itemWrapper: ItemWrapper,
  onChange=doesNothing,
  wrapperProps={},
}) => (
  <>
    {options.map((option, index) => (
      <RadioOption
        {...option}
        ItemWrapper={ItemWrapper}
        wrapperProps={wrapperProps}
        name={name}
        index={index}
        defaultValue={defaultValue}
        key={option.value}
        onChange={onChange}
        radioClassName={option.radioClassName}
      />
    ))}
  </>
);

export default Radio;
