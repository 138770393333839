import { expressApplyCommand, loadProfile } from "../Common/api";
import subscriberIdComposition from "./composition";

export const validSubscriberId = (source) => {
  if (source === undefined || source.length < 1) {
    throw "Subscriber ID can't be empty.";
  }
  if (source.length > 64) {
    throw "Subscriber ID must be shorter than 64 characters";
  }
  return source;
};

export const validPriority = (source) => {
  if (source === "none") {
    return "none";
  }
  if (source === undefined) {
    throw "Priority cant be empty.";
  }
  if (typeof source === "string") {
    if (source.length < 1) {
      throw "Priority cant be empty.";
    }
    if (source.includes(".")) {
      throw "Priority must be an integer";
    }
  }
  const parsed = parseInt(source);
  if (isNaN(parsed) || -9999 > parsed || parsed > 9999) {
    throw "Priority must be a number between -9999 and 9999";
  }
  return source;
};

const anySubscriberIdCollisionWith = (__id, subscriberId) => (existing) =>
  existing.subscriberId === subscriberId && existing.__id !== __id;

const validSubscriberIdItems = (item, list) => {
  const { subscriberId, stored, __id } = item;
  if (
    stored !== true &&
    list.find(anySubscriberIdCollisionWith(__id, subscriberId)) !== undefined
  ) {
    throw `Subscriber Id "${subscriberId}" already exists.`;
  }
  return item;
};
export const composeApplyCommand = (previous, expected) =>
  expressApplyCommand(subscriberIdComposition, previous, expected);

export const composeProfileSettings = (previous, { pristine, ...expected }) =>
  composeApplyCommand(
    [previous],
    [{ ...expected, modified: pristine !== true }]
  );
