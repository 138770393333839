const pad2 = (n) => (n < 10 ? "0" : "") + n;
const kilo = Math.pow(1000, 1);
const mega = Math.pow(1000, 2);
const giga = Math.pow(1000, 3);

export const YYYYMMDDHHmmssSSS = (date) =>
  date.getFullYear() +
  pad2(date.getMonth() + 1) +
  pad2(date.getDate()) +
  pad2(date.getHours()) +
  pad2(date.getMinutes()) +
  pad2(date.getSeconds());

export const unitToPrecisionMap = {
  Mbps: 3,
  ms: 3,
  '% retransimissions': 2,
  Flows: 0,
  'Flows-per-minute': 0,
  '% traffic at max speed': 2,
  '% congestion': 2
}

export function threeSignificantDigitsFormat(input, unit){
  if(isNaN(input)){
    return input;
  }

  const value = Number(input);
  const n = 3;
  let reduced = 0;
  let suffix = '';

  if(value < kilo || (value >= kilo && (unit === 'Mbps' || unit === 'ms'))){
    reduced = value;
  }else if(value >= kilo && value < mega){
    reduced = value/kilo;
    suffix = 'K';  
  }else if(value >= mega && value < giga){
    reduced = value/mega
    suffix = 'M';  
  }else if(value >= giga){
    reduced = value/giga;
    suffix = 'G';  
  }

  return reduced.toFixed(n-Math.min(n, reduced.toFixed(0).length)) + suffix;
}

export function toObjectLiteral(input) {
  return Object.fromEntries( input.entries() )
}

export const enumeration =
  (start = 1, extraParams) =>
  (item, index) => ({
    ...item,
    __id: start + index,
    ...extraParams,
  });

export const enumerate =
  (...params) =>
  (list) => list.map(enumeration(...params));

export const higherId = (current, { __id }) => (current > __id ? current : __id);

export const nextId = (items) => items.reduce(higherId, 0) + 1;


export const getFormData = (form) => {
  const data = new FormData(form);
  return Object.fromEntries(data.entries());
};
