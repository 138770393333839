import React, { useState } from "react";
import styled from "styled-components";
import * as d3 from "d3";

const timeFormat = d3.timeFormat("%Y-%m-%d %H:%M:%S");

const CurrentTable = styled.table`
  thead th {
    padding: 2px 10px !important;

    &.sorting {
      padding: 2px 20px 2px 10px !important;
    }
  }

  tbody td {
    padding: 1px 10px 2px !important;
  }

  tbody td.number {
    text-align: right;
    width: 50px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
`;

const Header = styled.h4`
  font-size: 12px !important;
  margin: 0 0 16px;
  text-align: left;
`;

const SubColor = styled.div`
  background-color: ${(props) => props.bgColor};
  width: 12px;
  height: 12px;
  border: 1px solid #999;
  margin-right: 5px;
`;

const SubColorEmpty = styled(SubColor)`
  border: none;
`;

const SubNameWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const SubName = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  width: 170px;
  white-space: nowrap;
  font-family: AllianceNo2-SemiBold !important;
  font-weigth: 100;
`;

const CurrentDate = styled.div`
  font-size: 11px;
  text-align: right;
  margin-top: 4px;
`;

function getSortedFields(
  fields,
  filterFields,
  current,
  { type, direction },
  upDownLink
) {
  const tableFields = [...fields].reduce((acc, field) => {
    const { name } = field;
    if (filterFields.has(name) && name !== "rest") {
      const currentValue = current && (current[name] || parseFloat(current[name]) === 0) ? current[name] : "n/a";
      acc.push({ ...field, current: currentValue });
    }
    return acc;
  }, []);
  const top10 = tableFields.slice(0, 9);
  if (type === "av" && direction === "desc") {
    if (upDownLink === "Uplink") {
      return top10.sort((a, b) => {
        return b.totalUp - a.totalUp;
      });
    } else {
      return top10.sort((a, b) => {
        return b.totalDown - a.totalDown;
      });
    }
  }
  if (type === "av" && direction === "asc") {
    if (upDownLink === "Uplink") {
      return top10.sort((a, b) => {
        return a.totalUp - b.totalUp;
      });
    } else {
      return top10.sort((a, b) => {
        return a.totalDown - b.totalDown;
      });
    }
  }
  if (type === "cur" && direction === "desc") {
    return top10.sort((a, b) => {
      return b.current - a.current;
    });
  }
  if (type === "cur" && direction === "asc") {
    return top10.sort((a, b) => {
      return a.current - b.current;
    });
  }

  return top10;
}

const CurrentDataTable = ({
  fields,
  headerOutput,
  type,
  current,
  filterFields,
  onSeriesInspect,
  seconds,
  total,
}) => {
  const [sorting, setSorting] = useState({ type: "av", direction: "desc" });

  const totalCurrent = current
    ? Object.entries(current).reduce((acc, [key, value]) => {
        if (key !== "time") {
          acc = acc + value;
        }
        return acc;
      }, 0)
    : 0;

  const sortedFields = getSortedFields(
    fields,
    filterFields,
    current,
    sorting,
    type
  );

  if (sortedFields.length === 0) {
    return null;
  }

  const handleClick = (type) => {
    setSorting({
      type,
      direction: sorting.direction === "asc" ? "desc" : "asc",
    });
  };

  return (
    <Wrapper>
      {current ? <Header>Current & Average Speed (Mbps)</Header> : null}
      <CurrentTable className="table dataTable">
        <thead>
          <tr>
            <th>{headerOutput}</th>
            <th
              className={
                sorting.type === "cur"
                  ? `sorting_${sorting.direction}`
                  : "sorting"
              }
              onClick={() => handleClick("cur")}
            >
              CURR.
            </th>
            <th
              className={
                sorting.type === "av"
                  ? `sorting_${sorting.direction}`
                  : "sorting"
              }
              onClick={() => handleClick("av")}
            >
              AVG.
            </th>
          </tr>
        </thead>
        <tbody>
          <tr key="total">
            <td>
              <SubNameWrapper>
                <SubColorEmpty />
                <SubName>Total</SubName>
              </SubNameWrapper>
            </td>
            <td className="number">{d3.format(".3f")(totalCurrent)}</td>
            <td className="number">{d3.format(".3f")(total / seconds)}</td>
          </tr>
          {sortedFields.map(({ name, totalDown, totalUp, current, color }) => {
            return (
              <tr key={name}>
                <td>
                  <SubNameWrapper
                    title={name}
                    onClick={() => onSeriesInspect(name)}
                  >
                    <SubColor bgColor={color}></SubColor>
                    <SubName><a href="#" id="addr" data-toggle="tooltip" data-placement="top" title={name}>{name}</a></SubName>
                  </SubNameWrapper>
                </td>
                <td className="number">{current === "n/a" ? "n/a" : d3.format(".3f")(current)}</td>
                <td className="number">
                  {type === "Downlink"
                    ? d3.format(".3f")(totalDown / seconds)
                    : d3.format(".3f")(totalUp / seconds)}
                </td>
              </tr>
            );
          })}
          
        </tbody>
      </CurrentTable>
      <CurrentDate>{timeFormat(current.time)}</CurrentDate>
    </Wrapper>
  );
};

export default CurrentDataTable;
