import React from "react";
import ProfileAndSettings from "views/Config/Profiles/Common";
import SubscriberIdProfilesHelpModal from "./Help";
import SubscriberIdProfileSettingsHelpModal from "./SettingsHelp";
import { composeProfileSettings, composeApplyCommand } from "./api";

import subscriberIdComposition from "./composition";

const ConfigSubscriberIdProfiles = ({ ...settings }) => (
  <>
    <ProfileAndSettings
      {...subscriberIdComposition}
      composeApplyCommand={composeApplyCommand}
      composeProfileSettings={composeProfileSettings}
      {...settings}
    />
    <SubscriberIdProfilesHelpModal />
    <SubscriberIdProfileSettingsHelpModal />
  </>
);

export default ConfigSubscriberIdProfiles;
