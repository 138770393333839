import React, { useState, useEffect, useContext, useRef } from "react";
import styled from "styled-components";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ActionsContext from "common/ActionsContext";
import SelectInputWithIcon from "common/SelectInputWithIcon";
import { SubscriberFlowPoliciesTable } from "../Policies/component";
import { PROFS } from "../RulesTree/constants";
import RulesTreeReport from "../RulesTree/component";
import RulesTable from "../RulesTable/component";
import { capitalize } from "common/api";

const Wrapper = styled.div`
  width: 100%;
  position: relative;

  .rulesFlowTabs {
    border-bottom: #cccccc59 2px solid;
    padding: 0 20px;

    button {
      font-size: 12px;
      color: #999999;

      &.Mui-selected {
        color: var(--text-primary);
      }
    }
  }
`;

const TabContent = styled.div`
  padding: 20px;
`;

const RulesTreeWrapper = styled.div`
  width: 100%;
  position: relative;
  background: yellow;

  svg {
    background: pink;
  }
`;

const SubMenuWrapper = styled.div`
  margin: 15px 8px 10px 9px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  > div,
  > button {
    margin-bottom: 10px;
  }
`;

const SelectWrapper = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  color: var(--Text-Primary);
  margin: 0 10px;

  .selectProfileFlow {
    min-width: 100px;
    > div {
      margin: 0 5px;
      border: 1px solid #999;
    }
  }
`;

const _profileChoices = PROFS.map((prof) => {
  return { value: prof.attr, label: capitalize(prof.type) };
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <TabContent
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </TabContent>
  );
}

function a11yProps(index) {
  return {
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const SubscriberFlowsTabs = ({rules, response, conflicts, tab, setTab}) => {
  const actions = useContext(ActionsContext);

  const mapAttrInstance = {
    if: profIf,
    vlan: profVlan,
    policiesRate: profPoliciesRate,
    int: profInt,
    // subscriberGroup: profSubscriberGroup,
    subscriberId: profSubscriberId,
    acc: profAcc,
    throu: profThrou,
    dpi: profDpi,
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleProfChange = ({ target }) => {
    const returnView = "viewRulesFlow";
    const attr = target.value;
    if (attr === "vlan") {
      globalNavigate("viewVLANProfileConfig", {
        returnView,
      });
    } else if (attr === "time"){
      globalNavigate("viewTimeProfileConfig", {
        returnView,
      });
    } else if (attr === "subscriberId"){
      globalNavigate("viewSubscriberIDProfileConfig", {
        returnView,
      });
    } else if (attr === "subscriberGroup"){
      globalNavigate("viewSubscriberGroupProfileConfig", {
        returnView,
      });
    } else {
      mapAttrInstance[attr].setReturnView("viewRulesFlow");
      globalNavigate(`viewProf${capitalize(attr)}`);
    }
  };

  return (
    <Wrapper>
      <Tabs
        value={tab}
        onChange={handleTabChange}
        aria-label="Subscriber Flows"
        className="rulesFlowTabs"
      >
        <Tab
          label="RULES TREE-VIEW"
          id="rulesFlowTabTree"
          {...a11yProps(0)}
          disableRipple={true}
        />
        <Tab
          label="RULES TABLE-VIEW"
          id="rulesFlowTabTable"
          {...a11yProps(1)}
          disableRipple={true}
        />
        <Tab
          label="POLICIES"
          id="rulesFlowTabPolicies"
          {...a11yProps(2)}
          disableRipple={true}
        />
      </Tabs>
      <SubMenuWrapper>
        {tab !== 2 ? (
          <button
            type="button"
            className="btn btn-default waves-effect shadow-none hidden-to-operators"
            id="rulesFlowAddRuleBtn3"
            onClick={() => actions.send("open-rule-modal", { type: "Add" })}
          >
            <i className="material-icons">add_circle</i>
            <span>Add Rule...</span>
          </button>
        ) : null}
        <button
          type="button"
          className="btn btn-default waves-effect shadow-none"
          onClick={()=> rulesFlow.data.policies.addPolicy("viewRulesFlow", {tab})}
        >
          <i className="material-icons">add_circle</i>
          <span>Add Policy...</span>
        </button>
        {tab !== 2 ? (
          <button
            type="button"
            className="btn btn-default waves-effect shadow-none"
            id="rulesFlowShowConflictsBtn"
            onClick={() => actions.send("open-conflict-modal")}
          >
            <i className="material-icons">playlist_add_check</i>
            <span>Show Rule Conflicts...</span>
          </button>
        ) : null}
        {tab !== 2 ? (
          <SelectWrapper>
            Manage
            <SelectInputWithIcon
              title="Manage Profiles"
              name="profilesSelect"
              selected={"init"}
              onChange={handleProfChange}
              options={[{ value: "init", label: "" }, ..._profileChoices]}
              className="selectProfileFlow"
            />
            Profiles...
          </SelectWrapper>
        ) : null}
      </SubMenuWrapper>
      <TabPanel value={tab} index={0}>
        <RulesTreeReport
          response={response}
          rules={rules}
          conflicts={conflicts}
        />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <RulesTable rules={rules} conflicts={conflicts} />
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <SubscriberFlowPoliciesTable />
      </TabPanel>
    </Wrapper>
  );
};

export default SubscriberFlowsTabs;
