import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import Form from "./Form";

const doesNothing = () => {};

const StyledDiv = styled.div``;

const MayRequireForm = ({ onApply = null, children }) =>
  typeof onApply === "function" ? (
    <Form onSubmit={onApply}>{children}</Form>
  ) : children ;

const Modal = ({
  title = "not defined",
  subTitle = undefined,
  content = () => null,
  onClose = doesNothing,
  closeLabel = "CLOSE",
  onApply = null,
  applyLabel = "OK",
  applyDisabled = false,
  superIcon = undefined,
  large = false,
  footerMessage = null,
  id = undefined,
}) => {
  const modal = useRef();
  const closeModal = (target = modal.current) => {
    $(target).modal("hide");
    $(target).data("modal", null);
    $(".modal-backdrop").remove();
    $(document.body).removeClass('modal-open')
    $(document.body).css({'padding-right':'0px'})
  };
  useEffect(() => {
    const target = modal.current;
    if (target === null) {
      return undefined;
    }
    $(modal.current).modal("show").on("hidden.bs.modal", onClose);
  }, [modal]);

  useEffect(() => {
    return () => {
      closeModal();
    };
  }, []);
  return (
    <StyledDiv className="modal fade" role="dialog" ref={modal} id={id}>
      <div
        className={`modal-dialog ${large === true ? "modal-lg" : ""}`}
        role="document"
      >
        <MayRequireForm onApply={onApply}>
          <div className="modal-content">
            <div className="modal-header">
              {superIcon ? (
                <div className="text-center">
                  <i className="material-icons font-size-80">{superIcon}</i>
                </div>
              ) : null}
              <h4 className="modal-title">{title}</h4>
              { subTitle === undefined ? null : 
                <p><span className="subtitle" >{subTitle}</span></p>
              }
            </div>
            <div className="modal-body">{content()}</div>
            <div className="modal-footer">
              {footerMessage}
              {onApply === null ? null : (
                <button
                  type="submit"
                  className="btn btn-link waves-effect"
                  disabled={applyDisabled}
                >
                  {applyLabel}
                </button>
              )}
              <button
                type="button"
                className="btn btn-link waves-effect"
                data-dismiss="modal"
              >
                {closeLabel}
              </button>
            </div>
          </div>
        </MayRequireForm>
      </div>
    </StyledDiv>
  );
};
export default Modal;
