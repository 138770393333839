import React, { useState, useContext } from "react";
import ActionsContext from "common/ActionsContext";
import Modal from "common/Modal";

const ConfigProfileTimeHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);
  actions.recv("open-settings-help", function () {
    setOpen(true);
  });
  const doClose = () => setOpen(false);
  if (open !== true) {
    return null;
  }

  return (
    <Modal
      title="Time profile settings"
      onClose={doClose}
      content={() => (
        <React.Fragment>
          <p>
            Time profile: matches when the time of day and the day of week
            match.
          </p>
          <ul>
            <li>
              <b>NAME</b>: Profile name.
            </li>
            <li>
              <b>STARTS</b>: Beginning of the profile validity.
            </li>
            <li>
              <b>ENDS</b>: End of the profile validity.
            </li>
            <li>
              <b>WEEKS DAYS</b>: Days of the week when the validity will apply.
            </li>
          </ul>
          <p>
            Click on the 'Add Time range' button to create a new time range on
            this profile after a name is given. or...
          </p>
          <p>
            Press the <i className="material-icons valign-icon">more_vert</i>{" "}
            icon and then select the <b>Add krange...</b> option to add a new
            range to the profile.
          </p>

          <p>
            Click on the refresh{" "}
            <i className="material-icons valign-icon">refresh</i> icon to
            refresh profiles information. Pending changes, if any, will be lost
          </p>
          <h5> inside the ranges list: </h5>
          <p>
            Click on the <i className="material-icons valign-icon">delete</i> to
            remove the range.
          </p>
          <p>
            Click on the <i className="material-icons valign-icon">edit</i> icon
            to open a modal to edit the profile range.
          </p>
          <p>
            Any changes in the configuration will not be effective until you
            click on the "Apply" button.
          </p>
        </React.Fragment>
      )}
    />
  );
};

export default ConfigProfileTimeHelpModal;
