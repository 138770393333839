import React from "react";
import ProfilesAndSettings from "views/Config/Profiles/Common";
import TimeProfilesHelpModal from "./Help";
import TimeProfileSettingsHelpModal from "./SettingsHelp";
import { composeProfileSettings, composeApplyCommand } from "./api";
import timeComposition from "./composition";

const ConfigTimeProfiles = ({ ...settings }) => (
  <>
    <ProfilesAndSettings
      {...timeComposition}
      composeApplyCommand={composeApplyCommand}
      composeProfileSettings={composeProfileSettings}
      {...settings}
    />
    <TimeProfilesHelpModal />
    <TimeProfileSettingsHelpModal />
  </>
);

export default ConfigTimeProfiles;
