import React, {useContext, useState, useEffect} from "react";
import styled from "styled-components";
import Request from "common/Request";
import DataTable, { Column } from "common/DataTable";
import { parsePoliciesIntoSets } from "../api";
import ActionsContext from "common/ActionsContext";
import { safeStr } from "common/api";
import { createPortal } from 'react-dom';
import { EditQuotasModal } from "./EditQuotaModal";

const TableContainer = styled.div`
  width: 100%;
  overflow: auto;
  padding-top: 26px;
  }
`;

const returnTo = "viewSubscriberQuotas";
const notifyIfPolicyNotFound = (error) => {
  const notFound = error.match(/%ERR-ENOENT: Cannot find "(.*)" policy/);
  if (notFound !== null) {
    const [_add, policyName] = notFound;
    const message = `Assigned policy ${policyName} is not defined. \
    A policy with this name needs to be configured, or the subscriber will be assigned\
    a configured policy according to the existing rules`;
    showModalError("Error:", message);
  }
  throw error;
};

const openPolicy = (name) => {
  const policiesInSets = ifCl.run("show policy").then(parsePoliciesIntoSets);
  policiesInSets.then(({ types, sources }) => {
    views.doKeep(returnTo);
    ifCl
      .run(`show policy ${safeStr(name)}`)
      .catch(notifyIfPolicyNotFound)
      .then((response) =>
        types.rate.has(name)
          ? rulesRate.data.policies.parsePolicy(response)
          : types.flow.has(name)
          ? rulesFlow.data.policies.parsePolicy(response)
          : rulesMonitor.data.policies.parsePolicy(response)
      )
      .then((policy) =>
        sources.static.has(name) !== true
          ? rulesRate.data.policies.showDynPolicy(policy)
          : types.rate.has(name)
          ? rulesRate.data.policies.editPolicy(policy, returnTo)
          : types.flow.has(name)
          ? rulesFlow.data.policies.editPolicy(policy, returnTo)
          : rulesMonitor.data.policies.editPolicy(policy, returnTo)
      );
  });
};

const openSubscriberDetails = (target, row) => {
  views.doKeep(returnTo);
  globalNavigate("viewSubsDetail", {returnView: returnTo, ...target});
};

function filterQuoteIsNotNA(row){
  return row[2]!=='n/a';
}

const ListSubscribers = ({ request}) => {
  const actions = useContext(ActionsContext);
  const reloadWhenDone = () => actions.send("do-load");
  const [showEditQuotasModal, setShowEditQuotasModal] = useState(false);
  useEffect(() => 
    actions.recv('create-quota', () => setShowEditQuotasModal({show: true}))
  , [])
  
  return (
    <>
      <TableContainer>
        <Request during={request}>
          {({ response }) => (
            <DataTable
              pageLength={100}
              exportAsCSV={true}
              content={response}
              filters={[filterQuoteIsNotNA]}
              columns={[
                Column.Text({
                  label: "IP-ADDRESS",
                  idx: 0,
                  cellClassName: "hyperlink-text",
                  title: "Subscriber’s IP address",
                  onClick: (addr, _row) => openSubscriberDetails({ addr }),
                }),
                Column.Text({
                  label: "SUBSCRIBER-ID",
                  idx: 1,
                  cellClassName: "hyperlink-text",
                  title: "Subscriber ID",
                  onClick: (subsId, [_addr, ..._row], _trigger) =>
                    openSubscriberDetails({ subsId }),
                }),
                Column.Text({
                  label: "QUOTA-BLOCK",
                  idx: 11,
                  colClassName: "align-left",
                  parse: (value) =>
                    value === "n/a"
                      ? value
                      : value === "blocked"
                      ? "yes"
                      : "no",
                }),
                Column.DateTimeOrNA({
                  label: "QUOTA-TIME-LIMIT",
                  idx: 12,
                  colClassName: "align-left",
                }),
                Column.Text({
                  label: "QUOTA-GBYTES-USED",
                  idx: 13,
                  colClassName: "align-left",
                  parse: (value) => {
                    if (value === "n/a") return "n/a";
                    return value
                      ? (Number(value.split("/")[0]) / 1000).toFixed(3)
                      : "n/a";
                  },
                }),
                Column.Text({
                  label: "QUOTA-GBYTE-LIMIT",
                  idx: 13,
                  colClassName: "align-left",
                  parse: (value) => {
                    if (value === "n/a") return "n/a";
                    return value
                      ? (Number(value.split("/")[1]) / 1000).toFixed(3)
                      : "n/a";
                  },
                }),
                Column.Actions({
                  label: "ACTIONS",
                  idx: 0,
                  colClassName: "text-center action",
                  cellClassName: "hides-content-to-operators",
                  are: (name) => [
                    {
                      id: "edit-config",
                      label: "Edit",
                      icon: "edit",
                      onClick: (_, row) => {
                        setShowEditQuotasModal({
                          show: true,
                          params: {
                            currentVolumeLimit: row[5],
                            currentVolumeUsed: row[4],
                            ipAddress: row[0],
                            currentTimeLimit: row[3],
                          },
                        });
                      },
                    },
                    {
                      id: "delete",
                      label: "Delete",
                      icon: "delete",
                      onClick: (ipAddress) => {
                        showModalConfirm(
                          `Eliminate time and volume quotas for IP Address ${ipAddress}?`,
                          "",
                          "delete_forever",
                          () => {
                            Promise.all([
                              ifCl.run(
                                `api subscriber ${ipAddress} quota volume disabled`
                              ),
                              ifCl.run(
                                `api subscriber ${ipAddress} quota time disabled`
                              ),
                            ])
                              .then(reloadWhenDone)
                              .catch((error) => showModalError(error));
                          }
                        );
                      },
                    },
                  ],
                }),
              ]}
            />
          )}
        </Request>
      </TableContainer>
      {showEditQuotasModal &&
        showEditQuotasModal.show &&
        createPortal(
          <EditQuotasModal
            onOk={reloadWhenDone}
            onClose={() => setShowEditQuotasModal({ show: false })}
            params={showEditQuotasModal.params}
          />,
          document.body
        )}
    </>
  ); 
}

export default ListSubscribers;
