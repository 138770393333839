/*globals globalNavigate*/
import React, { useState, useEffect, useContext } from "react";
import ActionsContext from "common/ActionsContext";
import SettingsView from "common/SettingsView";
import TextInput from "common/TextInput";
import ArrangeInColumns from "common/layouts/ArrangeInColumns";
import ProfileContents from "./Contents";
import { ImportActionsTriggers } from "./Contents/Import";
import { loadProfile } from "../api";

const doesNothing = () => {};

const ContextMenu = ({ options = null }) => {
  const actions = useContext(ActionsContext);
  return (
    <ul className="context-menu header-dropdown m-r--5">
      <li className="dropdown hidden-to-operators" key="help-menu">
        <a
          data-loading-effect="pulse"
          data-toggle="cardloading"
          title="Help"
          onClick={() => actions.send("open-settings-help")}
        >
          <i className="material-icons">help_outline</i>
        </a>
      </li>
      {options === null ? null : (
        <li className="dropdown hidden-to-operators" key="dropdown-menu">
          <a
            className="dropdown-toggle"
            data-toggle="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
            title="Options"
          >
            <i className="material-icons">more_vert</i>
          </a>
          <ul className="dropdown-menu pull-right hidden-to-operators">
            {options}
          </ul>
        </li>
      )}
    </ul>
  );
};

const optionsForEntities = (
  { cliName, labelSingle, label, importable = false },
  actions
) => (
  <>
    <li>
      <a onClick={() => actions.send(`show-create-${cliName}-modal`)}>
        {`Add ${labelSingle || label}...`}
      </a>
    </li>
    {importable ? (
      <ImportActionsTriggers
        {...{
          cliType: cliName,
          label,
          labelSingle,
          actions,
        }}
      />
    ) : null}
  </>
);

const settingsContextMenu = ({ fields }) => {
  const actions = useContext(ActionsContext);
  const many = fields.filter(({ type }) => type === "many");
  const DefinedContextMenu = () => (
    <ContextMenu
      actions={actions}
      options={
        many.length === 0
          ? null
          : many.map((entity) => optionsForEntities(entity, actions))
      }
    />
  );
  return DefinedContextMenu;
};

const CANT_PROCEED_ERROR =
  "Cannot proceed while there are pending config changes." +
  " Try again later.";

const clearConfigAttempt = () =>
  ifCl.run("configure\nclear config changes\n", /*is batch*/ true);

const TextField = ({
  name,
  label,
  canUpdate = true,
  defaults = {},
  onChange = doesNothing,
}) => (
  <TextInput
    type="text"
    name={name}
    label={label}
    readOnly={canUpdate === false && defaults.stored === true}
    defaultValue={defaults[name]}
    onChange={onChange}
    className="input-control"
    labelClassName="input-group-addon"
  />
);

const NumberField = ({
  name,
  label,
  canUpdate = true,
  defaults = {},
  onChange = doesNothing,
}) => (
  <TextInput
    type="number"
    name={name}
    label={label}
    readOnly={canUpdate === false && defaults.stored === true}
    defaultValue={defaults[name]}
    onChange={onChange}
    className="input-control"
    labelClassName="input-group-addon"
  />
);

const invalidFieldType = (type, { name }) => {
  throw new Error(`Invalid field type "${type}" for field ${name}.`);
};

const Field = ({ type, ...field }) =>
  type === "text" ? (
    <TextField {...field} />
  ) : type === "number" ? (
    <NumberField {...field} />
  ) : type === "many" ? (
    <ProfileContents {...field} />
  ) : (
    invalidFieldType(type, field)
  );

const manyAtTheEnd = (one, another) =>
  (one.type !== "many" && another.type !== "many") ||
  (one.type === "many" && another.type === "many")
    ? 0
    : one.type === "many"
    ? 1
    : another.type === "many"
    ? -1
    : 0;

const ShowProfileSettings = ({
  defaults,
  state,
  onChange = doesNothing,
  fields = [],
}) => (
  <ArrangeInColumns rowGap="0">
    {fields.toSorted(manyAtTheEnd).map(({ name, ...field }) => (
      <Field
        key={name}
        name={name}
        {...field}
        defaults={defaults}
        onChange={onChange}
      />
    ))}
  </ArrangeInColumns>
);

const createNewTarget = (fields = [], settings) =>
  Object.assign(
    Object.fromEntries(
      fields.map(({ name, type, defaultValue = "" }) => [
        name,
        type === "many" ? [] : defaultValue,
      ])
    ),
    settings
  );

const addId = (target) =>
  target === null ? null : { ...target, __id: target.__id || 0 };

const genericLoad = (name, cliType, fields) =>
  ifCl.run(`show profile ${cliType} `).then(loadProfile(name, fields));

const checkNothingPending = () =>
  ifCl.run("show config diff").then((response) => {
    if (response.length > 0) {
      throw new Error(CANT_PROCEED_ERROR);
    }
  });

const notifyAndRevert = (error) => {
  error = error.message === undefined ? new Error(error) : error;
  clearConfigAttempt();
  throw error;
};

const submitCommand = (command) => ifCl.run(command, /*is batch*/ true);

const usePersistence = ({
  create = null,
  edit = null,
  load: loadCLI,
  cliType,
  fields = [],
  composeProfileSettings = doesNothing,
}) => {
  const apply = (settings, originalSettings = {}) => {
    const buildCommand = () =>
      composeProfileSettings(originalSettings, settings);

    return checkNothingPending()
      .then(buildCommand)
      .then(submitCommand)
      .catch(notifyAndRevert);
  };

  const load =
    edit !== null
      ? loadCLI !== undefined
        ? () => loadCLI.then(addId)
        : () => genericLoad(edit, cliType, fields).then(addId)
      : create !== null
      ? () =>
          Promise.resolve(createNewTarget(fields, { stored: false })).then(
            addId
          )
      : () => Promise.resolve(null);

  return [load, apply];
};
const nonce = () => (new Date()).getTime();

export const ProfileSettings = ({
  typeLabel = "UNKNOWN",
  cliType,
  fields = [],
  edit = null,
  create = null,
  viewURL = "Unknown",
  returnView = undefined,
  ...settings
}) => {
  const [target, setTarget] = useState(null);
  const backToList = () => {
    globalNavigate(viewURL);
  };
  const openEdition = (target) =>
    globalNavigate(viewURL, { edit: target.name }, {replace: true});
  const [load, apply] = usePersistence({
    create,
    edit,
    fields,
    cliType,
    ...settings,
  });

  const doLoad = () => load().then(setTarget);

  useEffect(() => {
    doLoad();
  }, [edit, create]);

  const mayEditAfterApply = (target, ...args) =>
    apply(target, ...args).then(create === null ? doLoad : () => openEdition(target));

  const alreadyLoaded = () => Promise.resolve({...target, nonce: nonce()});

  return target === null ? null : (
    <>
      <SettingsView
        load={alreadyLoaded}
        apply={mayEditAfterApply}
        close={returnView === undefined && backToList}
        returnView={returnView || viewURL}
        ContextMenu={settingsContextMenu({ fields })}
        title={`${target.stored ? "Edit" : "Add"} ${typeLabel} Profile`}
        applyActionClassName="hidden-to-operators"
        wrapIntoForm={false}
      >
        <ShowProfileSettings fields={fields} />
      </SettingsView>
    </>
  );
};

export default ProfileSettings;
