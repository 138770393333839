import React, { useState, useContext } from "react";
import ActionsContext from "common/ActionsContext";
import Modal from "common/Modal.jsx";

const SubscriberDetailHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);
  actions.recv("open-help", function () {
    setOpen(true);
  });
  const doClose = () => setOpen(false);
  if (open !== true) {
    return null;
  }

  return (
    <Modal
      title="CONGESTION & ACM"
      onClose={doClose}
      content={() => (
        <>
          This page shows average measurements related to congestion for the
          whole network. Shows current information and statistics for the
          subscriber with the selected IP address.
          <ul>
            <li>
              Click on the refresh{" "}
              <i className="material-icons valign-icon">refresh</i> icon to
              reload page.
            </li>
          </ul>
          <p>Select the period for the statistics (from 1 day to 3 months)</p>
          The graphs show:
          <ul>
            <li>
              <b>
                Average traffic at high speed, under congestion and with ACM:
              </b>{" "}
              The average percent of total downlink traffic that is flowing at a
              speed near the maximum measured speed, the average percent of
              total downlink traffic that is detected as congested (high losses
              or high latency due to high speed) and the average of total
              download traffic that is being limited by the ACM functionality
            </li>
            <li>
              <b>Latency over time:</b> average latency for all users over time.
              This latency is measured from this node towards the subscribers,
              it does not include the latency from this node to the servers in
              Internet.
            </li>
            <li>
              <b>Average TCP Retransmissions Over Time:</b> average TCP
              retransmissions for all users over time. This value is very
              related to the amount of packet losses, and it is measured for
              packets retransmitted from this node towards the users in the
              access side.
            </li>
            <li>
              <b>Average reduction in latency with ACM:</b> The average
              reduction in downlink latency thanks to the the ACM functionality,
              in milliseconds
            </li>
            <li>
              <b>Average reduction in retransmissions with ACM:</b>The average
              reduction in downlink retransmissions thanks to the the ACM
              functionality, in absolute percent (e.g. a reduction from 7% to 5%
              retransmissions will appear as a 2% reduction)
            </li>
          </ul>
        </>
      )}
    />
  );
};
export default SubscriberDetailHelpModal;
