import React, { useState, useContext } from "react";
import Modal from "common/Modal.jsx";
import ActionsContext from "common/ActionsContext";

const ConfigGeneralSettingsHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);
  actions.recv("open-help", function () {
    setOpen(true);
  });
  const doClose = () => setOpen(false);
  if (open !== true) {
    return null;
  }

  return (
    <Modal
      title="BILLING SYSTEMS INTEGRATION"
      onClose={doClose}
      content={() => (
        <>
          <p>
            <strong>Hostname:</strong> change the system hostname. A reboot is
            needed to make it effective.
          </p>
          <p>
            <strong>IPv6 prefix:</strong> to change the default IPv6 prefix
            length, which the BQN will consider a subscriber.
          </p>
          <p>
            <strong>UDR rate:</strong> to change the default rate at which UDR
            records are produced (in records per minute). Note that large values
            may affect performance.
          </p>
          <p>
            <strong>Default subscriber-ID source:</strong> select what will be
            the subscriber ID field by default, either the access IP address or
            the access-side MAC address of the first packet in the subscriber
            session. This value may later be replaced with a subscriber ID
            coming from RADIUS, the REST API or an integrated billing system.
          </p>
          <p>
            <strong>GUI inactivity timeout (seconds):</strong> select what will
            be the inactivity timeout. The user will be automatically logged out
            and redirected to the login page when the timeout expires. Note that
            your current session will not be affected by this change until the
            page is reloaded. If no timeout is required, leave field as blank.
          </p>
          <p>
            <strong>Hide DPI per subscriber for operator profiles:</strong> this
            determines whether users with operator profile will be able to see
            DPI results per subscriber (they will still be able to see the
            system-wide DPI results)
          </p>
          <p>
            <strong>Hide config and policy rates for operators:</strong> when
            enabled (switched on) users with operator profile will not be able
            to see the Configuration section and the rate values of flow and
            rate policies.
          </p>
          <p>
            <strong>Strict password and login security:</strong> when enabled,
            new passwords must have at least 8 characters, not very simplistic,
            have at least a digit, a capital letter, a lower case letter, and a
            special character. Additionally, after 5 failed login attemps, the
            user will be locked for 5 minutes (except for root, to avoid
            denial-of-service attacks).
          </p>
          <p>
            <strong>Steer traffic per flow:</strong> enabling steering per flow
            will improve traffic load balancing across CPU cores but will only
            support TCPO and speed limits per flow. Per-subscriber policies,
            including flow limits per subscriber, will not be possible, as those
            subscriber limits will be applied for each CPU core invidually.
          </p>
          <p>
            Click on the <i className="material-icons valign-icon">refresh</i>{" "}
            icon to reload the current parameters.
          </p>
          <p>
            Change the parameters and the click Apply to make those changes
            effective.
          </p>
        </>
      )}
    />
  );
};

export default ConfigGeneralSettingsHelpModal;
