export const ensure = (term, ...terms) =>
  terms.length > 0
    ? (input, ...args) =>
        [term, ...terms].reduce((input, term) => term(input, ...args), input)
    : term;

export const asAString =
  (...terms) =>
  (input) =>
    ensure(...terms)((input !== undefined && String(input)) || undefined);

export const asArrayOfStrings =
  (...terms) =>
  (input) =>
    ensure(...terms)(input.length === 0 ? [] : input.split(/,\s*/));

export const notEmpty = (input) => {
  if (input === undefined || input.length === 0) {
    throw `can't be empty`;
  }
  return input;
};

export const noLongerThan = (max) => (input) => {
  if (input.length > max) {
    throw `can't be longer than ${max} characters`;
  }
  return input;
};

const toInteger = (input) => {
  if (typeof input === "number") {
    return input;
  }
  if (input.includes(".")) {
    throw "must be an integer";
  }
  return Number(input);
};

export const asAInteger =
  (...terms) =>
  (input) =>
    ensure(...terms)(input !== undefined && toInteger(input));

export const equalTo = (target) => (input) => {
  if (target !== input) {
    throw `has to be "${target}"`;
  }
  return input;
};

export const beBetween = (min, max) => (value) => {
  if (min <= value && value <= max) {
    return value;
  }
  throw `must be a number between ${min} and ${max}`;
};

const tryForWorkingTermWith =
  (input) =>
  ({ failures = [], result }, term) => {
    if (result !== undefined) {
      return { result };
    }
    try {
      return { result: term(input) };
    } catch (failed) {
      return { failures: [...failures, failed] };
    }
  };

export const oneOf =
  (...terms) =>
  (input) => {
    const { result, failures = [] } = terms.reduce(
      tryForWorkingTermWith(input),
      { failures: [] }
    );
    if (result === undefined && failures.length > 0) {
      throw failures.join(" or ");
    }
    return result;
  };

const unCapitalize = (input) =>
  input.slice(0, 1).toLowerCase() + input.slice(1);

export const wrapErrors =
  (wrapError = (error) => error, payload) =>
  (...args) => {
    try {
      return payload(...args);
    } catch (error) {
      throw wrapError(unCapitalize(error));
    }
  };

const invalid = (reason) => {
  throw reason;
};

const timesFound = (char, input) =>
  input.length - input.replaceAll(char, "").length;

export const maxCharacterRepetitions =
  (char, max = 1) =>
  (input) =>
    timesFound(char, input) > max
      ? invalid(`can't have more than ${max} occurences of ${char}`)
      : input;

export const maxWildcards = wrapErrors(
  () => "can't have more than two wildcards *",
  maxCharacterRepetitions("*", 2)
);

export const itsProperty =
  (field, ...terms) =>
  (target, ...args) => {
    ensure(...terms)(target[field], ...args);
    return target;
  };
