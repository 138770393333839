import React, { useEffect, useState, useContext } from "react";
import FileInput, { loadFile } from "common/FileInput";
import ActionsContext from "common/ActionsContext";
import Modal from "common/Modal.jsx";
import { enumeration } from "common/utils";

const importMethods = ["merge", "replace"];

const DefaultWrapper = ({ children, ...props }) => (
  <li {...props}>{children}</li>
);

export const ImportActionsTriggers = ({
  Wrapper = DefaultWrapper,
  actions,
  cliType,
  labelSingle,
  label,
}) => (
  <>
    {importMethods.map((method) => (
      <Wrapper key={method}>
        <a onClick={() => actions.send(`show-import-${cliType}`, { method })}>
          <span className="text-capitalize">{method}</span>
          {` ${label || labelSingle} from file...`}
        </a>
      </Wrapper>
    ))}
  </>
);
const doesNothing = () => {};

const intoItemOfFields = (fields) => (row) => {
  const values = fields.length > 1 ? row.split(/\s+/) : [row.trim("\r")];
  return Object.fromEntries(
    fields.map(({ name }, index) => [name, values[index]])
  );
};
const parseIntoItemsOfFields = (fields) => (input) => {
  const lines = input.trim("\n").split("\n");
  return lines.map(intoItemOfFields(fields));
};

const tryToSubmit =
  (enumeration, dispatch) =>
  ({ done, failed }, item, line) => {
    try {
      dispatch(enumeration(item, line));
      return { done: done + 1, failed };
    } catch (error) {
      return { done, failed: [...failed, { line, error }] };
    }
  };

const provideItems = (items, enumeration, dispatch) =>
  items.reduce(tryToSubmit(enumeration, dispatch), { done: 0, failed: [] });

const ReportFailures = ({ failed = [] }) =>
  failed.length === 0 ? null : (
    <div className="padding-t-10 align-left color-red">
      <p>Some errors found:</p>
      <ul>
        {failed.map(({ line, error }) => (
          <li key={line} className="align-left">
            Error at line {line + 1}: {String(error)}.
          </li>
        ))}
      </ul>
    </div>
  );

const ReportResult = ({ done = 0, failed = [], label = "Items" }) => (
  <div className="padding-t-20">
    <span>
      Loaded {done} {label}.
    </span>
    <ReportFailures failed={failed} />
  </div>
);

const SourceFileInput = ({ name, onLoad = doesNothing }) => {
  const handleSourceChange = (event) => {
    const [source] = event.target.files;
    onLoad(loadFile(source));
  };
  return (
    <FileInput
      text="Browse..."
      label={null}
      name={name}
      onChange={handleSourceChange}
    />
  );
};

const ImportModal = ({
  cliName = "",
  label = undefined,
  labelSingle = "",
  fields = [],
  onCreate = doesNothing,
  onUpdate = doesNothing,
  cliType,
}) => {
  const [open, setOpen] = useState(false);
  const [method, setMethod] = useState(null);
  const [error, setError] = useState(null);
  const [result, setResult] = useState(null);
  const clear = () => {
    setError(null);
    setResult(null);
  };
  const actions = useContext(ActionsContext);
  const doOpen = () => {
    setOpen(true);
    clear();
  };
  const doClose = () => {
    setOpen(false);
  };
  useEffect(
    () =>
      actions.recv(`show-import-${cliType}`, function ({ method }) {
        setMethod(method);
        doOpen();
      }),
    [cliName]
  );
  if (open !== true) {
    return null;
  }
  const importItems = (items) =>
    provideItems(
      items,
      method === "replace"
        ? enumeration(0, { modified: true })
        : (item) => item,
      method === "replace" ? onUpdate : onCreate
    );

  const handleLoad = (load) => {
    load
      .then(parseIntoItemsOfFields(fields))
      .then(importItems)
      .then(setResult)
      .catch((error) => setError(String(error)));
  };
  return (
    <Modal
      title={`${
        method === "replace" ? "Replace" : "Merge"
      } ${labelSingle} from .txt file:`}
      superIcon={method ? "add_circle" : "add_circle"}
      onClose={doClose}
      closeLabel={result === null ? "CLOSE" : "OK"}
      content={() => (
        <>
          <SourceFileInput name="source" onLoad={handleLoad} />
          {result === null ? null : (
            <ReportResult label={label || labelSingle} {...result} />
          )}
        </>
      )}
      footerMessage={
        (error !== null && (
          <span className="modal-err-msg color-red align-left">{error}</span>
        )) ||
        null
      }
    />
  );
};

export default ImportModal;
