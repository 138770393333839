/* globals showModalConfirm */
import React from "react";
import SwitchInput from "common/SwitchInput";
import TextInput from "common/TextInput";
import NumberInput from "common/NumberInputWithSpinner";
import SelectInput from "common/SelectInput";
import ArrangeInColumns from "common/layouts/ArrangeInColumns";

const subscriberIDSourceOptions = [
  { label: "MAC", value: "mac" },
  { label: "IP-ADDRESS", value: "ip-address" },
];

const IPV6PrefixInput = ({
  name = "ipV6Prefix",
  onChange,
  defaults = {},
  className,
}) => (
  <NumberInput
    min={1}
    max={128}
    defaultValue={defaults[name] || ""}
    className={`input-group ${className ?? ""}`}
    name={name}
    label="IPv6 prefix for subscribers:"
    onChange={onChange}
  />
);

const UDRProductionRateInput = ({
  name = "udrRate",
  onChange,
  defaults = {},
  className,
}) => (
  <NumberInput
    min={0}
    max={9999}
    defaultValue={defaults[name]}
    className={`input-group ${className ?? ""}`}
    name={name}
    label="UDR production rate"
    onChange={onChange}
  />
);

const GUIInactivityTimeoutInput = ({
  name = "guiInactivityTimeout",
  onChange,
  defaults = {},
  className,
}) => (
  <NumberInput
    min={1}
    max={9999}
    defaultValue={defaults[name] || ""}
    className={`input-group ${className ?? ""}`}
    name={name}
    label="GUI inactivity timeout (seconds)"
    onChange={onChange}
    backspaceValue={""}
    allowsEmpty={true}
  />
);

const revertEnabled = (event) => (event.target.checked = false);

const SteeringPerFlowInput = ({ onChange, defaults = {}, className = "" }) => (
  <SwitchInput
    name="steeringFlow"
    className={`input-group ${className}`}
    defaultChecked={defaults.steeringFlow}
    onChange={(event) =>
      confirmBeforeEnabling(event).then(onChange).catch(revertEnabled)
    }
    label="Steer traffic per flow"
  />
);

const confirmBeforeEnabling = (event) =>
  event.target.checked !== true
    ? Promise.resolve(event)
    : new Promise((resolve, reject) => {
        showModalConfirm(
          "",
          "Enabling steering per flow will improve traffic load balancing across CPU cores " +
            "but will only support TCPO and speed limits per flow. Per-subscriber policies, " +
            "including flow limits per subscriber, will not be possible, as those subscriber " +
            "limits will be applied for each CPU core invidually.",
          "info",
          () => resolve(event),
          () => reject(event),
          "OK",
          "CANCEL"
        );
      });

const GeneralSettings = ({ onChange, defaults = {}, state = {} }) => (
  <ArrangeInColumns columns={2} rowGap={0}>
    <TextInput
      name="hostname"
      className="input-group"
      onChange={onChange}
      title="Hostname"
      defaultValue={defaults.hostname}
      label="Hostname:"
    />
    <div></div>
    <IPV6PrefixInput
      onChange={onChange}
      defaults={defaults}
      state={state}
      className="margin-b-20"
    />
    <SelectInput
      name="subscriberIDSource"
      className="input-group"
      onChange={onChange}
      title="Default Subs.ID Source"
      defaultValue={defaults.subscriberIDSource || "mac"}
      options={subscriberIDSourceOptions}
      label="Default subscriber-ID source:"
    />
    <UDRProductionRateInput
      onChange={onChange}
      defaults={defaults}
      state={state}
      className="margin-b-20"
    />
    <GUIInactivityTimeoutInput
      onChange={onChange}
      defaults={defaults}
      state={state}
      className="margin-b-20"
    />
    <SwitchInput
      name="hideDPIToOperators"
      className="input-group "
      defaultChecked={defaults.hideDPIToOperators}
      onChange={onChange}
      label="Hide DPI per subscriber for operator profiles"
    />
    <SwitchInput
      name="hidePolicyRatesToOperators"
      className="input-group "
      defaultChecked={defaults.hidePolicyRatesToOperators}
      onChange={onChange}
      label="Hide config and policy rates for operators"
    />
    <SwitchInput
      name="strictSystemSecurityActive"
      className="input-group "
      defaultChecked={defaults.strictSystemSecurityActive}
      onChange={onChange}
      label="Strict password and login security"
    />
    <SteeringPerFlowInput defaults={defaults} onChange={onChange} />
  </ArrangeInColumns>
);

export default GeneralSettings;
