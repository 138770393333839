/* global login */
import React, { useState } from "react";
import styled from "styled-components";
import colors from "./colors";
import colorsDark from "./colorsDark";

const doesNothing = () => {};
const LegendStyle = styled.span`
  margin: 0 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  .sample {
    background-color: ${(props) =>
      props.enabled ? props.color : "transparent"};
    border: solid 1px ${(props) => props.color};
    border-radius: 15px;
    display: inline-block;
    width: 12.5px;
    height: 12.5px;
  }
  .legend {
    margin-left: 0.25em;
    line-height: 1.1em;
    vertical-align: middle;
    font-family: "AllianceNo2-Regular";
    font-size: 12px;
    color: var(--chart-text-color);
    fill: var(--chart-text-color);
  }
  .clickable, .clickable:hover {
    curson: pointer;
    text-decoration: none;
  }
  &.fixed-width {
    .legend {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      ${props  => props.width === undefined ? "" :
        `width: ${props.width};`
      }
    } 
  }
`;

const preventingDefaultEvent =
  (action = doesNothing) =>
  (event, item) => {
    event.preventDefault();
    action(item);
  };

const Legend = ({
  field,
  color,
  label,
  disabled = false,
  doToggle = doesNothing,
  onLabelClick = undefined,
  onLabelInspect = doesNothing,
  width = undefined,
}) => (
  <LegendStyle
    color={color}
    width={width}
    enabled={!disabled}
    className={`${disabled ? "disabled" : "enabled"} ${width ? "fixed-width" : ""}`}
  >
    <a
      className="clickable sample"
      onClick={() => doToggle(field, disabled)}
      onDoubleClick={() => doToggle(field, disabled, /*only this*/ true)}
    />
    <a
      className="clickable legend"
      onClick={() => doToggle(field, disabled)}
      onDoubleClick={() => doToggle(field, disabled, /*only this*/ true)}
      onContextMenu={preventingDefaultEvent(() =>
        onLabelInspect({
          field,
          name: field,
          key: field,
          label,
        })
      )}
      data-toggle="tooltip" data-placement="top" title={label || field}
    >
      {label || field}
    </a>
  </LegendStyle>
);

export const LegendsAndGraph = styled.div`
  position: relative;
  width: 100%;
  margin: 15px 0;
  display: flex;
  flex-flow: column nowrap;
  .legends {
    flex: 1em 1 0;
  }
  .legends + div {
    flex: 100% 1 1;
  }
`;

const LegendsListWrapper = styled.div`
  ul {
    list-style-type: none;
    line-height: 1.1em;
    vertical-align: middle;
    li {
      display: inline-block;
      white-space: nowrap;
      line-height: 1.1em;
      vertical-align: middle;
      margin: 0.15em 0.25em 0.15em 0.25em;
    }
  }
`;
export const LegendsList = ({
  items = [],
  prepend = [],
  doToggle = doesNothing,
  labelWidth = "15ch",
  ...rest
}) => {
  return (
    <LegendsListWrapper className="legends">
      <ul>
        {prepend.map((item) => (
          <li key={item.key}>
            <Legend {...item} width={labelWidth} />
          </li>
        ))}
        {items.map((item) => (
          <li key={item.key}>
            <Legend {...item} field={item.key} doToggle={doToggle} width={labelWidth} {...rest} />
          </li>
        ))}
      </ul>
    </LegendsListWrapper>
  );
};

const enableAll = (items) =>
  items.map((item) => ({ ...item, disabled: false }));

const reenableIfAllDisabled = (result, previous) =>
  result.some(({ disabled }) => disabled !== true)
    ? result
    : enableAll(previous);

const keyFromName = ({ name }) => name;

const toggleLegend = (key, wasDisabled) => (previous) =>
  reenableIfAllDisabled(
    previous.map((legend) =>
      legend.key === key ? { ...legend, disabled: !wasDisabled } : legend
    ),
    previous
  );

const allWereEnabled = (previous) =>
  previous.some(({ disabled }) => disabled === true) !== true;

const toggleTarget = (legend, previous) =>
  allWereEnabled(previous)
    ? /*enable this*/ false
    : /*toggle target*/ legend.disabled === false; //if all disabled will be enabled.

const disableAllExcept = (key) => (previous) =>
  reenableIfAllDisabled(
    previous.map((legend) => ({
      ...legend,
      disabled: legend.key !== key ? true : toggleTarget(legend, previous),
    })),
    previous
  );

export const useSelection = (source, keyFrom = keyFromName) => {
  const palette = login.isTheme("light") ? colors : colorsDark;
  const [selection, setSelection] = useState(
    source.map((field, index) => ({
      key: field.key || keyFrom(field) || index,
      disabled: false,
      color: field.color || palette[index] || palette[index % palette.length],
      ...field,
    }))
  );
  const doToggle = (key, wasDisabled = true, onlyThis = false) =>
    setSelection(
      onlyThis ? disableAllExcept(key) : toggleLegend(key, wasDisabled)
    );
  return [selection, doToggle];
};

export default LegendsList;
