import React, { useState, useEffect } from "react";
import SpinnerInput from "common/SpinnerInput";
import styled from "styled-components";
import useFirstTime from "common/hooks/firstTime";
import Labeled from "common/Labeled";

const doesNothing = () => {};

const splitHourMinute = (input) => {
  const [hour, minute] = input.split(":");
  return { hour, minute };
};

const TimeInputWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  width: 20ch !important;
  input {
    min-width: 0;
  }
  .separator {
    width: 2ch;
    text-align: center;
  }
  .spinner {
    flex: initial;
  }
`;

const format = ({ hour, minute }) =>
  `${String(hour).padStart(2, "0")}:${String(minute).padStart(2, "0")}`;

export const TimeInput = ({
  name,
  defaultValue = "00:00",
  onChange = doesNothing,
}) => {
  const [time, setTime] = useState(splitHourMinute(defaultValue));
  const isFirstTime = useFirstTime();
  const updateField = (field) => (source) => {
    const value = source.target === undefined ? source : source.target.value;
    setTime({ ...time, [field]: parseInt(value, 10) });
  };
  useEffect(() => {
    if (isFirstTime()) {
      return;
    }
    onChange(format(time));
  }, [time]);
  return (
    <TimeInputWrapper className="form-line inline hour-and-minute nowrap">
      <input type="hidden" value={format(time)} name={name} />
      <SpinnerInput
        defaultValue={time.hour}
        onChange={updateField("hour")}
        size={2}
        min={0}
        max={23}
        type="number"
        placeHolder="hours (00-23)"
      />
      <span className="separator">:</span>
      <SpinnerInput
        defaultValue={time.minute}
        onChange={updateField("minute")}
        size={2}
        min={0}
        max={59}
        type="number"
        placeHolder="minutes (00-59)"
      />
    </TimeInputWrapper>
  );
};

export const LabeledTimeInput = ({ ...settings }) => (
  <Labeled {...settings}>
    <div className="margin-l-10">
      <TimeInput {...settings} />
    </div>
  </Labeled>
);
export default LabeledTimeInput;
