import { expressApplyCommand, loadProfile } from "../Common/api";
import vlanComposition from "./composition";

export const composeApplyCommand = (previous, expected) =>
  expressApplyCommand(vlanComposition, previous, expected);

export const composeProfileSettings = (previous, { pristine, ...expected }) =>
  composeApplyCommand(
    [previous],
    [{ ...expected, modified: pristine !== true }]
  );

export const loadVLANProfile = (name) =>
  loadProfile(name, vlanComposition.fields);
