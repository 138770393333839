import {
  ensure,
  wrapErrors,
  asAString,
  asAInteger,
  beBetween,
  noLongerThan,
  notEmpty,
  maxWildcards,
} from "../Common/composition";

const validatePriority = wrapErrors(
  (error) => `Priority ${error}`,
  ensure(asAInteger(beBetween(-9999, 9999)))
);

const anySubscriberGroupCollisionWith = (__id, subscriberGroup) => (existing) =>
  existing.subscriberGroup === subscriberGroup && existing.__id !== __id;

const SUBSCRIBER_GROUP_MAX_LENGTH = 63;

const validateSubscriberGroup = wrapErrors(
  (error) => `Subscriber Group ${error}`,
  ensure(
    asAString(
      notEmpty,
      noLongerThan(SUBSCRIBER_GROUP_MAX_LENGTH),
      maxWildcards
    )
  )
);

const validSubscriberGroupItems = (item, list) => {
  const { subscriberGroup, stored, __id } = item;
  validateSubscriberGroup(subscriberGroup);
  if (
    stored !== true &&
    list.find(anySubscriberGroupCollisionWith(__id, subscriberGroup)) !== undefined
  ) {
    throw `Subscriber Group "${subscriberGroup}" already present.`;
  }
  return item;
};

const subscriberGroupComposition = {
  cliType: "subscriber-group",
  typeLabel: "Subscriber Group",
  viewURL: "viewSubscriberGroupProfileConfig",
  fields: [
    { name: "name", label: "Profile name", type: "text", canUpdate: false },
    {
      name: "subscriberGroups",
      cliName: "group",
      label: "Subscriber Groups",
      labelSingle: "Subscriber Group",
      importable: true,
      type: "many",
      validate: validSubscriberGroupItems,
      canBeEmpty: false,
      fields: [
        {
          name: "subscriberGroup",
          label: "Subscriber Group",
          type: "text",
          wrapSafe: true,
          validate: validateSubscriberGroup,
          clarify: "Up to two wildcards '*' are allowed. Any spaces inside the subscriber-group will be replaced by the '_' character."
        },
      ],
    },
    {
      name: "priority",
      label: "Priority",
      type: "text",
      defaultValue: "9999",
      icon: "label_outline",
      validate: validatePriority,
    },
  ],
};

export default subscriberGroupComposition;
