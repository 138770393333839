import React from "react";

const doesNothing = () => {};

const notDeleted = ({ deleted }) => deleted !== true;

const DeleteContentItemButton = ({ onClick = doesNothing }) => (
  <a className="waves-effect borderless" onClick={onClick}>
    <i className="material-icons">delete</i>
  </a>
);

const EditContentItemButton = ({ onClick = doesNothing }) => (
  <a className="waves-effect borderless" onClick={onClick}>
    <i className="material-icons">edit</i>
  </a>
);

const ItemActions = ({ id, onDelete, onEdit }) => (
  <>
    <EditContentItemButton onClick={() => onEdit(id)} />
    <DeleteContentItemButton onClick={() => onDelete(id)} />
  </>
);

export const classForType = (type) => (type === "number" ? "text-right" : "");
const actionsClassForFields = fields => (fields.length < 2 ? 'width-50' : ''); 

const Value = ({ value }) => <span>{value}</span>;

const ItemRow = ({
  fields = [],
  even = true,
  onDelete = doesNothing,
  onEdit = doesNothing,
  ...item
}) => (
  <tr className={`${even ? "even" : "odd"}`}>
    {fields.map(({ name, type, Component = Value }) => (
      <td key={name} className={classForType(type)}>
        <Component value={item[name]} readOnly={true} />
      </td>
    ))}
    <td className="actions text-center hides-content-to-operators">
      <ItemActions id={item.__id} onEdit={onEdit} onDelete={onDelete} />
    </td>
  </tr>
);

const ListItems = ({
  className = "",
  items = [],
  fields = [],
  onDelete = doesNothing,
  onEdit = doesNothing,
}) => (
  <table className={`profiles table table-class-striped ${className} `}>
    <thead>
      <tr>
        {fields.map(({ name, label, type, colHeadClass }) => (
          <th key={name} className={colHeadClass ||classForType(type)}>
            {label.toUpperCase()}
          </th>
        ))}
        <th className={`text-center ${actionsClassForFields(fields)}`} >Actions</th>
      </tr>
    </thead>
    <tbody>
      {items.filter(notDeleted).map((item, index) => (
        <ItemRow
          fields={fields}
          key={item.__id}
          index={index}
          {...item}
          even={(index + 1) % 2 === 0}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      ))}
    </tbody>
  </table>
);

export default ListItems;
