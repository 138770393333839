/* global nv, NO_DATA_AVAILABLE*/
import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import * as d3 from "d3";
import { useScreenSize } from "common/hooks";
import { translateCoordsOver } from "common/graphs/NVLineChart";
import {
  mayExpandLinearScale,
  LoadingOverlay,
  defaultYAxisFormat,
  splitByFields,
} from "common/graphs/NVStackedAreaChart";

const WrapperDIV = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    width: 100%;
    height: 100%;
    .nv-background rect {
      stroke: var(--chart-inner-border-color, #000);
      stroke-opacity: 0.75;
    }
    .nvd3 .nv-axis path.domain {
      stroke-opacity: 0;
    }
    text.threshold-label {
      text-anchor: end;
      dominant-baseline: text-after-edge;
    }
    .nv-axislabel {
      transform: translate(20px, 0) rotate(-90deg);
    }
    --graph-min-height: ${(props) => props.minHeight || "6cm"};
    height: calc(${(props) => 0}px + var(--graph-min-height)) !important;
  
    .nv-groups, .nv-interactive{
      display: none;
    }
  }
`;

let bestOfBest = 0;

const calcItemsRatio = (count, width) => {
  return (count > width && Math.floor(count / (width * 0.4))) || 1;
};

const NVStackedAreaChartLive = ({
  margin = { top: 90, right: 50, bottom: 30, left: 70 },
  items,
  fields,
  onHighlight,
  highlight,
  chartWidth,
  onSeriesInspect,
  minHeight,
  onRange,
}) => {
  const [wrapper, canvas] = [useRef(null), useRef(null)];
  const screenSize = useScreenSize();
  const isLoading = useRef(true);
  const [range, setRange] = useState(0);
  let chart = null;
  let _initialDimmensions = null;
  const xField = "time";
  
  const render = (target) => {
    isLoading.current = true;
    nv.addGraph(function () {
      if (chart === null) {
        chart = nv.models
          .stackedAreaChart()
          .margin(margin)
          .x((d) => d[0])
          .y((d) => d[1])
          .useInteractiveGuideline(true) // This affects tooltip behavior
          .showControls(false)
          .noData(NO_DATA_AVAILABLE)
          .duration(0)
          .yScale(
            mayExpandLinearScale(d3.scaleLinear(), {
              topGap: 0.05,
            })
          )
          .xScale(d3.scaleTime())
          .showLegend(false)
          .clipEdge(true);
        chart.interactiveLayer.tooltip.enabled(false);
        chart.xAxis.tickFormat(d3.timeFormat("%H:%M:%S")).ticks(3);
        chart.yAxis
          .axisLabel("Mbps")
          .showMaxMin(false)
          .tickFormat(defaultYAxisFormat);
      }
      const ratio = calcItemsRatio(items.length, chartWidth);
      const datum = splitByFields([xField, ...fields], items, ratio);
      bestOfBest = 0;
      d3.select(canvas.current).datum(datum);
      const [_yDomainMin, yDomainMax] = chart.yAxis.domain();
      d3.select(canvas.current).call(chart);
      d3.select(canvas.current).select(".nv-background rect").attr("rx", 9);

      const main = d3.select(canvas.current);
      const rangeWidthNew = chart.xAxis.range()[1];
      if (rangeWidthNew !== range) {
        setRange(rangeWidthNew);
      }

      chart.dispatch.on("renderEnd", function () {
        isLoading.current = false;
      });
      if (onHighlight && onHighlight !== null) {
        const translateCoords = translateCoordsOver(main.node());
        chart.interactiveLayer.dispatch.on("elementMousemove.name", (event) => {
          if (event.synthetic === true) {
            return;
          }
          const { pointXValue, mouseX } = event;
          onHighlight({ synthetic: true, ...translateCoords.out(event) });
        });
        chart.interactiveLayer.dispatch.on("elementMouseout.name", () => {
          onHighlight(null);
        });
      }
      if (highlight && highlight !== null) {
        chart.interactiveLayer.dispatch.elementMousemove(highlight);
      } else {
        chart.interactiveLayer.dispatch.elementMouseout({
          mouseX: 0,
          mouseY: 0,
        });
      }
      const linesArea = main.select(".nvd3.nv-stackedarea");
      if (onSeriesInspect !== undefined) {
        const handleInspect = (event, d) => {
          event.preventDefault();
           // react on right-clicking;
          onSeriesInspect({name: d.key});
        }
        linesArea.on("contextmenu", (event) => {
          if (event.target.tagName !== 'path') {
            return;
          }
          const [data] = d3.select(event.target).data();
          return handleInspect(event, data);
        });
      }

      return chart;
    });
  };

  useEffect(() => {
    if(onRange){
      onRange(range);
    }
  }, [range]);

  useEffect(() => {
    const target = wrapper.current;
    return items ? render(target) : () => {};
  }, [items, fields, screenSize]);

  const str = highlight ? new Date(highlight.pointXValue).toISOString() : ''

  useEffect(() => {
    const target = wrapper.current;
    return items ? render(target) : () => {};
  }, [str]);

  return (
    <WrapperDIV ref={wrapper} minHeight={minHeight}>
      <svg ref={canvas}></svg>
    </WrapperDIV>
  );
};

export default NVStackedAreaChartLive;
