import {
  ensure,
  wrapErrors,
  oneOf,
  asAString,
  asAInteger,
  equalTo,
  beBetween,
} from "../Common/composition";

const validateVLANTag = wrapErrors(
  (error) => `Tag ${error}`,
  ensure(
    oneOf(
      asAString(equalTo("none")),
      asAInteger(beBetween(1, 4094))
    )
  )
);

const anyTagCollisionWith = (__id, tag) => (existing) =>
  existing.tag === tag && existing.__id !== __id;

const verfyTag = (item, list) => {
  const { tag, stored, __id } = item;
  validateVLANTag(tag);
  if (
    stored !== true &&
    list.find(anyTagCollisionWith(__id, tag)) !== undefined
  ) {
    throw "Tag already exists.";
  }
  return item;
};

const vlanComposition = {
  cliType: "vlan",
  typeLabel: "VLAN",
  viewURL: "viewVLANProfileConfig",
  fields: [
    { name: "name", label: "Profile name", type: "text", canUpdate: false },
    {
      name: "vlans",
      cliName: "vlan",
      label: "VLAN Tags",
      labelSingle: "VLAN Tag",
      type: "many",
      validate: verfyTag,
      canBeEmpty: false,
      fields: [
        {
          name: "tag",
          label: "VLAN Tags",
          labelSingle: "VLAN Tag",
          colHeadClass: 'text-left',
          type: "number",
          icon: "label_outline",
          clarify: '"none" for no tag',
        },
      ],
    },
  ],
};

export default vlanComposition;
