import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import styled from "styled-components";
import * as d3 from "d3";
import { getDateWithOffset } from "common/api";
import { useDimensions } from "common/hooks";
import NVStackedAreaChartLive from "views/Statistics/SubscriberDetail/NVStackedAreaChartLive";
import {
  getItemsUpdate,
  formatDate,
} from "views/Statistics/SubscriberDetail/livemetrics.utils";
import { default as LiveLegend } from "views/Statistics/SubscriberDetail/DPIAnalyticsLiveLegend";
import Tooltip from "./Tooltip";
import CurrentDataTable from "./CurrentDataTable";

const Wrapper = styled.div`
  width: 100%;
`;

const LegendContainer = styled.div`
  > div {
    display: block;
  }
`;

const Title = styled.h4`
  font-weight: 100;
  font-size: var(--chart-title-font-size) !important;
  margin: ${(props) => (props.isFirst ? "20px 0 15px" : "-10px 0 15px")};
`;

const CursorLine = styled.div`
  position: absolute;
  width: 2px;
  z-index: 9999;
  background-color: var(--Text-Primary);
`;

const ChartContainer = styled.div`
  width: calc(100% - 350px);
  position: relative;
  margin-bottom: 20px;

  @media (max-width: 650px) {
    width: 100%;
  }

  svg.nvd3-svg .nv-axis path.domain {
    stroke-opacity: 0;
  }

  .nv-y.nv-axis .nv-axisMaxMin {
    display: none;
  }

  .nv-y.nv-axis .tick.zero line {
    stroke: transparent !important;
  }

  .nvd3 .nv-groups path.nv-line {
    stroke-width: 1.1;
  }

  .border {
    position: absolute;

    &.top {
      width: 100%;
      height: 55px;
      min-height: 55px;
      top: -50px;
      left: 0;
    }
    &.right {
      width: 45px;
      height: 100%;
      top: 0;
      right: 5px;
    }

    &.bottom {
      min-height: 70px;
      width: 100%;
      height: 30px;
      bottom: -40px;
      left: 0;
    }

    &.left {
      width: 50px;
      height: 100%;
      top: 0;
      left: 20px;
    }
  }
`;

const ChartsWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  @media (max-width: 650px) {
    display: block;
  }
`;

const TableContainer = styled.div`
  width: 350px;
  margin: 3px 0 30px;
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  align-items: flex-end;
  @media (max-width: 650px) {
    width: 100%;
  }
`;

const getHoveredTime = (mouseEvent, items, range) => {
  if (!mouseEvent || items.length === 0) {
    return null;
  }
  const xScale =  d3.scaleTime()
	.domain([items[0].time, items[items.length-1].time])
	.range([0, range]);
  return xScale.invert(mouseEvent.mouseX);
};

const marginRight = 50;
const marginLeft = 70;
const marginTop = 5;
const marginBottom = 30;

const Chart = ({
  value,
  timeOffset,
  fields,
  fieldsMap,
  title,
  onMouseEvent,
  mouseEvent,
  seconds,
  total,
  liveMode,
  output,
  timeWindow,
  setLiveMode,
}) => {
  const [items, setItems] = useState([]);
  const [window, setWindow] = useState(0);
  const [filterFields, setFilterFields] = useState(new Set());
  const [filteredFields, setFilteredFields] = useState([]);
  const [sortedFields, setSortedFields] = useState([]);
  const firstValue = useRef(false);
  const fieldsSet = useRef({});
  const chartRef = useRef(null);
  const dataMap = useRef({});
  const { width } = useDimensions(chartRef);
  const [range, setRange] = useState(0);

  function reset() {
    setItems([]);
    setWindow(0);
    setFilterFields(new Set());
    setFilteredFields([]);
    firstValue.current = false;
    fieldsSet.current = {};
    dataMap.current = {};
  }

  useEffect(() => {
    reset();
  }, [output, timeWindow]);

  useEffect(() => {
    if (liveMode) {
      reset();
    }
  }, [liveMode]);

  useEffect(() => {
    if (!value) {
      return;
    }

    const now = getDateWithOffset(timeOffset);
    const newValue = { ...value };

    if (!firstValue.current) {
      firstValue.current = newValue;
    }

    const fieldNames = Object.keys(fieldsMap);
    const addedFields = fieldNames.filter((field) => !fieldsSet.current[field]);
    const filterFieldsWithoutRemoved = [...filterFields].filter(
      (fieldName) => fieldsSet.current[fieldName]
    );
    setFilterFields(new Set([...filterFieldsWithoutRemoved, ...addedFields]));
    fieldsSet.current = fieldsMap;

    const timeFrame =
      newValue.time.getTime() - firstValue.current.time.getTime();
    let { itemsUpdate, window: windowNew } = getItemsUpdate(
      items,
      now,
      fields,
      timeOffset,
      timeFrame,
      timeWindow,
      newValue,
      window
    );
    setWindow(windowNew);
    const lastValue = itemsUpdate[itemsUpdate.length - 1];
    if (
      lastValue?.time &&
      newValue?.time &&
      formatDate(lastValue.time) !== formatDate(newValue.time)
    ) {
      delete dataMap.current[formatDate(itemsUpdate[0].time)];
      const totalSpeedValues = Object.values(fieldsMap).reduce(
        (acc, fieldValue) => {
          acc.up = acc.up + fieldValue.totalUp;
          acc.down = acc.down + fieldValue.totalDown;
          return acc;
        },
        { up: 0, down: 0 }
      );
      dataMap.current[formatDate(newValue.time)] = {
        time: newValue.time,
        fields: [...fields],
        value: newValue,
        totalUp: totalSpeedValues.up,
        totalDown: totalSpeedValues.down,
      };
      setItems([...itemsUpdate.slice(1), newValue]);
    }
  }, [value]);

  useEffect(() => {
    if (filterFields) {
      setFilteredFields(fields.filter((field) => filterFields.has(field.name)));
    }
  }, [filterFields]);

  useEffect(() => {
    if (filteredFields) {
      const {rest, otherFields} = filteredFields.reduce((acc, value)=>{
        if (value.name === "rest") {
          acc.rest = value;
        } else {
          acc.otherFields.push(value)
        }
        return acc;
      },{rest:undefined, otherFields:[]})
      const sortedFields = [...otherFields.sort((a, b) => {
        return a.name.localeCompare(b.name);
      }), ...(rest!== undefined)? [rest]:[]]
      setSortedFields(sortedFields);
    }
  }, [filteredFields]);

  const top20Fields = useMemo(
    () =>
      Object.entries(fieldsMap)
        .map(([key, { total, color, totalUp, totalDown }]) => {
          return {
            label: key,
            name: key,
            color,
            total,
            totalUp,
            totalDown,
          };
        })
        .sort((a, b) => b.total - a.total)
        .slice(0, 20),
    [JSON.stringify(fieldsMap)]
  );

  const hasRestValue = fieldsMap["rest"]
    ? fieldsMap["rest"].total >= top20Fields[top20Fields.length - 1].total
    : false;

  function removeTooltip() {
    if (mouseEvent) {
      onMouseEvent(null);
    }
  }

  const openDashboard = (label, output) => {
    if (label === "rest") {
      return;
    }

    setLiveMode(false);
    views.doKeep("viewSubscriberThroughput");

    const target =
      output === "ip-addresses"
        ? { addr: label, subsId: null }
        : { addr: null, subsId: label };
    globalNavigate("viewSubscriberThroughput", {
      ...target,
      returnView: "viewSubscriberThroughput",
    });
  };

  const hoverderTime = getHoveredTime(mouseEvent, items, range);

  return (
    <Wrapper>
      <Title className="chart-title" isFirst={title === "Downlink"}>
        {title}
      </Title>
      <ChartsWrapper>
        <ChartContainer ref={chartRef}>
          {items.length > 0 ? (
            <LegendContainer>
              <LiveLegend
                fields={top20Fields}
                onChange={(filter) => setFilterFields(filter)}
                value={filterFields}
                rest={hasRestValue ? fieldsMap["rest"] : false}
                onContextMenu={(name) => openDashboard(name, output)}
                maxWidthField="240"
              />
            </LegendContainer>
          ) : null}
          {mouseEvent && hoverderTime && items.length > 0 && (
            <Tooltip
              dataPoint={dataMap.current[formatDate(hoverderTime)]}
              mouseX={mouseEvent.mouseX}
              mouseY={mouseEvent.mouseY}
              title={formatDate(hoverderTime)}
              mouseEvent={mouseEvent}
              chartWidth={width}
              fieldsMap={fieldsMap}
              filterFields={filterFields}
              type={title}
            />
          )}
          {mouseEvent && (
            <>
              <div
                className="border top"
                onMouseEnter={removeTooltip}
                onMouseLeave={removeTooltip}
              ></div>
              <div
                className="border right"
                onMouseEnter={removeTooltip}
                onMouseLeave={removeTooltip}
              ></div>
              <div
                className="border bottom"
                onMouseEnter={removeTooltip}
                onMouseLeave={removeTooltip}
              ></div>
              <div
                className="border left"
                onMouseEnter={removeTooltip}
                onMouseLeave={removeTooltip}
              ></div>
            </>
          )}
          <NVStackedAreaChartLive
            items={items}
            xAxisFormat={d3.timeFormat("%m/%d %H:%M")}
            fields={sortedFields}
            yAxisUnits="Mbps"
            yAxisTopGap={0.05}
            margin={{
              top: marginTop,
              right: marginRight,
              bottom: marginBottom,
              left: marginLeft,
            }}
            chartWidth={width - marginLeft - marginTop}
            minHeight="276px"
            onHighlight={(e) => {
              if (e) {
                onMouseEvent(e);
              }
            }}
            onSeriesInspect={({ name }) => openDashboard(name, output)}
            onRange={(rangeNew) => {
              setRange(rangeNew);
            }}
          />
          {mouseEvent && hoverderTime && filterFields.size > 0 && (
            <CursorLine
              style={{
                left: mouseEvent.mouseX + marginLeft - 4,
                height: "242px",
                bottom: "30px",
              }}
            />
          )}
        </ChartContainer>
        <TableContainer>
          {filterFields.size > 0 ? (
            <CurrentDataTable
              filterFields={filterFields}
              fields={top20Fields}
              headerOutput={output === "ip-addresses" ? "IP-Addr" : "Subs-Id"}
              type={title}
              current={value}
              onSeriesInspect={(name) => openDashboard(name, output)}
              seconds={seconds}
              total={total}
            />
          ) : null}
        </TableContainer>
      </ChartsWrapper>
    </Wrapper>
  );
};

export default Chart;