import React, { useContext } from "react";
import ActionsContext from "common/ActionsContext";
import { TitleWithNav } from "common/TitleWithNav";

const doesNothing = () => {};

const DefaultContextMenu = ({ createAction = doesNothing }) => {
  const actions = useContext(ActionsContext);
  return (
    <ul className="context-menu header-dropdown m-r--5">
      <li>
        <a
          data-loading-effect="pulse"
          data-toggle="cardloading"
          title="Help"
          onClick={() => actions.send("open-help")}
        >
          <i className="material-icons">help_outline</i>
        </a>
        <a
          data-toggle="cardloading"
          data-loading-effect="pulse"
          title="Refresh"
          onClick={() => actions.send("do-load")}
        >
          <i className="material-icons">refresh</i>
        </a>
      </li>
      <li className="dropdown hidden-to-operators">
        <a
          className="dropdown-toggle"
          data-toggle="dropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
          title="Options"
        >
          <i className="material-icons">more_vert</i>
        </a>
        <ul className="dropdown-menu pull-right">
          <li>
            <a onClick={createAction}>Add Profile...</a>
          </li>
        </ul>
      </li>
    </ul>
  );
};
const View = ({
  title = "title not set",
  titleClassName = "",
  className = "container-lg",
  subtitle = null,
  ContextMenu = DefaultContextMenu,
  noHeader,
  returnView,
  createAction = doesNothing,
  error = null,
  children,
}) => (
  <div className={className}>
    <div className="row clearfix">
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div className="card">
          {!noHeader && (
            <div className="header block-header">
              <TitleWithNav returnView={returnView}>
                <h2 className={`title ${titleClassName}`}>{title}</h2>
              </TitleWithNav>
              <ContextMenu createAction={createAction}></ContextMenu>
              {subtitle === null ? null : subtitle}
            </div>
          )}
          <div className="body table-responsive">{children}</div>
          <div className="control modal-footer">
            {error === null
              ? null
              : console.error(error) || (
                  <span className="modal-err-msg color-red align-left">
                    {String(error)}
                  </span>
                )}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default View;
