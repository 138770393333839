import React from 'react';
import styled from 'styled-components';

const LabelWithIconSpan = styled.span`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  .text-label {
    margin-left: .5em;
  }
`

const Label = ({icon=undefined, ...settings}) => 
  <span className="input-group-addon padding-0">
    {icon === undefined
      ? <TextLabel {...settings} />
      : <IconLabel icon={icon} {...settings} />
    }
  </span>

const TextLabel = ({label='', className=''}) =>
  label === undefined || label.length === 0 ? null :
    <span className={`text-label ${className}`}>{label}:</span>


const IconLabel = ({icon, ...settings}) =>
  <LabelWithIconSpan className="label-with-icon">
    <i className="material-icons">{icon}</i>
    <TextLabel {...settings} />
  </LabelWithIconSpan>

const Labeled = ({className='', label='', icon= undefined, children}) =>
  <div className={`input-group ${className}`}>
    <Label {...{label, className, icon}} />
    {children}
  </div>

export default Labeled;
