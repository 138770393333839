import styled from "styled-components";

const Button = styled.button`
    width: 35px;
    height: 35px;
    background: var(--color-grey200);
    border-radius: 5px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;

    i{
      color: var(--color-grey900) !important;
    }
`;



  export const BackButton = function({className, style}){
    return <Button onClick={globalNavigateBack} className={className} style={style}>
      <i className="material-icons">keyboard_arrow_left</i>
    </Button>
  }