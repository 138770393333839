// @ts-nocheck
import React, { useState, useEffect } from "react";
import DefaultLoading from "./Loading";
import styled from "styled-components";

type Props = {
  during: Promise<any>;
  fallback?: React.ComponentClass<any>;
  children: (arg0: any) => React.ReactElement<any, any>;
  keepContentWhileLoading?: boolean;
};

interface Result {
  [key: string]: string | number;
}

const Failed = ({ ...error }) => console.error(error) || <span>{JSON.stringify(error)}</span>;

const Request: React.FC<Props> = ({
  during,
  fallback: Loading = DefaultLoading,
  children,
  keepContentWhileLoading = false,
}) => {
  const [result, setResult] = useState<{ [key: string]: any } | false>({});
  const [done, setDone] = useState(false);
  const [failure, setFailure] = useState<false | { [key: string]: any }>(false);
  
  useEffect(() => {
    setDone(false);

    if(!keepContentWhileLoading){
      setResult(false);
    }

    setFailure(false);
    
    if(during){
      during
        .then((result) => {
          setResult(result);
          setDone(true);
        })
        .catch((error) => {
          setFailure({ error });
          setDone(true);
        });
      //during.finally(() => console.log("test done."));
    }
  }, [during]);
  if (done !== true && keepContentWhileLoading !== true) {
    return <Loading />;
  }
  if (failure !== false) {
    return <Failed {...failure} />;
  }
  if (result !== null) {
    return children({ ...result });
  }
  return null;
};

export default Request;
