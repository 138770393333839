import React from "react";
import styled from "styled-components";

const doesNothing = () => {};

const TickOrCross = ({ ok = false }) => (
  <i className={`material-icons ${ok ? "col-green" : "col-red"}`}>
    {ok ? "check" : "close"}
  </i>
);

const Captures = ({
  iface: name,
  type = "ethernet",
  onClick = doesNothing,
}) => (
  <a
    className="action margin-l-15"
    title="Capture"
    onClick={() => onClick("capture", { name, type })}
  >
    <i className="material-icons">search</i>
  </a>
);

const InterfaceName = ({ iface: name, onClick = doesNothing }) => (
  <a onClick={() => onClick("details", { name, type: "ethernet" })}>{name}</a>
);

const AlignMiddle = styled.span`
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
`;

const InterfaceFields = ({ onClick = doesNothing, ...current }) => (
  <>
    <td className="hyperlink-text">
      <AlignMiddle>
        <InterfaceName {...current} onClick={onClick} />
        <Captures {...current} onClick={onClick} />
      </AlignMiddle>
    </td>
    <td className="text-center">
      <TickOrCross ok={current.enabled} />
    </td>
    <td className="text-center">
      <TickOrCross ok={current.connected} />
    </td>
    <td>{current.pcap ? "yes" : "no"}</td>
  </>
);

const WireRow = ({
  wire,
  onInterfaceClick = doesNothing,
  Actions = null,
  ...others
}) => (
  <tr>
    <InterfaceFields {...wire.acc} onClick={onInterfaceClick} />
    <InterfaceFields {...wire.inet} onClick={onInterfaceClick} />
    {Actions === null ? null : (
      <td className="text-center actions">
        <Actions wire={wire} {...others} />
      </td>
    )}
  </tr>
);

const ListWires = ({
  items = [],
  onInterfaceClick = doesNothing,
  filter = () => true,
  Actions = null,
  ...others
}) => (
  <table className="table table-striped margin-t-15 margin-b-0">
    <thead>
      <tr>
        <th>ACCESS INTERFACE</th>
        <th className="text-center">UP</th>
        <th className="text-center">LINK</th>
        <th>PCAP</th>
        <th>INTERNET INTERFACE</th>
        <th className="text-center">UP</th>
        <th className="text-center">LINK</th>
        <th>PCAP</th>
        {Actions === null ? null : <th className="text-center">ACTIONS</th>}
      </tr>
    </thead>
    <tbody>
      {items.filter(filter).map((current) => (
        <WireRow
          key={current.__id}
          wire={current}
          onInterfaceClick={onInterfaceClick}
          Actions={Actions}
          {...others}
        />
      ))}
    </tbody>
  </table>
);
export default ListWires;
