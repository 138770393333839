import React, { useState, useContext } from "react";
import styled from "styled-components";
import DataTable, { Column } from "common/DataTable";
import Table from "common/table";
import ActionsContext from "common/ActionsContext";
import { PROFS, CONST_TREE_RULE, POLI, TYPE_PROFILE_SHORTCUTF_MAP } from "../RulesTree/constants";
import { buildCommand } from "../RulesTree/utils";

const TableContainer = styled.div`
  width: 100%;
  overflow: auto;
`;

const VIEW_NAME = "viewRulesFlow";
const DEFAULT_POLICY = "flow-default";

function getUsedProfAndHeader(rules) {
  return PROFS.reduce(
    (acc, prof) => {
      const profName = prof.attr;
      const ruleWithProf = rules.find((rule) => {
        return rule[profName] !== CONST_TREE_RULE.ANY;
      });
      if (ruleWithProf) {
        acc.push(prof);
      }
      return acc;
    },
    []
  );
}

function getHasConflicts(conflicts, id) {
  if (!conflicts) return "no";
  return conflicts[id] ? "yes" : "no";
}

function isDefaultRule(policy, rule) {
  const testRule = { ...rule };
  delete testRule.id;
  delete testRule.poli;
  return (
    policy === DEFAULT_POLICY &&
    Object.values(testRule).every((rule) => rule === "any")
  );
}

const RulesTable = ({ rules, conflicts }) => {
  const actions = useContext(ActionsContext);
  const profiles = getUsedProfAndHeader(rules);

  const profileColumns = profiles.map((profile, profIdx) =>
    Table.Column.Text({
      label: profile.header,
      idx: 3 + profIdx,
      cellClassName: (value) =>
        value === CONST_TREE_RULE.ANY ? value : "hyperlink-text",
      onClick: (_header, [id, ..._rest]) => {
        const rule = rules.find((rule) => rule.id === id);
        if (rule[profile.attr] === CONST_TREE_RULE.ANY) return;
        TYPE_PROFILE_SHORTCUTF_MAP[profile.type](rule[profile.attr], VIEW_NAME);
      },
    })
  );

  const rows = rules.map((rule) => {
    const hasConflict = getHasConflicts(conflicts, rule.id);
    return [
      rule.id,
      rule[POLI.attr],
      hasConflict,
      ...profiles.map((profile) => rule[profile.attr]),
    ].join("    ");
  });

  const columns = [
    Table.Column.Text({
      label: "RULE",
      idx: 0,
      cellClassName: (_value, [_id, conflict]) =>
        conflict === undefined || conflict === "no"
          ? "col-green"
          : "col-orange",
    }),
    Table.Column.Hidden({
      label: "CONFLICT",
      idx: 2,
    }),
    ...profileColumns,
    Table.Column.Text({
      label: "POLICY",
      idx: 1,
      cellClassName: "hyperlink-text",
      onClick: (policy, [id]) => {
        const rule = rules.find((rule) => rule.id === id);
        if (rule) {
          TYPE_PROFILE_SHORTCUTF_MAP.policy(rule.poli, VIEW_NAME, { tab: 1 });
        }
      },
    }),
    Table.Column.Actions({
      label: "ACTIONS",
      idx: 1,
      colClassName: "text-center action",
      cellClassName: "hides-content-to-operators",
      are: (policy, [id]) => {
        const rule = rules.find((rule) => rule.id === id);
        const isDefault = isDefaultRule(policy, rule);
        if (isDefault) {
          return [];
        }

        return [
          {
            id: "edit-config",
            label: "Edit",
            icon: "edit",
            onClick: (_, [id]) => {
              if (rule) {
                actions.send("open-rule-modal", { type: "Edit", rule });
              }
            },
          },
          {
            id: "delete",
            label: "Delete",
            icon: "delete",
            onClick: (_, [id]) => {
              if (rule) {
                showModalConfirm(
                  "Remove rule ?",
                  "WARNING: Rule data will be lost",
                  "delete_forever",
                  () => {
                    const cmd = buildCommand("clear", rule);

                    ifCl
                      .run(cmd)
                      .then((response) => {
                        if (response.length === 0) {
                          actions.send("do-load");
                        } else if (response.substring(0, 5) === "%WARN") {
                          showModalInfo("Warning:", response);
                        } else {
                          showModalError("Error:", response);
                        }
                      })
                      .catch((error) => showModalError(error));
                  }
                );
              }
            },
          },
        ];
      },
    }),
  ];

  const paginator = new Table.Paginator(columns, rows, []);

  return (
    <TableContainer>
      <DataTable
        pageLength={50}
        exportAsCSV={true}
        paginator={paginator}
        columns={columns}
      />
    </TableContainer>
  );
};

export default RulesTable;
